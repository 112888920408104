import React, { useContext, useEffect, useState } from "react";
import Styles from "./CrossSaleOperationsLeads.module.css"
import { Box, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { onValue, ref } from "firebase/database";
import { firebaseDatabase } from "../../../backend/firebaseHandler";
import UserContext from "../../../context/UserData.context";
import { useNavigate } from "react-router-dom";
import { RiArrowDropUpFill } from "react-icons/ri";

const CrossSaleOperationsLeads = () => {

    const [visibleList, setVisibleList] = useState({ data: [], noData: false, loading: true });
    const [userData] = useContext(UserContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (userData.uid) {
            const crossSaleLeads = ref(firebaseDatabase, `/HI_WITH_OPERATIONS_LEADS/${userData.uid}`);
            onValue(crossSaleLeads, (dataSnapshot) => {
                const myLeads = []
                if (dataSnapshot.exists()) {
                    for (const phonNumber in dataSnapshot.val()) {
                        const lead = dataSnapshot.child(phonNumber).val();
                        myLeads.push(lead);
                    }
                    setVisibleList({
                        data: myLeads,
                        loading: false,
                        noData: myLeads.length === 0
                    });
                } else {
                    setVisibleList({
                        data: myLeads,
                        loading: false,
                        noData: true
                    });
                }
            }, { onlyOnce: true })
        }
    }, [])

    return (
        <div>
            <div>
                <h1 style={{ color: "#352F6C" }}>Cross Sale Operations</h1>
            </div>
            <Divider sx={{ margin: '24px 0' }} />
            <div>
                <Box>
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>#</TableCell>
                                    <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Customer Id</TableCell>
                                    <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Name</TableCell>
                                    <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Ph Number</TableCell>
                                    <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Email ID</TableCell>
                                    <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Country</TableCell>
                                    <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>University</TableCell>
                                    <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Travel Date</TableCell>
                                    <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody style={{ backgroundColor: "white" }}>
                                {
                                    visibleList.data.map((item, index) => <CRMTableCell key={index.toString()} item={item} index={index} navigate={navigate} />)
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </div>
        </div>
    )
}


const CRMTableCell = ({ index, item, navigate }) => {

    const handleDetail = () => navigate("cross-sale-lead-detail", { state: item })

    return (
        <TableRow onClick={handleDetail} key={index.toString()} >
            <TableCell>{(index + 1).toString()}</TableCell>
            <TableCell>{item.customerId}</TableCell>
            <TableCell>{item.name}</TableCell>
            <TableCell>xxxxxxxxx{item.phoneNumber.substring(9, 12)}</TableCell>
            <TableCell>{item.emailId}</TableCell>
            <TableCell>{item.desiredCountry}</TableCell>
            <TableCell>{item.desiredUniversity}</TableCell>
            <TableCell>{item.travelDate}</TableCell>
            <TableCell>{item.status}</TableCell>
        </TableRow>
    )
}


export default CrossSaleOperationsLeads