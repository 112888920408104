import React, { useContext, useEffect, useState } from "react";
import Styles from "./HiReassignLeads.module.css"
import { Box, Button, CircularProgress, Input, MenuItem, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { AiOutlineSearch } from 'react-icons/ai';
import { onValue, ref, remove, set } from "firebase/database";
import { firebaseDatabase } from "../../../backend/firebaseHandler";
import educationLoan from "../../../entities/EducationLoan.sample";
import moment from "moment";
import UserContext from "../../../context/UserData.context";

const HiReassignLeads = () => {

    const [searchWord, setSearchWord] = useState("")
    const [result, setResult] = useState({})
    const [loading, setLoading] = useState(false)
    const [notFound, setNotFound] = useState(false)
    const possibleStatus = ["New", "Interested", "Rescheduled", "Closed"]
    const possibleRefs = ["HI_NEW_LEADS", "HI_INTERESTED_LEADS", "HI_RESCHEDULED_LEADS", "HI_CLOSED_LEADS"]
    const [userList, setUserList] = useState([])
    const [selectedAdvisor, setSelectedAdvisor] = useState(0)
    const [userData, setUserData] = useContext(UserContext)
    const [transferring, setTransferring] = useState(false)
    const [selectedType, setSelectedType] = useState("INBOUND")

    useEffect(() => {
        let temp = []

        onValue(ref(firebaseDatabase, `USER_ARCHIVE`), (snap) => {
            if (snap.exists()) {
                for (const key in snap.val()) {
                    let tempObj = snap.child(key).val()

                    if (tempObj.status === "Active" && (tempObj.position === "Advisor" || tempObj.position === "Manager") && tempObj.department === "Health Insurance") {
                        temp.push(tempObj)
                    }
                }
            }
            setUserList(temp)
        }, { onlyOnce: true })
    }, [])

    const handleSearch = (code) => {
        setNotFound(false)
        if (code === 13) {
            if (searchWord === "") {
                setLoading(false)
                setResult({})
            } else {
                setLoading(true)
                onValue(ref(firebaseDatabase, `HEALTH_INSURANCE_APPLICATIONS/${searchWord}`), (snap1) => {
                    if (snap1.exists()) {
                        let firstObject = snap1.val()
                        setResult(firstObject)
                        setSelectedType(firstObject.type)
                        setLoading(false)
                    } else {
                        setNotFound(true)
                        setLoading(false)
                    }
                })
            }
        }
    }

    const handleReAssign = async () => {
        // update loan application
        // update lead status
        // add to new table
        // remove from current table
        // update operations is possible

        // if (userList[selectedAdvisor].uid === result.assignedAdvisorUid) {
        //     alert("Sorry. The lead already belongs with " + userList[selectedAdvisor].name)
        //     return
        // }

        let newObject = {
            ...result,
            assignedAdvisorUid: userList[selectedAdvisor].uid,
            assignedAdvisorName: userList[selectedAdvisor].name,
            assignedAdvisorPhoneNumber: userList[selectedAdvisor].phoneNumber,
            type: selectedType,
            reAssigningDate: moment().format("DD-MM-YYYY h:mm a"),
            newFromAdminDate: moment().format("DD-MM-YYYY h:mm a"),
            status: "New",
            subStatus: "From Admin-Yet to Call",
            product: "Health Insurance",
            firstCall: null,
            lastCallDate: "",
            receivedFrom: {"position":userData.position, "name":userData.name, "phoneNumber": userData.phoneNumber, "uid": userData.uid}
        }

        let hasBeenWithArray = result.pastAdvisors?result.pastAdvisors:[]
        hasBeenWithArray.push(result.assignedAdvisorUid)
        newObject.pastAdvisors = hasBeenWithArray

        setTransferring(true)

        onValue(ref(firebaseDatabase, `ALL_LEADS_STATUS/Health Insurance/${newObject.phoneNumber}`), async (snap) => {
            if (snap.exists()) {
                let statusObject = snap.val()

                let lifeLineArray = [...statusObject.lifeline]
                let arrayItem = {
                    actionBy: userData.uid,
                    status: "New",
                    subStatus: "Reassigned by Admin",
                    date: moment().format("DD-MM-YYYY h:mm a"),
                    ref: "HI_NEW_LEADS"
                }
                statusObject.status = "New"
                lifeLineArray.push(arrayItem)
                statusObject.lifeline = [...lifeLineArray]

                let pastRef = possibleRefs[possibleStatus.indexOf(result.status)]

                await set(ref(firebaseDatabase, `ALL_LEADS_STATUS/Health Insurance/${newObject.phoneNumber}`), statusObject)
                await set(ref(firebaseDatabase, `HEALTH_INSURANCE_APPLICATIONS/${newObject.phoneNumber}`), newObject)
                await set(ref(firebaseDatabase, `HI_NEW_LEADS/${newObject.assignedAdvisorUid}/${newObject.phoneNumber}`), newObject)
                if ((result.assignedAdvisorUid !== newObject.assignedAdvisorUid || pastRef!=="HI_NEW_LEADS")) {
                    await remove(ref(firebaseDatabase, `${pastRef}/${result.assignedAdvisorUid}/${result.phoneNumber}`))
                }
                setTransferring(false)
                setResult({})
                alert("Re-assigned successfully!")
            }
        }, {onlyOnce:true})
    }

    return (
        <div>
            <div className={Styles.headingButtonContainer}>
                <h1 style={{ color: "#352F6C" }}>Re-assign Leads</h1>
            </div>

            <Stack className={Styles.filterContainer}>
                <Input disableUnderline={true} style={{ backgroundColor: "white", borderRadius: 5, padding: 5, width: 250 }} value={searchWord} onKeyUp={(event) => { handleSearch(event.keyCode) }} onChange={(event) => { setSearchWord(event.target.value) }} startAdornment={<AiOutlineSearch style={{ marginRight: 12 }} size={24} />} size='small' placeholder='Phone Number' />
            </Stack>

            {
                loading
                    ?
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px 0' }}>
                        <CircularProgress size={20} />
                    </div>
                    :
                    notFound
                        ?
                        <p>Sorry. Could not find this number in the Database.</p>
                        :
                        <Box>
                            <TableContainer>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Name</TableCell>
                                            <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Ph Number</TableCell>
                                            <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Email ID</TableCell>
                                            <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Status</TableCell>
                                            <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Sub Status</TableCell>
                                            <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Source</TableCell>
                                            <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Source Description</TableCell>
                                            <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Type</TableCell>
                                            <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Advisor</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody style={{ backgroundColor: "white" }}>
                                        <TableRow>
                                            <TableCell>{result.name}</TableCell>
                                            <TableCell>{result.phoneNumber}</TableCell>
                                            <TableCell>{result.emailId}</TableCell>
                                            <TableCell>{result.status}</TableCell>
                                            <TableCell>{result.subStatus}</TableCell>
                                            <TableCell>{result.source}</TableCell>
                                            <TableCell>{result.sourceDescription}</TableCell>
                                            <TableCell>{result.type}</TableCell>
                                            <TableCell>{result.assignedAdvisorName}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
            }

            {
                result && possibleStatus.includes(result.status) && !notFound
                    ?
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{display:"flex", flexDirection:"row", gap:20}}>
                            <div>
                                <p className={Styles.label} style={{ marginTop: 40 }}>Re-assign to?</p>
                                <Select style={{ width: 300, backgroundColor: "white", marginTop: 5, padding:10, borderRadius:5 }} value={selectedAdvisor} onChange={(event) => { setSelectedAdvisor(event.target.value) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                    {
                                        userList.map((item, index) => {
                                            return (
                                                <MenuItem value={index}>{item.name}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </div>
                            <div>
                                <p className={Styles.label} style={{ marginTop: 40 }}>Re-assign as?</p>
                                <Select style={{ width: 300, backgroundColor: "white", marginTop: 5, padding:10, borderRadius:5 }} value={selectedType} onChange={(event) => { setSelectedType(event.target.value) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                    <MenuItem value="INBOUND">INBOUND</MenuItem>
                                    <MenuItem value="OUTBOUND">OUTBOUND</MenuItem>
                                </Select>
                            </div>
                        </div>

                        <Button onClick={() => { handleReAssign() }} style={{ marginTop: 10, width: 150, backgroundColor: transferring ? "#ccc" : null }} variant="contained">Re-assign</Button>
                    </div>
                    :
                    null
            }
        </div>
    )
}

export default HiReassignLeads