import React from "react";
import Styles from "./AdvisorInterestedLeadsIncognito.module.css"
import { useNavigate } from "react-router-dom";
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

const AdvisorInterestedLeadsIncognito = ({activeList}) => {

    const navigate = useNavigate()
    
    return (
        <div style={{width:"100%", marginTop:40}}>
            <Box>
                <TableContainer>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>#</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Customer ID</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Name</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Ph Number</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Email ID</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Country</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>University</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Interested Date</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Last Call Date</TableCell>
                                <TableCell style={{backgroundColor:"rgba(1, 1, 1, 0)"}}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody style={{backgroundColor:"white"}}>
                            {
                                activeList.map((item, index) => {
                                    return (
                                        <TableRow key={index.toString()}>
                                            <TableCell style={{color:item.outstandingDate?"#ccc":"#444"}}>{(index + 1).toString()}</TableCell>
                                            <TableCell style={{color:item.outstandingDate?"#ccc":"#444"}}>{item.customerId}</TableCell>
                                            <TableCell style={{color:item.outstandingDate?"#ccc":"#444"}}>{item.name}</TableCell>
                                            <TableCell style={{color:item.outstandingDate?"#ccc":"#444"}}>xxxxxxxxx{item.phoneNumber.substring(9, 12)}</TableCell>
                                            <TableCell style={{color:item.outstandingDate?"#ccc":"#444"}}>{item.emailId}</TableCell>
                                            <TableCell style={{color:item.outstandingDate?"#ccc":"#444"}}>{item.desiredCountry}</TableCell>
                                            <TableCell style={{color:item.outstandingDate?"#ccc":"#444"}}>{item.desiredUniversity}</TableCell>
                                            <TableCell style={{color:item.outstandingDate?"#ccc":"#444"}}>{item.interestedDate}</TableCell>
                                            <TableCell style={{color:item.outstandingDate?"#ccc":"#444"}}>{item.lastCallDate.split(" ")[0]}</TableCell>
                                            <TableCell style={{color:item.outstandingDate?"#ccc":"#444"}}><Button onClick={()=>{navigate("/advisor-incognito-leads/advisor-incognito-lead-detail", {state:{item:item}})}} style={{backgroundColor:"#352F6C", height:30}} size='medium' variant='contained'>View Details</Button></TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </div>
    )
}

export default AdvisorInterestedLeadsIncognito