import { ref, get, query, limitToLast } from "firebase/database"
import { firebaseDatabase } from "../backend/firebaseHandler"

const fetchNotification = async (uid) => {

    const notificationPromise = new Promise(async (resolve, reject) => {
        try {
            // Severity: Info, Warning, Critical
            const notificationsRef = ref(firebaseDatabase, `NOTIFICATION_ARCHIVE/${uid}`);
            const notificationQuery = query(notificationsRef, limitToLast(10));
            const notificationSnapshot = await get(notificationQuery);
            if (notificationSnapshot.exists()) {
                const notifications = Object.values(notificationSnapshot.val());
                resolve(notifications);
            } else {
                resolve([])
            }

        } catch (err) {
            reject(err);
        }
    })

    return notificationPromise;


}

export default fetchNotification;
