import React, { useContext, useEffect, useState } from "react";
import Styles from "./AdvisorCompleteDetailIncognito.module.css"
import { useLocation, useNavigate } from "react-router-dom";
import QRCode from "react-qr-code";
import { Alert, Button, MenuItem, Select, Snackbar, Tab, Tabs, Modal, Box } from "@mui/material";
import UserContext from "../../context/UserData.context";
import relationMap from "../../workload/CoSignerRelationMap";
// import documentMap from "../../workload/DocumentSet";
import { off, onValue, ref, remove, set } from "firebase/database";
import { firebaseDatabase, firebaseDatabaseSC, firebaseStorage } from "../../backend/firebaseHandler";
import moment from "moment";
import { FaExternalLinkSquareAlt } from "react-icons/fa"
import { getDownloadURL, ref as storageRef, uploadBytesResumable } from "firebase/storage";
import getDocumentMap from "../../workload/DocumentSet";
import { AiFillCloseSquare } from "react-icons/ai"
import CryptoJS from "crypto-js";
import InputContainer from "../InputContainer/InputContainer.component";
import getNbfcList from "../../workload/NbfcList";
import getMailList from "../../workload/MailList";

const AdvisorCompleteDetailIncognito = () => {

    const location = useLocation()
    const navigate = useNavigate()
    const [application, setApplication] = useState(location.state.item)
    const [caseDetail, setCaseDetail] = useState(application.caseDetail)
    const [userData, setUserData] = useContext(UserContext)
    const [value, setValue] = React.useState(0);
    const [submitting, setSubmitting] = useState(false)
    const [snackBar, setSnackBar] = useState({ visibility: false, severity: "", message: "" })
    const [examsWritten, setExamsWritten] = useState([])
    const [docNameModal, setDocNameModal] = useState(false)
    const [docName, setDocName] = useState("")
    const [qrLink, setQrLink] = useState("")
    const [updateList, setUpdateList] = useState([])
    const [mailList, setMailList] = useState(getMailList())
    const [outstandingModal, setOutstandingModal] = useState(false)

    useEffect(() => {
        if (application.examsWritten) {
            setExamsWritten(application.examsWritten)
        }

        if (application.mailList) {
            setMailList(application.mailList)
        }else {
            setMailList(getMailList());
        }

        switch (application.status) {
            case "Operations": {
                setQrLink(encrypt(`INCOGNITO_WITH_OPERATIONS_LEADS/${userData.uid}/${application.phoneNumber}`, "CutriPlingPutri"))
                break;
            }
            default: { }
        }

        if (application.status === "Operations") {
            let dbRef = ref(firebaseDatabase, `INCOGNITO_WITH_OPERATIONS_LEADS/${userData.uid}/${application.phoneNumber}`)
            onValue(dbRef, (snap) => {
                if (snap.exists()) {

                } else {
                    navigate(-1)
                }
            }, { onlyOnce: false })

            return () => {
                off(dbRef, "value")
            }
        }
    }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleCoApplicantSelect = (value) => {
        let tempObj = { ...application }
        tempObj.coApplicant = value
        tempObj.coApplicantProfession = ""
        tempObj.coApplicantAge = ""
        tempObj.coApplicantDocs = null
        tempObj.whyFatherNotFin = ""
        tempObj.whyMotherNotFin = ""
        tempObj.nonFinancialCoApplicants = [...relationMap[value]]

        let tempDocs = []
        for (const index in tempObj.nonFinancialCoApplicants) {
            tempDocs.push(getDocumentMap()["NonFinancial"])
        }

        tempObj.nonFinancialDocs = [...tempDocs]
        setApplication({ ...tempObj })
    }

    const handleProfession = (value) => {
        let tempObj = { ...application }
        tempObj.coApplicantProfession = value

        let searchWord = ""

        if (["Father", "Mother", "Spouse", "Father-in-law", "Mother-in-law", "Sister", "Brother"].includes(application.coApplicant)) {
            searchWord = "Direct"
        } else {
            searchWord = "InDirect"
        }
        tempObj.coApplicantDocs = [...getDocumentMap()[searchWord][value]]
        setApplication({ ...tempObj })
    }

    const handleWorkExperience = (value) => {
        setApplication({ ...application, hasWorkExperience: value })

        let tempObj = { ...application }
        tempObj.hasWorkExperience = value

        if (value === "Yes") {
            tempObj.applicantDocs = [...getDocumentMap()["StudentWorking"]]
        } else if (value === "No") {
            tempObj.applicantDocs = [...getDocumentMap()["StudentNonWorking"]]
        } else {
            tempObj.applicantDocs = [...getDocumentMap()["StudentUsedToWork"]]
        }

        setApplication({ ...tempObj })
    }

    const handleFatherReason = (value) => {
        let tempObj = { ...application }
        tempObj.whyFatherNotFin = value

        if (value === "Poor Cibil" || value === "No Income Proof or Low banking") {
            tempObj.nonFinancialDocs[0] = [...getDocumentMap()["NonFinancial"]]
        } else if (value === "Divorced") {
            tempObj.nonFinancialDocs[0] = [...getDocumentMap()["SeparatedFather"]]
        } else if (value === "Deceased") {
            tempObj.nonFinancialDocs[0] = [...getDocumentMap()["DeadFather"]]
        }

        setApplication({ ...tempObj })
    }

    const handleMotherReason = (value) => {
        let tempObj = { ...application }
        tempObj.whyMotherNotFin = value

        if (value === "Poor Cibil" || value === "No Income Proof or Low banking") {
            tempObj.nonFinancialDocs[1] = [...getDocumentMap()["NonFinancial"]]
        } else if (value === "Divorced") {
            tempObj.nonFinancialDocs[1] = [...getDocumentMap()["SeparatedMother"]]
        } else if (value === "Deceased") {
            tempObj.nonFinancialDocs[1] = [...getDocumentMap()["DeadMother"]]
        }

        setApplication({ ...tempObj })
    }

    const handleExams = (value) => {
        setExamsWritten(value)

        let tempArray = []
        let tempObj = { ...application }
        for (const index in value) {
            let obj = {
                name: value[index],
                url: "",
                label: value[index]
            }
            tempArray.push(obj)
        }
        tempObj["examDocs"] = [...tempArray]
        setApplication({ ...tempObj })
    }

    const handleDocumentUpload = (item, folderName, index, key) => {
        const inputElement = document.createElement('input');
        inputElement.setAttribute('type', "file");
        inputElement.setAttribute('accept', 'application/pdf');
        inputElement.onchange = (event) => {
            const files = event.target.files;

            if (files[0].name.split(".")[1] !== "pdf") {
                setSnackBar({ visibility: true, severity: "warning", message: "Please upload a PDF file." })
            } else {
                const fileRef = storageRef(firebaseStorage, `EDUCATION_LOAN_DOCS/${application.key}/${folderName}/${item.label}/${Date.now().toString()}`);
                setSubmitting(true)
                const uploadTask = uploadBytesResumable(fileRef, files[0], { contentType: 'application/pdf' });
                uploadTask.on('state_changed', (snapshot) => {

                }, (error) => {
                    console.log(error.message)
                    setSnackBar({ visibility: true, severity: "error", message: "Something went wrong" })
                }, async () => {
                    const url = await getDownloadURL(fileRef);
                    item.url = url
                    let tempObj = { ...application }
                    tempObj[key][index] = { ...item }
                    setApplication({ ...tempObj })
                    setSubmitting(false)
                })
            }
        }
        inputElement.click();
    }

    const handleNestedDocumentUpload = (item, folderName, index, index2, key) => {
        const inputElement = document.createElement('input');
        inputElement.setAttribute('type', "file");
        inputElement.setAttribute('accept', 'application/pdf');
        inputElement.onchange = (event) => {
            const files = event.target.files;

            if (files[0].name.split(".")[1] !== "pdf") {
                setSnackBar({ visibility: true, severity: "warning", message: "Please upload a PDF file." })
            } else {
                const fileRef = storageRef(firebaseStorage, `EDUCATION_LOAN_DOCS/${application.key}/${folderName}/${item.label}/${Date.now().toString()}`);
                setSubmitting(true)
                const uploadTask = uploadBytesResumable(fileRef, files[0], { contentType: 'application/pdf' });
                uploadTask.on('state_changed', (snapshot) => {

                }, (error) => {
                    console.log(error.message)
                    setSnackBar({ visibility: true, severity: "error", message: "Something went wrong" })
                }, async () => {
                    const url = await getDownloadURL(fileRef);
                    item.url = url
                    let tempObj = { ...application }
                    tempObj[key][index][index2] = { ...item }
                    console.log(key, index, index2, item)
                    setApplication({ ...tempObj })
                    setSubmitting(false)
                })
            }
        }
        inputElement.click();
    }

    const handleMailUpload = (folderName, key, index) => {
        const inputElement = document.createElement('input');
        inputElement.setAttribute('type', "file");
        inputElement.setAttribute('accept', 'application/pdf');
        inputElement.onchange = (event) => {
            const files = event.target.files;

            if (files[0].name.split(".")[1] !== "pdf") {
                setSnackBar({ visibility: true, severity: "warning", message: "Please upload a PDF file." })
            } else {
                const fileRef = storageRef(firebaseStorage, `EDUCATION_LOAN_DOCS/${application.key}/${folderName}/${key}/${Date.now().toString()}`);
                setSubmitting(true)
                const uploadTask = uploadBytesResumable(fileRef, files[0], { contentType: 'application/pdf' });
                uploadTask.on('state_changed', (snapshot) => {

                }, (error) => {
                    console.log(error.message)
                    setSnackBar({ visibility: true, severity: "error", message: "Something went wrong" })
                }, async () => {
                    const url = await getDownloadURL(fileRef);
                    let tempList = [...mailList]
                    let tempItem = tempList[index]
                    tempItem.url = url
                    tempList[index] = {...tempItem}
                    setMailList([...tempList])
                    setSubmitting(false)
                })
            }
        }
        inputElement.click();
    }

    const handleChangeNF = (value, label, index) => {
        let array = [...caseDetail.nfInformation]
        let obj = { ...array[index] }
        obj[label] = value
        array[index] = { ...obj }
        caseDetail.nfInformation = [...array]

        setCaseDetail({ ...caseDetail })
    }

    const handleSave = async () => {
        application.caseDetail = { ...caseDetail }
        application.examsWritten = [...examsWritten]

        if (application.coApplicantProfession !== "" && application.coApplicantAge !== "") {
            if (application.coApplicantProfession === "Pensioner" && parseInt(application.coApplicantAge) > 58) {
                setSnackBar({ visibility: true, severity: "warning", message: "Sorry! If the co-applicant is a Pensioner, the age can not be more than 58." })
                return
            }
        }
        if (application.status === "Vendor Status") {
            setSubmitting(true)

            if (mailList) {
                application.mailList = [...mailList]
            }

            await set(ref(firebaseDatabase, `INCOGNITO_WITH_VENDORS_LEADS/${userData.uid}/${application.phoneNumber}`), application)
            await set(ref(firebaseDatabase, `INCOGNITO_OP_USER_WITH_VENDORS_LEADS/${application.phoneNumber}`), application)
            await set(ref(firebaseDatabase, `INCOGNITO_LOAN_APPLICATIONS/${application.phoneNumber}`), application)
            setSnackBar({ visibility: true, severity: "success", message: "Changes saved!" })
        } else {
            if (application.subStatus === "New") {
                setSubmitting(true)
                await set(ref(firebaseDatabase, `INCOGNITO_WITH_OPERATIONS_LEADS/${userData.uid}/${application.phoneNumber}`), application)
                await set(ref(firebaseDatabase, `INCOGNITO_OP_USER_WITH_OPERATIONS_LEADS/${application.phoneNumber}`), application)
                await set(ref(firebaseDatabase, `INCOGNITO_LOAN_APPLICATIONS/${application.phoneNumber}`), application)
                setSnackBar({ visibility: true, severity: "success", message: "Changes saved!" })
            } else {
                setSubmitting(true)
                application.subStatus = "Re-submitted"
                application.resubmitToOperationDate = moment().format("DD-MM-YYYY h:mm a")
                await set(ref(firebaseDatabase, `INCOGNITO_WITH_OPERATIONS_LEADS/${userData.uid}/${application.phoneNumber}`), application)
                await set(ref(firebaseDatabase, `INCOGNITO_OP_USER_WITH_OPERATIONS_LEADS/${application.phoneNumber}`), application)
                await set(ref(firebaseDatabase, `INCOGNITO_LOAN_APPLICATIONS/${application.phoneNumber}`), application)

                onValue(ref(firebaseDatabase, `ALL_LEADS_STATUS/Incognito/${application.phoneNumber}`), async (snap) => {
                    if (snap.exists()) {
                        let lifelineObj = snap.val()

                        let array = [...lifelineObj.lifeline]
                        let arrayItem = {
                            actionBy: userData.uid,
                            status: "Operations",
                            subStatus: "Re-submitted",
                            date: application.resubmitToOperationDate,
                            ref: "INCOGNITO_WITH_OPERATIONS_LEADS"
                        }
                        array.push(arrayItem)
                        lifelineObj.lifeline = [...array]
                        await set(ref(firebaseDatabase, `ALL_LEADS_STATUS/Incognito/${application.phoneNumber}`), lifelineObj)
                        setApplication({ ...application })
                        setSnackBar({ visibility: true, severity: "success", message: "Operations notified!" })
                        setTimeout(() => {
                            setSubmitting(false)
                            navigate(-1)
                        }, 3000)
                    }
                }, { onlyOnce: true })
            }
        }


    }

    const handleLGDocs = (value) => {
        let tempObj = { ...application }

        tempObj.hasOwnHouse = value
        if (value === "No") {
            tempObj["locationGuarantorDocs"] = [...getDocumentMap()["LocationGuarantor"]]
        } else {
            tempObj["locationGuarantorDocs"] = []
        }

        setApplication({ ...tempObj })
    }

    const handleCurrentCountry = (value) => {
        let tempObj = { ...application }

        tempObj.currentlyInIndia = value
        if (value === "No") {
            tempObj["abroadDocs"] = [...getDocumentMap()["InAbroad"]]
        } else {
            tempObj["abroadDocs"] = []
        }
        console.log(tempObj)
        setApplication({ ...tempObj })
    }

    const handleCoAppLocation = (value) => {
        let tempObj = { ...application }

        tempObj.coApplicantInIndia = value
        if (value === "No") {
            tempObj["abroadCoAppDocs"] = [...getDocumentMap()["AbroadCoApplicant"]]
        } else {
            tempObj["abroadCoAppDocs"] = []
        }

        setApplication({ ...tempObj })
    }

    const handleDocumentNameAdd = () => {
        if (docName.includes("/") || docName.includes("$") || docName.includes("[") || docName.includes("]") || docName.includes("#") || docName.includes(".")) {
            setSnackBar({ visibility: true, severity: "warning", message: "Sorry. File name can not include `/`, `.`, `[`, `]`, `#`, `$`" })
            return
        }
        let tempObj = { ...application }
        let addDocArray = tempObj.additionalDocuments ? tempObj.additionalDocuments : []
        let docObject = {
            name: docName,
            label: docName,
            url: ""
        }

        addDocArray.push(docObject)
        tempObj.additionalDocuments = [...addDocArray]
        setApplication({ ...tempObj })
        setDocName("")
    }

    const handleRemoveAdditionalDoc = (item, index) => {
        let tempObj = { ...application }
        let addDocArray = [...tempObj.additionalDocuments]
        addDocArray.splice(index, 1)
        tempObj.additionalDocuments = [...addDocArray]
        setApplication({ ...tempObj })
    }

    function encrypt(message, key) {
        return CryptoJS.AES.encrypt(message, key).toString();
    }

    const handleOutstandingCase = async () => {
        if (!application.outstandingReason) {
            setSnackBar({ visibility: true, severity: "warning", message: "Please enter the reason!" })
            return
        }
        setSubmitting(true)

        application.caseDetail = { ...caseDetail }
        application.examsWritten = [...examsWritten]
        application.outstandingDate = moment().format("DD-MM-YYYY h:mm a")
        
        await set(ref(firebaseDatabase, `INCOGNITO_WITH_OPERATIONS_LEADS/${userData.uid}/${application.phoneNumber}`), application)
        await set(ref(firebaseDatabase, `INCOGNITO_OP_USER_WITH_OPERATIONS_LEADS/${application.phoneNumber}`), application)
        await set(ref(firebaseDatabase, `INCOGNITO_LOAN_APPLICATIONS/${application.phoneNumber}`), application)
        
        setSnackBar({ visibility: true, severity: "success", message: "Marked outstanding!" })
        setOutstandingModal(false)
    }

    return (
        <div className={Styles.advisorCompleteLeadDetail}>
            <div className={Styles.headerContainer}>

                <h1 style={{ color: "#352F6C" }}>Lead Detail</h1>

                <div className={Styles.qrMessageContainer}>
                    <p className={Styles.messageContainer}>Scan QR Code to make a call</p>
                    <div className={Styles.qrCodeContainer}>
                        <QRCode size={130} value={qrLink} viewBox={`0 0 256 256`} />
                    </div>
                </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 20, marginBottom: 40 }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" >
                    <Tab label="Basic Details" {...a11yProps(0)} />
                    <Tab label="Case Details" {...a11yProps(1)} />
                    <Tab label="Applicant Docs" {...a11yProps(2)} />
                    <Tab label="Co-Applicant Docs" {...a11yProps(3)} />
                    <Tab label="Non Financial Docs" {...a11yProps(4)} />
                </Tabs>
            </div>

            {
                (application.reAssignmentDetail && application.reAssignmentDetail.reason === "Advior In-active")
                &&
                <div style={{ backgroundColor: "#98fab2", padding: 15, borderRadius: 10, marginTop: 20, marginBottom: 20 }}>
                    <p>This lead was forwarded to you from <span style={{ fontWeight: 600 }}>{application.reAssignmentDetail.previousAdvisorName}</span>. The reason for reassignemnt is <span style={{ fontWeight: 600 }}>{application.reAssignmentDetail.reason}</span>.</p>
                </div>
            }

            {
                (application.subStatus.includes("Re-check"))
                &&
                <div style={{ backgroundColor: "#fac739", padding: 15, borderRadius: 10, marginTop: 20, marginBottom: 20 }}>
                    <p><span style={{ fontWeight: 600 }}>Note from Operations:</span> {application.noteFromOperations}</p>
                </div>
            }

            <div className={Styles.formListContainer}>
                {
                    value === 0
                    &&
                    <div className={Styles.formContainer}>
                        <InputContainer value={application.name} onChange={(event) => { setApplication({ ...application, name: event.target.value }) }} className={Styles.inputField} placeholder="Full Name" InputProps={{ disableUnderline: true }} variant="standard" />
                        <InputContainer value={application.phoneNumber?"XXXXXXXX"+application.phoneNumber.substring(9, 12):""} readOnly className={Styles.inputField} placeholder="Phone Number" InputProps={{ disableUnderline: true }} variant="standard" />
                        <InputContainer value={application.emailId} onChange={(event) => { setApplication({ ...application, emailId: event.target.value }) }} className={Styles.inputField} placeholder="Email Id" InputProps={{ disableUnderline: true }} variant="standard" />

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Date of Birth</p>
                            <input type="date" value={application.dob} onChange={(event) => { setApplication({ ...application, dob: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Level of Education</p>
                            <Select style={{ width: "100%" }} value={application.levelOfStudy} onChange={(event) => { setApplication({ ...application, levelOfStudy: event.target.value }) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                <MenuItem value="UG">UG</MenuItem>
                                <MenuItem value="PG">PG</MenuItem>
                                <MenuItem value="Diploma">Diploma</MenuItem>
                                <MenuItem value="Double Master">Double Master</MenuItem>
                                <MenuItem value="PHD">PHD</MenuItem>
                            </Select>
                        </div>

                        <div style={{ width: "100%", gridColumn: "1/2" }}>
                            <p className={Styles.label}>Currently in India?</p>
                            <Select style={{ width: "100%" }} value={application.currentlyInIndia} onChange={(event) => { handleCurrentCountry(event.target.value) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                <MenuItem value="Yes">Yes</MenuItem>
                                <MenuItem value="No">No</MenuItem>
                            </Select>
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Abroad Phone Number</p>
                            <input value={application.abroadPhoneNumber} onChange={(event) => { setApplication({ ...application, abroadPhoneNumber: event.target.value }) }} className={Styles.inputField} placeholder="Abroad Phone Number" InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                        <div style={{ width: "100%", gridColumn: "1/2" }}>
                            <p className={Styles.label}>Desired Country</p>
                            <input value={application.desiredCountry} onChange={(event) => { setApplication({ ...application, desiredCountry: event.target.value }) }} className={Styles.inputField} placeholder="Desired Country" InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Desired University</p>
                            <input value={application.desiredUniversity} onChange={(event) => { setApplication({ ...application, desiredUniversity: event.target.value }) }} className={Styles.inputField} placeholder="Desired University" InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Entrance Exams written</p>
                            <Select multiple style={{ width: "100%" }} value={examsWritten} onChange={(event) => { handleExams(event.target.value) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                <MenuItem value="GRE">GRE</MenuItem>
                                <MenuItem value="TOFEL">TOFEL</MenuItem>
                                <MenuItem value="IELTS">IELTS</MenuItem>
                                <MenuItem value="Duolingo">Duolingo</MenuItem>
                                <MenuItem value="PTE">PTE</MenuItem>
                                <MenuItem value="Other Exam">Other Exam</MenuItem>
                            </Select>
                        </div>

                        <InputContainer value={application.requiredLoanAmount} onChange={(event) => { setApplication({ ...application, requiredLoanAmount: event.target.value }) }} className={Styles.inputField} placeholder="Loan amount required" InputProps={{ disableUnderline: true }} variant="standard" />

                        <div className={Styles.yesNoContainer}>
                            <p>Have you applied to any Bank?</p>
                            <p style={{ color: application.hasPreAppliedToBank === "Yes" ? "rgba(53, 47, 108, 1)" : "rgba(53, 47, 108, 0.5)", cursor: "pointer" }} onClick={() => { setApplication({ ...application, hasPreAppliedToBank: "Yes" }) }}>Yes</p>
                            <p>|</p>
                            <p style={{ color: application.hasPreAppliedToBank === "No" ? "rgba(53, 47, 108, 1)" : "rgba(53, 47, 108, 0.5)", cursor: "pointer" }} onClick={() => { setApplication({ ...application, hasPreAppliedToBank: "No" }) }}>No</p>
                        </div>

                        <InputContainer value={application.preAppliedBank} readOnly={application.hasPreAppliedToBank !== "Yes"} onChange={(event) => { setApplication({ ...application, preAppliedBank: event.target.value }) }} className={Styles.inputField} placeholder="Bank Name" InputProps={{ disableUnderline: true }} variant="standard" />

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Work experience?</p>
                            <Select style={{ width: "100%" }} value={application.hasWorkExperience} onChange={(event) => { handleWorkExperience(event.target.value) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                <MenuItem value="Yes">Currently Working</MenuItem>
                                <MenuItem value="Past">Used to work</MenuItem>
                                <MenuItem value="No">No work experience</MenuItem>
                            </Select>
                        </div>

                        <div className={Styles.yesNoContainer}>
                            <p>Have your own house?</p>
                            <p style={{ color: application.hasOwnHouse === "Yes" ? "rgba(53, 47, 108, 1)" : "rgba(53, 47, 108, 0.5)", cursor: "pointer" }} onClick={() => { handleLGDocs("Yes") }}>Yes</p>
                            <p>|</p>
                            <p style={{ color: application.hasOwnHouse === "No" ? "rgba(53, 47, 108, 1)" : "rgba(53, 47, 108, 0.5)", cursor: "pointer" }} onClick={() => { handleLGDocs("No") }}>No</p>
                        </div>

                        <div style={{ width: "100%", gridColumn: "1/2" }}>
                            <p className={Styles.label}>Who will be your co-applicant?</p>
                            <Select style={{ width: "100%" }} value={application.coApplicant} onChange={(event) => { handleCoApplicantSelect(event.target.value) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                {
                                    Object.keys(relationMap).map((item, index) => {
                                        return (
                                            <MenuItem value={item}>{item}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </div>

                        {
                            application.coApplicant !== ""
                            &&
                            <>
                                <div style={{ width: "100%" }}>
                                    <p className={Styles.label}>Co-applicant's Profession?</p>
                                    <Select style={{ width: "100%" }} value={application.coApplicantProfession} onChange={(event) => { handleProfession(event.target.value) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                        <MenuItem value="Salaried">Salaried</MenuItem>
                                        <MenuItem value="Self Employed - Professional">Self Employed - Professional</MenuItem>
                                        <MenuItem value="Self Employed - Non Professional">Self Employed - Non Professional</MenuItem>
                                        <MenuItem value="Farmer">Farmer</MenuItem>
                                        <MenuItem value="Pensioner">Pensioner</MenuItem>
                                        <MenuItem value="Avanse Global">Avanse Global</MenuItem>
                                    </Select>
                                </div>
                                <div style={{ width: "100%" }}>
                                    <p className={Styles.label}>Co-applicant's Age?</p>
                                    <input value={application.coApplicantAge} onChange={(event) => { setApplication({ ...application, coApplicantAge: event.target.value }) }} className={Styles.inputField} placeholder="Co-applicant's Age" InputProps={{ disableUnderline: true }} variant="standard" />
                                </div>
                                <div style={{ width: "100%" }}>
                                    <p className={Styles.label}>Co-applicant in India?</p>
                                    <Select style={{ width: "100%" }} value={application.coApplicantInIndia} onChange={(event) => { handleCoAppLocation(event.target.value) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                        <MenuItem value="Yes">Yes</MenuItem>
                                        <MenuItem value="No">No</MenuItem>
                                    </Select>
                                </div>
                            </>
                        }

                        <div style={{ gridColumn: "1/4" }}>
                            {
                                relationMap[application.coApplicant].map((item, index) => {
                                    return (
                                        <div className={Styles.nonFinListContainer}>
                                            <div style={{ width: "100%" }}>
                                                <p className={Styles.label} style={{ color: "rgba(0,0,0,0)" }}>Number</p>
                                                <input value={"Non Financial Applicant " + (index + 1)} readOnly className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                                            </div>
                                            <div style={{ width: "100%" }}>
                                                <p className={Styles.label}>Relation</p>
                                                <input value={item} readOnly className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                                            </div>
                                            {
                                                item === "Father" && (application.coApplicant !== "Father" && application.coApplicant !== "Mother")
                                                &&
                                                <div style={{ width: "100%" }}>
                                                    <p className={Styles.label}>Why isn't your {item} a financial Co-applicant?</p>
                                                    <Select style={{ width: "100%" }} value={application.whyFatherNotFin} onChange={(event) => { handleFatherReason(event.target.value) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                                        <MenuItem value="Poor Cibil">Poor Cibil</MenuItem>
                                                        <MenuItem value="No Income Proof or Low banking">No Income Proof or Low banking</MenuItem>
                                                        <MenuItem value="Divorced">Divorced</MenuItem>
                                                        <MenuItem value="Deceased">Deceased</MenuItem>
                                                    </Select>
                                                </div>
                                            }
                                            {
                                                item === "Mother" && (application.coApplicant !== "Father" && application.coApplicant !== "Mother")
                                                &&
                                                <div style={{ width: "100%" }}>
                                                    <p className={Styles.label}>Why isn't your {item} a financial Co-applicant?</p>
                                                    <Select style={{ width: "100%" }} value={application.whyMotherNotFin} onChange={(event) => { handleMotherReason(event.target.value) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                                        <MenuItem value="Poor Cibil">Poor Cibil</MenuItem>
                                                        <MenuItem value="No Income Proof or Low banking">No Income Proof or Low banking</MenuItem>
                                                        <MenuItem value="Divorced">Divorced</MenuItem>
                                                        <MenuItem value="Deceased">Deceased</MenuItem>
                                                    </Select>
                                                </div>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>


                        {
                            application.isSpecialCase
                            &&
                            <div style={{ width: "100%", gridColumn: "1/4" }}>
                                <p className={Styles.label}>This has been marked as a Special Case</p>
                                <input value={application.specialCaseDescription} readOnly className={Styles.inputFieldSpecial} InputProps={{ disableUnderline: true }} variant="standard" />
                            </div>
                        }

                    </div>
                }

                {
                    value === 1
                    &&
                    <>
                        <p className={Styles.cdTabTitle}>Student Detail</p>
                        <div className={Styles.formContainer}>
                            <InputContainer value={application.name} readOnly className={Styles.inputField} placeholder="Full Name" InputProps={{ disableUnderline: true }} variant="standard" />
                            <InputContainer value={application.phoneNumber} readOnly className={Styles.inputField} placeholder="Phone Number" InputProps={{ disableUnderline: true }} variant="standard" />
                            <InputContainer value={caseDetail.emailId} readOnly className={Styles.inputField} placeholder="Email ID" InputProps={{ disableUnderline: true }} variant="standard" />

                            <InputContainer value={caseDetail.fatherName} onChange={(event) => { setCaseDetail({ ...caseDetail, fatherName: event.target.value }) }} className={Styles.inputField} placeholder="Father's Name" InputProps={{ disableUnderline: true }} variant="standard" />
                            <InputContainer value={caseDetail.motherName} onChange={(event) => { setCaseDetail({ ...caseDetail, motherName: event.target.value }) }} className={Styles.inputField} placeholder="Mother's Name" InputProps={{ disableUnderline: true }} variant="standard" />
                            <InputContainer value={caseDetail.requiredLoanAmount} readOnly className={Styles.inputField} placeholder="Loan Amount Required" InputProps={{ disableUnderline: true }} variant="standard" />

                            <div style={{ width: "100%" }}>
                                <p className={Styles.label}>Total cost of education</p>
                                <input value={caseDetail.costOfEducation} onChange={(event) => { setCaseDetail({ ...caseDetail, costOfEducation: event.target.value }) }} className={Styles.inputField} placeholder="Total cost of education" InputProps={{ disableUnderline: true }} variant="standard" />
                            </div>
                            <div style={{ width: "100%" }}>
                                <p className={Styles.label}>Marital Status</p>
                                <Select style={{ width: "100%" }} value={caseDetail.maritalStatus} onChange={(event) => { setCaseDetail({ ...caseDetail, maritalStatus: event.target.value }) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                    <MenuItem value="Single">Single</MenuItem>
                                    <MenuItem value="Married">Married</MenuItem>
                                    <MenuItem value="Divorced">Divorced</MenuItem>
                                </Select>
                            </div>
                            <div style={{ width: "100%" }}>
                                <p className={Styles.label}>Pan Number</p>
                                <input value={caseDetail.panNumber} onChange={(event) => { setCaseDetail({ ...caseDetail, panNumber: event.target.value }) }} className={Styles.inputField} placeholder="Pan Number" InputProps={{ disableUnderline: true }} variant="standard" />
                            </div>

                            <div style={{ gridColumn: "1/4" }}>
                                <InputContainer value={caseDetail.currentAddress} onChange={(event) => { setCaseDetail({ ...caseDetail, currentAddress: event.target.value }) }} className={Styles.inputField} placeholder="Current Address" InputProps={{ disableUnderline: true }} variant="standard" />
                            </div>

                            <div style={{ gridColumn: "1/4" }}>
                                <InputContainer value={caseDetail.permanentAddress} onChange={(event) => { setCaseDetail({ ...caseDetail, permanentAddress: event.target.value }) }} style={{ gridColumn: "1/4" }} className={Styles.inputField} placeholder="Permanent Address" InputProps={{ disableUnderline: true }} variant="standard" />
                            </div>
                        </div>

                        <p className={Styles.cdTabTitle}>Academic Detail</p>
                        <div className={Styles.formContainer}>
                            <InputContainer value={caseDetail.tenth} onChange={(event) => { setCaseDetail({ ...caseDetail, tenth: event.target.value }) }} className={Styles.inputField} placeholder="10th" InputProps={{ disableUnderline: true }} variant="standard" />
                            <InputContainer value={caseDetail.twelfth} onChange={(event) => { setCaseDetail({ ...caseDetail, twelfth: event.target.value }) }} className={Styles.inputField} placeholder="12th" InputProps={{ disableUnderline: true }} variant="standard" />
                            <InputContainer value={caseDetail.graduation} onChange={(event) => { setCaseDetail({ ...caseDetail, graduation: event.target.value }) }} className={Styles.inputField} placeholder="Graduation" InputProps={{ disableUnderline: true }} variant="standard" />

                            {
                                application.examsWritten
                                &&
                                application.examsWritten.map((item, index) => {
                                    return (
                                        <InputContainer value={caseDetail[item]} onChange={(event) => { setCaseDetail({ ...caseDetail, [item]: event.target.value }) }} className={Styles.inputField} placeholder={item} InputProps={{ disableUnderline: true }} variant="standard" />
                                    )
                                })
                            }
                        </div>

                        <p className={Styles.cdTabTitle}>Reference Detail</p>
                        <div className={Styles.formContainer}>
                            <InputContainer value={caseDetail.referenceName} onChange={(event) => { setCaseDetail({ ...caseDetail, referenceName: event.target.value }) }} style={{ gridColumn: "1/2" }} className={Styles.inputField} placeholder="Student Reference Name" InputProps={{ disableUnderline: true }} variant="standard" />
                            <InputContainer value={caseDetail.referenceNumber} onChange={(event) => { setCaseDetail({ ...caseDetail, referenceNumber: event.target.value }) }} className={Styles.inputField} placeholder="Student Reference Phone Number" InputProps={{ disableUnderline: true }} variant="standard" />
                            <InputContainer value={caseDetail.referenceOccupation} onChange={(event) => { setCaseDetail({ ...caseDetail, referenceOccupation: event.target.value }) }} className={Styles.inputField} placeholder="Student Reference Occupation" InputProps={{ disableUnderline: true }} variant="standard" />
                            <div style={{ gridColumn: "1/4" }}>
                                <InputContainer value={caseDetail.referenceAddress} onChange={(event) => { setCaseDetail({ ...caseDetail, referenceAddress: event.target.value }) }} style={{ gridColumn: "1/4" }} className={Styles.inputField} placeholder="Student Reference Permanent Address" InputProps={{ disableUnderline: true }} variant="standard" />
                            </div>
                        </div>

                        <p className={Styles.cdTabTitle}>Co-Applicant Detail</p>
                        <div className={Styles.formContainer}>
                            <InputContainer value={caseDetail.coAppName} onChange={(event) => { setCaseDetail({ ...caseDetail, coAppName: event.target.value }) }} className={Styles.inputField} placeholder="Name" InputProps={{ disableUnderline: true }} variant="standard" />
                            <InputContainer value={caseDetail.coAppPhoneNumber} onChange={(event) => { setCaseDetail({ ...caseDetail, coAppPhoneNumber: event.target.value }) }} className={Styles.inputField} placeholder="Phone Number" InputProps={{ disableUnderline: true }} variant="standard" />
                            <InputContainer value={caseDetail.coAppEmailId} onChange={(event) => { setCaseDetail({ ...caseDetail, coAppEmailId: event.target.value }) }} className={Styles.inputField} placeholder="Email Id" InputProps={{ disableUnderline: true }} variant="standard" />

                            <InputContainer value={caseDetail.coAppFatherName} onChange={(event) => { setCaseDetail({ ...caseDetail, coAppFatherName: event.target.value }) }} className={Styles.inputField} placeholder="Father's Name" InputProps={{ disableUnderline: true }} variant="standard" />
                            <InputContainer value={caseDetail.coAppMotherName} onChange={(event) => { setCaseDetail({ ...caseDetail, coAppMotherName: event.target.value }) }} className={Styles.inputField} placeholder="Mother's Name" InputProps={{ disableUnderline: true }} variant="standard" />
                            <InputContainer value={caseDetail.coAppQualification} onChange={(event) => { setCaseDetail({ ...caseDetail, coAppQualification: event.target.value }) }} className={Styles.inputField} placeholder="Qualification" InputProps={{ disableUnderline: true }} variant="standard" />

                            <InputContainer value={caseDetail.coAppNoOfDependents} onChange={(event) => { setCaseDetail({ ...caseDetail, coAppNoOfDependents: event.target.value }) }} className={Styles.inputField} placeholder="Number of Dependents" InputProps={{ disableUnderline: true }} variant="standard" />
                            <InputContainer value={caseDetail.coAppYearsInCurrentHouse} onChange={(event) => { setCaseDetail({ ...caseDetail, coAppYearsInCurrentHouse: event.target.value }) }} className={Styles.inputField} placeholder="No. of years in current house" InputProps={{ disableUnderline: true }} variant="standard" />

                            <div style={{ gridColumn: "1/4" }}>
                                <InputContainer value={caseDetail.coAppCurrentAddress} onChange={(event) => { setCaseDetail({ ...caseDetail, coAppCurrentAddress: event.target.value }) }} style={{ gridColumn: "1/4" }} className={Styles.inputField} placeholder="Current Address" InputProps={{ disableUnderline: true }} variant="standard" />
                            </div>
                            <div style={{ gridColumn: "1/4" }}>
                                <InputContainer value={caseDetail.coAppPermanentAddress} onChange={(event) => { setCaseDetail({ ...caseDetail, coAppPermanentAddress: event.target.value }) }} style={{ gridColumn: "1/4" }} className={Styles.inputField} placeholder="Permanent Address" InputProps={{ disableUnderline: true }} variant="standard" />
                            </div>

                            <InputContainer value={caseDetail.coAppYearsInCurrentOffice} onChange={(event) => { setCaseDetail({ ...caseDetail, coAppYearsInCurrentOffice: event.target.value }) }} className={Styles.inputField} placeholder="No. of years in current office" InputProps={{ disableUnderline: true }} variant="standard" />
                            <div style={{ gridColumn: "2/4" }}>
                                <InputContainer value={caseDetail.coAppOfficeAddress} onChange={(event) => { setCaseDetail({ ...caseDetail, coAppOfficeAddress: event.target.value }) }} style={{ gridColumn: "1/4" }} className={Styles.inputField} placeholder="Office Address" InputProps={{ disableUnderline: true }} variant="standard" />
                            </div>

                            <InputContainer value={caseDetail.coAppRefName} onChange={(event) => { setCaseDetail({ ...caseDetail, coAppRefName: event.target.value }) }} className={Styles.inputField} placeholder="Reference's Name" InputProps={{ disableUnderline: true }} variant="standard" />
                            <InputContainer value={caseDetail.coAppRefPhoneNumber} onChange={(event) => { setCaseDetail({ ...caseDetail, coAppRefPhoneNumber: event.target.value }) }} className={Styles.inputField} placeholder="Reference's Phone Number" InputProps={{ disableUnderline: true }} variant="standard" />
                            <InputContainer value={caseDetail.coAppRefOccupation} onChange={(event) => { setCaseDetail({ ...caseDetail, coAppRefOccupation: event.target.value }) }} className={Styles.inputField} placeholder="Reference's Occupation" InputProps={{ disableUnderline: true }} variant="standard" />
                            <div style={{ gridColumn: "1/4" }}>
                                <InputContainer value={caseDetail.coAppRefPermanentAddress} onChange={(event) => { setCaseDetail({ ...caseDetail, coAppRefPermanentAddress: event.target.value }) }} style={{ gridColumn: "1/4" }} className={Styles.inputField} placeholder="Reference's Permanent Address" InputProps={{ disableUnderline: true }} variant="standard" />
                            </div>
                        </div>

                        <p className={Styles.cdTabTitle}>Non Financial Applicant Detail</p>
                        <div className={Styles.subFormContainer}>
                            {
                                application.nonFinancialCoApplicants.map((item, index) => {
                                    return (
                                        <>
                                            {
                                                item === "Father" && (application.whyFatherNotFin === "Divorced" || application.whyFatherNotFin === "Deceased")
                                                    ?
                                                    null
                                                    :
                                                    item === "Mother" && (application.whyMotherNotFin === "Divorced" || application.whyMotherNotFin === "Deceased")
                                                        ?
                                                        null
                                                        :
                                                        <>
                                                            <p className={Styles.nfRelationLabel}>{item}</p>
                                                            <div className={Styles.formContainer} style={{ marginBottom: 20 }}>
                                                                <InputContainer value={caseDetail.nfInformation[index].name} onChange={(event) => { handleChangeNF(event.target.value, "name", index) }} className={Styles.inputField} placeholder="Full Name" InputProps={{ disableUnderline: true }} variant="standard" />
                                                                <InputContainer value={caseDetail.nfInformation[index].phoneNumber} onChange={(event) => { handleChangeNF(event.target.value, "phoneNumber", index) }} className={Styles.inputField} placeholder="Phone Number" InputProps={{ disableUnderline: true }} variant="standard" />
                                                                <InputContainer value={caseDetail.nfInformation[index].emailId} onChange={(event) => { handleChangeNF(event.target.value, "emailId", index) }} className={Styles.inputField} placeholder="Email ID" InputProps={{ disableUnderline: true }} variant="standard" />

                                                                <InputContainer value={caseDetail.nfInformation[index].fatherName} onChange={(event) => { handleChangeNF(event.target.value, "fatherName", index) }} className={Styles.inputField} placeholder="Father's Name" InputProps={{ disableUnderline: true }} variant="standard" />
                                                                <InputContainer value={caseDetail.nfInformation[index].motherName} onChange={(event) => { handleChangeNF(event.target.value, "motherName", index) }} className={Styles.inputField} placeholder="Mother's Name" InputProps={{ disableUnderline: true }} variant="standard" />
                                                                <InputContainer value={caseDetail.nfInformation[index].qualification} onChange={(event) => { handleChangeNF(event.target.value, "qualification", index) }} className={Styles.inputField} placeholder="Occupation" InputProps={{ disableUnderline: true }} variant="standard" />

                                                                <InputContainer value={caseDetail.nfInformation[index].noOfDependents} onChange={(event) => { handleChangeNF(event.target.value, "noOfDependents", index) }} className={Styles.inputField} placeholder="Number of Dependents" InputProps={{ disableUnderline: true }} variant="standard" />
                                                                <InputContainer value={caseDetail.nfInformation[index].noOfYearInCurrentHouse} onChange={(event) => { handleChangeNF(event.target.value, "noOfYearInCurrentHouse", index) }} className={Styles.inputField} placeholder="No. of years in current house" InputProps={{ disableUnderline: true }} variant="standard" />

                                                                <div style={{ gridColumn: "1/4" }}>
                                                                    <InputContainer value={caseDetail.nfInformation[index].currentAddress} onChange={(event) => { handleChangeNF(event.target.value, "currentAddress", index) }} style={{ gridColumn: "1/4" }} className={Styles.inputField} placeholder="Current Address" InputProps={{ disableUnderline: true }} variant="standard" />
                                                                </div>

                                                                <div style={{ gridColumn: "1/4" }}>
                                                                    <InputContainer value={caseDetail.nfInformation[index].permanentAddress} onChange={(event) => { handleChangeNF(event.target.value, "permanentAddress", index) }} style={{ gridColumn: "1/4" }} className={Styles.inputField} placeholder="Permanent Address" InputProps={{ disableUnderline: true }} variant="standard" />
                                                                </div>
                                                            </div>
                                                        </>
                                            }

                                        </>
                                    )
                                })
                            }
                        </div>
                    </>
                }

                {
                    value === 2
                    &&
                    <>
                        <div className={Styles.documentListContainer}>
                            {
                                !application.applicantDocs && !application.examDocs && !application.currentlyInIndia
                                    ?
                                    <p className={Styles.informationMessage}>Complete information not available to generate LOD</p>
                                    :
                                    application.applicantDocs
                                        ?
                                        application.applicantDocs.map((item, index) => {
                                            return (
                                                <div className={Styles.documentItem}>
                                                    <p onClick={() => { handleDocumentUpload(item, "Applicant Docs", index, "applicantDocs") }} style={{ color: item.url === "" ? null : "#12b004" }} className={Styles.documentName}>{item.name}</p>
                                                    <FaExternalLinkSquareAlt onClick={() => { window.open(item.url, "_blank") }} color={item.url === "" ? "" : "#12b004"} style={{ cursor: "pointer" }} />
                                                </div>
                                            )
                                        })
                                        :
                                        null
                            }

                        </div>
                        <div style={{ marginTop: 20 }} className={Styles.documentListContainer}>
                            {
                                application.abroadDocs
                                &&
                                application.abroadDocs.map((item, index) => {
                                    return (
                                        <div className={Styles.documentItem}>
                                            <p onClick={() => { handleDocumentUpload(item, "Applicant Docs", index, "abroadDocs") }} style={{ color: item.url === "" ? null : "#12b004" }} className={Styles.documentName}>{item.name}</p>
                                            <FaExternalLinkSquareAlt onClick={() => { window.open(item.url, "_blank") }} color={item.url === "" ? "" : "#12b004"} style={{ cursor: "pointer" }} />
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div style={{ marginTop: 20 }} className={Styles.documentListContainer}>
                            {
                                application.examDocs
                                &&
                                application.examDocs.map((item, index) => {
                                    return (
                                        <div className={Styles.documentItem}>
                                            <p onClick={() => { handleDocumentUpload(item, "Applicant Docs", index, "examDocs") }} style={{ color: item.url === "" ? null : "#12b004" }} className={Styles.documentName}>{item.name}</p>
                                            <FaExternalLinkSquareAlt onClick={() => { window.open(item.url, "_blank") }} color={item.url === "" ? "" : "#12b004"} style={{ cursor: "pointer" }} />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </>
                }

                {
                    value === 3
                    &&
                    <div className={Styles.documentListContainer}>
                        {
                            application.coApplicantDocs
                                ?
                                application.coApplicantDocs.map((item, index) => {
                                    return (
                                        <div className={Styles.documentItem}>
                                            <p onClick={() => { handleDocumentUpload(item, "Co-Applicant Docs", index, "coApplicantDocs") }} style={{ color: item.url === "" ? null : "#12b004" }} className={Styles.documentName}>{item.name}</p>
                                            <FaExternalLinkSquareAlt onClick={() => { window.open(item.url, "_blank") }} color={item.url === "" ? "" : "#12b004"} style={{ cursor: "pointer" }} />
                                        </div>
                                    )
                                })
                                :
                                <p className={Styles.informationMessage}>Complete information not available to generate LOD</p>
                        }
                        {
                            application.abroadCoAppDocs
                            &&
                            application.abroadCoAppDocs.map((item, index) => {
                                return (
                                    <div className={Styles.documentItem}>
                                        <p onClick={() => { handleDocumentUpload(item, "Co-Applicant Docs", index, "abroadCoAppDocs") }} style={{ color: item.url === "" ? null : "#12b004" }} className={Styles.documentName}>{item.name}</p>
                                        <FaExternalLinkSquareAlt onClick={() => { window.open(item.url, "_blank") }} color={item.url === "" ? "" : "#12b004"} style={{ cursor: "pointer" }} />
                                    </div>
                                )
                            })
                        }
                    </div>
                }

                {
                    value === 4
                    &&
                    <div>
                        {
                            application.nonFinancialCoApplicants
                                ?
                                <>
                                    {
                                        application.nonFinancialCoApplicants.map((item, index) => {
                                            return (
                                                <div>
                                                    <p className={Styles.nonFinRelationLabel}>{item}</p>

                                                    <div className={Styles.documentListContainer}>
                                                        {
                                                            application.nonFinancialDocs[index].map((item2, index2) => {
                                                                return (
                                                                    <div className={Styles.documentItem}>
                                                                        <p onClick={() => { handleNestedDocumentUpload(item2, "Non Financial Applicant Docs", index, index2, "nonFinancialDocs") }} style={{ color: item2.url === "" ? null : "#12b004" }} className={Styles.documentName}>{item2.name}</p>
                                                                        <FaExternalLinkSquareAlt onClick={() => { window.open(item2.url, "_blank") }} color={item2.url === "" ? "" : "#12b004"} style={{ cursor: "pointer" }} />
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    <div>
                                        <p className={Styles.nonFinRelationLabel}>Location Guarantor Documents</p>
                                        <div className={Styles.documentListContainer}>
                                            {
                                                application.locationGuarantorDocs
                                                    ?
                                                    application.locationGuarantorDocs.map((item, index) => {
                                                        return (
                                                            <div className={Styles.documentItem}>
                                                                <p onClick={() => { handleDocumentUpload(item, "Location Guarantor Docs", index, "locationGuarantorDocs") }} style={{ color: item.url === "" ? null : "#12b004" }} className={Styles.documentName}>{item.name}</p>
                                                                <FaExternalLinkSquareAlt onClick={() => { window.open(item.url, "_blank") }} color={item.url === "" ? "" : "#12b004"} style={{ cursor: "pointer" }} />
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    <p>None</p>
                                            }
                                        </div>
                                    </div>
                                    {
                                        application.status === "Vendor Status"
                                        &&
                                        <div>
                                            <p className={Styles.nonFinRelationLabel}>Consent Mail</p>
                                            <div className={Styles.documentListContainer}>
                                                {
                                                    mailList.map((item, index) => {
                                                        return (
                                                            <div className={Styles.documentItem}>
                                                                <p onClick={() => { handleMailUpload("Consent Mail", item.uid, index) }} style={{ color: item.url === "" ? null : "#12b004" }} className={Styles.documentName}>{item.name}</p>
                                                                <FaExternalLinkSquareAlt onClick={() => { window.open(item.url, "_blank") }} color={item.url === "" ? "" : "#12b004"} style={{ cursor: "pointer" }} />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    }
                                    <div>
                                        <p className={Styles.nonFinRelationClickableLabel} onClick={() => { setDocNameModal(true) }}>Additional Documents</p>

                                        <div className={Styles.documentListContainer}>
                                            {
                                                application.additionalDocuments
                                                    ?
                                                    application.additionalDocuments.map((item, index) => {
                                                        return (
                                                            <div className={Styles.documentItem}>
                                                                <p onClick={() => { handleDocumentUpload(item, "Additional Docs", index, "additionalDocuments") }} style={{ color: item.url === "" ? null : "#12b004" }} className={Styles.documentName}>{item.name}</p>
                                                                <FaExternalLinkSquareAlt onClick={() => { window.open(item.url, "_blank") }} color={item.url === "" ? "" : "#12b004"} style={{ cursor: "pointer" }} />
                                                                <AiFillCloseSquare color="red" size={20} style={{ cursor: "pointer" }} onClick={() => { handleRemoveAdditionalDoc(item, index) }} />
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    <p>None</p>
                                            }
                                        </div>
                                    </div>
                                </>
                                :
                                <p className={Styles.informationMessage}>Complete information not available to generate LOD</p>
                        }
                    </div>
                }
            </div>

            {
                !application.outstandingDate
                &&
                <div className={Styles.buttonsTypesContainer}>
                    {
                        application.status === "Operations"
                        &&
                        <Button onClick={() => { setOutstandingModal(true) }} style={{ backgroundColor: submitting ? "#ccc" : "#352F6C", width: 200, height: 40, marginTop: "auto" }} size='medium' variant='contained'>Mark Outstanding</Button>
                    }
                    <Button onClick={() => { handleSave() }} style={{ backgroundColor: submitting ? "#ccc" : "#352F6C", width: 200, height: 40, marginTop: "auto" }} size='medium' variant='contained'>Save</Button>
                </div>
            }

            {/* Additional Document Modal */}
            <Modal open={docNameModal} onClose={() => { setDocNameModal(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className={Styles.modalContainer}>
                        <p className={Styles.modalTitle} style={{ fontWeight: 400, marginBottom: 10 }}>Please enter the Document Name below</p>
                        <input value={docName} onChange={(event) => { setDocName(event.target.value) }} className={Styles.inputField} placeholder="Document Name" InputProps={{ disableUnderline: true }} variant="standard" />

                        <div className={Styles.buttonsContainer}>
                            <Button sx={{ backgroundColor: "rgba(53, 47, 108, 0.5)" }} onClick={() => { setDocNameModal(false) }} variant='contained' className={Styles.secondaryButton}>Cancel</Button>
                            <Button onClick={() => { handleDocumentNameAdd() }} variant='contained' className={Styles.primaryButton}>Add</Button>
                        </div>
                    </div>
                </Box>
            </Modal>

            {/* Outstanding Modal */}
            <Modal open={outstandingModal} onClose={() => { setOutstandingModal(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className={Styles.modalContainer}>
                        <p className={Styles.modalTitle} style={{ fontWeight: 400, marginBottom: 10 }}>Please enter the reason</p>
                        <input value={application.outstandingReason} onChange={(event) => { setApplication({ ...application, outstandingReason: event.target.value }) }} className={Styles.inputField} placeholder="" InputProps={{ disableUnderline: true }} variant="standard" />

                        <div className={Styles.buttonsContainer}>
                            <Button sx={{ backgroundColor: "rgba(53, 47, 108, 0.5)" }} onClick={() => { setOutstandingModal(false) }} variant='contained' className={Styles.secondaryButton}>Cancel</Button>
                            <Button onClick={() => { handleOutstandingCase() }} variant='contained' className={Styles.primaryButton}>Add</Button>
                        </div>
                    </div>
                </Box>
            </Modal>

            <Snackbar open={snackBar.visibility} autoHideDuration={3000} onClose={() => { setSnackBar({ visibility: false, message: "", severity: "" }) }}>
                <Alert onClose={() => { setSnackBar({ visibility: false, message: "", severity: "" }) }} severity={snackBar.severity} variant="filled" sx={{ width: '100%' }}>{snackBar.message}</Alert>
            </Snackbar>
        </div>
    )
}

export default AdvisorCompleteDetailIncognito