import React, { useContext, useEffect, useState } from "react";
import Styles from "./HiAdvisorNewLeads.style.css"
import { onValue, ref } from "firebase/database";
import { firebaseDatabase } from "../../../backend/firebaseHandler";
import UserContext from "../../../context/UserData.context";
import { Box, CircularProgress, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs } from "@mui/material";
import { IoCall } from "react-icons/io5"
import { useNavigate } from "react-router-dom";

const HiAdvisorNewLeads = () => {

    const [loading, setLoading] = useState(true);
    const [allLeads, setAllLeads] = useState([]);
    const [visibleList, setVisibleList] = useState([]);
    const [userData, setUserData] = useContext(UserContext);
    const [value, setValue] = React.useState(0);
    const navigate = useNavigate()


    useEffect(() => {
        let temp = []
        let tempVisible = []

        let localSavedLeads = sessionStorage.getItem("allLeads");
        const localSavedTab = sessionStorage.getItem("currentTabValue");
        setValue(localSavedTab || 0)

        if (localSavedLeads !== null && localSavedLeads !== undefined && localSavedLeads !== "") {
            localSavedLeads = JSON.parse(localSavedLeads);
            setAllLeads([...localSavedLeads]);
            handleChange(null, parseInt(localSavedTab), localSavedLeads);
            setLoading(false);
            console.log("local data")
        } else {
            console.log("Refetching")
            onValue(ref(firebaseDatabase, `HI_NEW_LEADS/${userData.uid}`), (snap) => {
                if (snap.exists()) {
                    for (const key in snap.val()) {
                        let item = snap.child(key).val()
                        if (item.type === "INBOUND") {
                            tempVisible.push(item)
                        }
                        temp.push(snap.child(key).val())
                    }
                }
                setVisibleList([...tempVisible])
                setAllLeads([...temp])
                sessionStorage.setItem("allLeads", JSON.stringify(temp));

                setLoading(false)
            }, { onlyOnce: true })
        }
    }, [])

    useEffect(() => {
        const clearSessionStorage = _ => {
            sessionStorage.clear();
        }
        window.addEventListener('beforeunload', clearSessionStorage)
        return () => {
            window.removeEventListener('beforeunload', clearSessionStorage)
        }
    }, [])

    const handleChange = (event, newValue, allLeads) => {
        setValue(newValue)
        let temp = []

        sessionStorage.setItem("currentTabValue", newValue);
        if (newValue === 0) {
            for (const index in allLeads) {
                let obj = allLeads[index]

                if (obj.type === "INBOUND") {
                    temp.push(obj)
                }
            }
        } else if (newValue === 1) {
            for (const index in allLeads) {
                let obj = allLeads[index]

                if (obj.type === "OUTBOUND") {
                    temp.push(obj)
                }
            }
        } else if (newValue === 2) {
            for (const index in allLeads) {
                let obj = allLeads[index]

                if (obj.type === "INTERNATIONAL") {
                    temp.push(obj)
                }
            }
        }

        setVisibleList(temp)
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <div>
            <h1 style={{ color: "#352F6C", marginBottom: 40 }}>New Leads</h1>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 20, marginBottom: 40 }}>
                <Tabs value={value} onChange={(event, newValue) => handleChange(null, newValue, allLeads)} aria-label="basic tabs example" >
                    <Tab label="Inbound Lead" {...a11yProps(0)} />
                    <Tab label="Outbound Leads" {...a11yProps(1)} />
                    <Tab label="International Numbers" {...a11yProps(1)} />
                </Tabs>
            </div>

            <div className={Styles.progressComponentContainer}>
                {
                    loading
                        ?
                        <CircularProgress className={Styles.progress} />
                        :
                        <Box>
                            <TableContainer>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>#</TableCell>
                                            <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Name</TableCell>
                                            <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Ph Number</TableCell>
                                            <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Email ID</TableCell>
                                            <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>University</TableCell>
                                            <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Date Received</TableCell>
                                            <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Last Call</TableCell>
                                            <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Next Call</TableCell>
                                            <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody style={{ backgroundColor: "white" }}>
                                        {
                                            visibleList.map((item, index) => {
                                                return (
                                                    <TableRow key={index.toString()} >
                                                        <TableCell>{(index + 1).toString()}</TableCell>
                                                        <TableCell>{item.name}</TableCell>
                                                        <TableCell>{item.phoneNumber}</TableCell>
                                                        <TableCell>{item.emailId}</TableCell>
                                                        <TableCell>{item.desiredUniversity}</TableCell>
                                                        <TableCell>{item.newFromAdminDate ? item.newFromAdminDate.split(" ")[0] : ""}</TableCell>
                                                        <TableCell>{item.lastCallDate ? item.lastCallDate.split(" ")[0] : ""}</TableCell>
                                                        <TableCell>{item.nextCallDateTime ? item.nextCallDateTime.split(" ")[0] : ""}</TableCell>
                                                        <TableCell><IoCall size={20} color="#352F6C" onClick={() => { navigate("/health-insurance/new-leads/new-lead-detail", { state: { item: item } }) }} style={{ cursor: "pointer" }} /></TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                }
            </div>

        </div>
    )
}

export default HiAdvisorNewLeads