const healthInsurance = {
    key: "",
    customerId: "",
    uidSC: "",
    phoneNumber: "",
    whatsappNumber: "",
    isThisWhatsappNumber: "Yes",
    name: "",
    emailId: "",
    desiredCountry: "",
    desiredUniversity: "",
    source: "",
    sourceDescription: "",
    type: "",
    date: "",
    status: "",
    subStatus: "",
    product: "",
    receivedFrom: {position: "", name: "", phoneNumber: "", emailId: ""},
    advisorPool: [],
    lastCallDate: "",
    nextCallDateTime: "",
    nextCallMode: "",
    newFromAdminDate: "",
    currentCountry: "",
    gender: "",
    selectedBusiness: "",
    selectedPlanName: "",
    selectedPlanId: "",
    age: "",
    dob: "",
    coveragePeriod: "",
    panNumber: "",
    sponsorName: "",
    emergencyNumber: "",
    alternateEmail: "",
    motherName: "",
    passportNumber: "",
    nomineeName: "",
    nomineeRelation: "",
    indiaAddress: "",
    haveUsAddress: "No",
    usAddress: "",
    travelDate: "",
    provider: "",
    paidFee: "",
    visaCategory: ""
}

export default healthInsurance