import React, { useContext, useState } from "react";
import Styles from "./AuthPage.module.css"
import UserContext from "../../context/UserData.context";
import { signInWithEmailAndPassword } from "firebase/auth";
import { firebaseAuth } from "../../backend/firebaseHandler";
import { Button, TextField } from "@mui/material";
import authIllus from "../../assets/authpage_illus.png"

const AuthPage = () => {

    const [loading, setLoading] = useState(false);
    const [credentials, setCredentials] = useState({
        emailId: "",
        password: ""
    });
    const [userData, setUserData] = useContext(UserContext)

    const handleLogin = async () => {
        try {
            setLoading(true);
            await signInWithEmailAndPassword(firebaseAuth, credentials.emailId, credentials.password)
        } catch (err) {
            alert(err.message)
        }
        setLoading(false);
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setCredentials({
            ...credentials,
            [name]: value
        })
    }

    return (
        <div className={Styles.authPageContainer}>
            <div className={Styles.authIllustrationContainer}>
                <p className={Styles.authContentText}>
                    “There is no secrets to success.
                    It is the result of preparation, hard work,
                    and learning from Failure.” -Colin Powell
                </p>
                <img className={Styles.authIllustration} alt='Student Cover CRM' src={authIllus} />
            </div>
            <div className={Styles.authFormContainer}>
                <div className={Styles.authForm}>
                    <h2 style={{ color: '#352F6C', marginTop: -120, marginBottom: 10, fontSize: '1.6rem' }}>Log In</h2>
                    <TextField onChange={handleChange} value={credentials.emailId} name='emailId' type='email' size='small' fullWidth id="outlined-basic" label="Email Id" variant="standard" />
                    <TextField onChange={handleChange} value={credentials.password} name='password' type='password' size='small' fullWidth id="outlined-basic" label="Password" variant="standard" />
                    <Button onClick={handleLogin} loading={loading} style={{ width: 200, marginTop: 10, backgroundColor:"#352f6c" }} variant='contained'>Login</Button>
                </div>
            </div>
        </div>
    )
}

export default AuthPage