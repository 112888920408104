import React, { useEffect, useState } from "react";
import Styles from "./BTandND.module.css"
import { Box, Button, CircularProgress, MenuItem, Select, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs } from "@mui/material";
import getNbfcList from "../../../workload/NbfcList";
import { onValue, ref } from "firebase/database";
import { firebaseDatabase } from "../../../backend/firebaseHandler";
import moment from "moment";
import exportFromJSON from "export-from-json";
import { HiDocumentDownload } from "react-icons/hi";

const BTandND = () => {

    const [value, setValue] = useState(0)
    const [leadList, setLeadList] = useState([])
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        handleChange(null, 0)
    }, [])



    const handleChange = (event, newValue) => {
        setValue(newValue)

        let temp = []
        let dbRef = null

        if (newValue === 0) {
            dbRef = ref(firebaseDatabase, `BALANCE_TRANSFER_REQUESTS`)
        } else {
            dbRef = ref(firebaseDatabase, `NEXT_DISBURSAL_REQUESTS`)
        }

        setLoading(true)
        onValue(dbRef, (snap) => {
            if (snap.exists()) {
                for (const key in snap.val()) {
                    let item = snap.child(key).val()
                    temp.push(item)
                }
                setLeadList(temp)
                setLoading(false)
            } else {
                setLeadList([])
                setLoading(false)
            }
        }, { onlyOnce: true })
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <div className={Styles.btAndNdContainer}>
            <h1 style={{ color: "#352F6C" }}>Balance Transfer and Next Disbursal</h1>
        
            <div style={{ marginTop: 20 }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" >
                    <Tab label="Balance Transfer" {...a11yProps(0)} />
                    <Tab label="Next Disbursal" {...a11yProps(1)} />
                </Tabs>
            </div>

            <div className={Styles.progressComponentContainer}>
                {
                    loading
                    ?
                    <CircularProgress className={Styles.progress} />
                    :
                    value === 0
                    ?
                    <Box>
                        <TableContainer>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>#</TableCell>
                                        <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Name</TableCell>
                                        <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Ph Number</TableCell>
                                        <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Email ID</TableCell>
                                        <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>On CRM?</TableCell>
                                        <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Country</TableCell>
                                        <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>University</TableCell>
                                        <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Status</TableCell>
                                        <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Sub Status</TableCell>
                                        <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>SC Advisor</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody style={{ backgroundColor: "white" }}>
                                    {
                                        leadList.map((item, index) => {
                                            return (
                                                <TableRow key={index.toString()} >
                                                    <TableCell>{index + 1}</TableCell>
                                                    <TableCell>{item.name}</TableCell>
                                                    <TableCell>{item.phoneNumber}</TableCell>
                                                    <TableCell>{item.emailId}</TableCell>
                                                    <TableCell>{item.hasApplied}</TableCell>
                                                    <TableCell>{item.desiredCountry}</TableCell>
                                                    <TableCell>{item.desiredUniversity}</TableCell>
                                                    <TableCell>{item.elStatus}</TableCell>
                                                    <TableCell>{item.subStatus}</TableCell>
                                                    <TableCell>{item.assignedAdvisorName}</TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    :
                    <Box>
                        <TableContainer>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>#</TableCell>
                                        <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Name</TableCell>
                                        <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Ph Number</TableCell>
                                        <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Email ID</TableCell>
                                        <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>On CRM?</TableCell>
                                        <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Country</TableCell>
                                        <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>University</TableCell>
                                        <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Status</TableCell>
                                        <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Sub Status</TableCell>
                                        <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>SC Advisor</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody style={{ backgroundColor: "white" }}>
                                    {
                                        leadList.map((item, index) => {
                                            return (
                                                <TableRow key={index.toString()} >
                                                    <TableCell>{index + 1}</TableCell>
                                                    <TableCell>{item.name}</TableCell>
                                                    <TableCell>{item.phoneNumber}</TableCell>
                                                    <TableCell>{item.emailId}</TableCell>
                                                    <TableCell>{item.hasApplied}</TableCell>
                                                    <TableCell>{item.desiredCountry}</TableCell>
                                                    <TableCell>{item.desiredUniversity}</TableCell>
                                                    <TableCell>{item.elStatus}</TableCell>
                                                    <TableCell>{item.subStatus}</TableCell>
                                                    <TableCell>{item.assignedAdvisorName}</TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                }
            </div>
        </div>
    )
}

export default BTandND