import React, { useContext, useEffect, useState } from "react";
import Styles from "./HiAdvisorOperationsLeads.module.css"
import { onValue, ref } from "firebase/database";
import { firebaseDatabase } from "../../../backend/firebaseHandler";
import UserContext from "../../../context/UserData.context";
import { Box, CircularProgress, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs } from "@mui/material";
import { IoCall } from "react-icons/io5"
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";

const HiAdvisorOperationsLeads = () => {

    const [loading, setLoading] = useState(true)
    const [visibleList, setVisibleList] = useState([])
    const [userData] = useContext(UserContext)
    const navigate = useNavigate()

    useEffect(() => {
        let temp = []
        let temp2 = []

        onValue(ref(firebaseDatabase, `HI_WITH_OPERATIONS_LEADS/${userData.uid}`), (snap) => {
            if (snap.exists()) {
                for (const key in snap.val()) {
                    let item = snap.child(key).val()
                    let travelDate = moment(item.travelDate, "DD-MM-YYYY").format("YYYY-MM-DD")
                    item.travelAge = moment(travelDate).diff(moment(), 'days')
                    temp.push(item)
                }

                temp2 = temp.sort((a, b) => {
                    const date_1 = moment(a.sentToOperationsDate,"DD-MM-YYYY h:mm a")
                    const date_2 = moment(b.sentToOperationsDate,"DD-MM-YYYY h:mm a")
                    return moment(date_2).diff(date_1)
                })
            }
            setVisibleList([...temp2])
            setLoading(false)
        }, {onlyOnce:true})
    }, [])

    return (
        <div>
            <h1 style={{ color: "#352F6C", marginBottom:40 }}>Operations Leads</h1>

            <div className={Styles.progressComponentContainer}>
                {
                    loading
                    ?
                    <CircularProgress className={Styles.progress} />
                    :
                    <Box>
                        <TableContainer>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>#</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Name</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Ph Number</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Email ID</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>University</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Date Received</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Days before travel</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Sent to opr. on</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody style={{backgroundColor:"white"}}>
                                    {
                                        visibleList.map((item, index) => {
                                            return (
                                                <TableRow key={index.toString()} >
                                                    <TableCell>{(index + 1).toString()}</TableCell>
                                                    <TableCell>{item.name}</TableCell>
                                                    <TableCell>{item.phoneNumber}</TableCell>
                                                    <TableCell>{item.emailId}</TableCell>
                                                    <TableCell>{item.desiredUniversity}</TableCell>
                                                    <TableCell>{item.newFromAdminDate?item.newFromAdminDate.split(" ")[0]:""}</TableCell>
                                                    <TableCell>{item.travelAge} days</TableCell>
                                                    <TableCell>{item.sentToOperationsDate?item.sentToOperationsDate.split(" ")[0]:""}</TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                }
            </div>
        </div>
    )
}

export default HiAdvisorOperationsLeads