import React, { useContext, useEffect, useState } from "react";
import Styles from "./VendorClosedLeads.module.css"
import { Box, Tab, Tabs, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress } from "@mui/material";
import UserContext from "../../../context/UserData.context";
import { useNavigate } from "react-router-dom";
import { onValue, ref } from "firebase/database";
import { firebaseDatabase } from "../../../backend/firebaseHandler";
import { HiDocumentCheck } from "react-icons/hi2";
import { IoMdInformationCircle } from "react-icons/io";
import { IoFolderOpenSharp } from "react-icons/io5";

const VendorClosedLeads = () => {

    const [activeList, setActiveList] = useState([])
    const [userData, setUserData] = useContext(UserContext)
    const [value, setValue] = React.useState(0);
    const [loading, setLoading] = useState(true)
    const [rejectedFetched, setRejectedFetched] = useState(false)
    const [closedList, setClosedList] = useState([])
    const [rejectedList, setRejectedList] = useState([])
    const navigate = useNavigate();

    useEffect(() => {
        let temp = []

        onValue(ref(firebaseDatabase, `VENDOR_CLOSED_LEADS/${userData.nbfcName}`), (snap) => {
            if (snap.exists()) {
                for (const key in snap.val()) {
                    temp.push(snap.child(key).val())
                }
            }
            setActiveList(temp)
            setClosedList(temp)
            setLoading(false)
        }, {onlyOnce:true})
    }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue)

        if (newValue === 0) {
            setActiveList(closedList)
        } else {
            if (rejectedFetched) {
                setActiveList(rejectedList)
            } else {
                let temp = []
                setLoading(true)

                onValue(ref(firebaseDatabase, `VENDOR_REJECTED_LEADS/${userData.nbfcName}`), (snap) => {
                    if (snap.exists()) {
                        for (const key in snap.val()) {
                            temp.push(snap.child(key).val())
                        }
                    }
                    setActiveList(temp)
                    setRejectedList(temp)
                    setRejectedFetched(true)
                    setLoading(false)
                }, {onlyOnce:true})
            }
        }
    }


    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <div>
            <div>
                <h1 style={{ color: "#352F6C" }}>Closed/Rejected Leads</h1>
            </div>

            <div style={{marginTop:40}}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" >
                    <Tab label="Closed Leads" {...a11yProps(0)} />
                    <Tab label="Rejected Leads" {...a11yProps(1)} />
                </Tabs>
            </div>

            <div className={Styles.progressComponentContainer}>
                {
                    loading
                    ?
                    <CircularProgress className={Styles.progress} />
                    :
                    <Box>
                        <TableContainer>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>#</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Customer ID</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Name</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Ph Number</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Email ID</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Date Received</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>{value===0?"Date Closed":"Date Rejected"}</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>File</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody style={{backgroundColor:"white"}}>
                                    {
                                        activeList.map((item, index) => {
                                            return (
                                                <TableRow key={index.toString()} >
                                                    <TableCell>{(index + 1).toString()}</TableCell>
                                                    <TableCell>{item.customerId}</TableCell>
                                                    <TableCell>{item.name}</TableCell>
                                                    <TableCell>{item.phoneNumber}</TableCell>
                                                    <TableCell>{item.emailId}</TableCell>
                                                    <TableCell>{item.rmAssignmentDate.split(" ")[0]}</TableCell>
                                                    <TableCell>{value===0?item.recheckFromVendorDate:item.fromVendorStatusDate}</TableCell>
                                                    <TableCell><IoFolderOpenSharp  size={20} color="#352F6C" onClick={()=>{navigate("/vendor-view-file", { state: { item: item } })}} style={{cursor:"pointer"}} /></TableCell>                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                }
            </div>
        </div>
    )
}

export default VendorClosedLeads