import React, { useEffect, useState } from "react";
import Styles from "./AdvisorOverAll.module.css";
import axios from "axios";
import stc from 'string-to-color';

const AdvisorOverall = ({ uid }) => {
    const [data, setData] = useState({ data: {}, loading: true, noData: false })

    const fetchData = async () => {
        try {
            const baseURL = "https://sc-srm-api-nine.vercel.app/reporting/get-advisor-stats-overall";
            const response = await axios(baseURL, {
                params: {
                    assignedAdvisorUid: uid
                }
            })
            const data = await response.data;
            setData({ data, loading: false });
        } catch (err) {

        }

    };


    useEffect(() => {
        fetchData()
    }, [])

    console.log(Object.keys(data.data))


    return (
        <div style={{ flex: 1, backgroundColor: 'white', borderRadius: '12px', padding: '24px' }}>
            <h3>Overall Progress</h3>
            <div className={Styles.gridContainer}>
                {
                    Object.keys(data.data).map(item => {
                        return (
                            <div style={{ backgroundColor: getTileBackgroundColor(item), padding: '12px', borderRadius: '12px', display: 'flex', flexDirection: 'column', gap: '12px' }}>
                                <h4>{item}</h4>
                                <p style={{ fontSize: '1.2rem' }}>{data.data[item]}</p>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}


function getTileBackgroundColor(title) {
    const colors = {
        "Vendor Status": "#E0E0E0",
        "Closed": "#B0BEC5",
        "New": "#FFCDD2",
        "Case Details": "#CFD8DC",
        "Interested": "#C8E6C9",
        "SL Received": "#FFF9C4",
        "Disbursed": "#D1C4E9",
        "Operations": "#FFE0B2"
    };

    return colors[title] || "#FFFFFF"; // Default to white if title not found
}
export default AdvisorOverall;