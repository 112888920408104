import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from "react";
import Styles from "./DistributeLeads.module.css"
import UserContext from "../../../context/UserData.context";
import { off, onValue, push, ref, remove, set } from "firebase/database";
import { firebaseDatabase } from "../../../backend/firebaseHandler";
import moment from "moment";
import exportFromJSON from "export-from-json";
import * as XLSX from 'xlsx';
import { Button, Divider, Tab, Tabs, Stack, Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Dialog, DialogTitle, DialogContent, DialogActions, Checkbox, Modal, Switch, Select, MenuItem } from '@mui/material';
import { IoAddCircle } from 'react-icons/io5';
import _ from 'lodash';
import educationLoan from "../../../entities/EducationLoan.sample";
import healthInsurance from "../../../entities/HealthInsurance.sample";
import { checkIfEntryExists } from "../../../workload/DuplicationReportHelper";

const DistributeLeads = () => {

    const [userData, setUserData] = useContext(UserContext)

    const [value, setValue] = React.useState(0);
    const [subValue, setSubValue] = React.useState(0);

    const [visibleList, setVisibleList] = useState([])

    const [allAdvisors, setAllAdvisors] = useState([])
    const [filteredAdvisors, setFilteredAdvisors] = useState([])
    const [selectedAdvisors, setSelectedAdvisors] = useState([])

    const [uniqueList, setUniqueList] = useState([])
    const [duplicationStat, setDuplicationStat] = useState({ new: 0, interested: 0, caseDetails: 0, operations: 0, vendor: 0, pfPaid: 0, slReceived: 0, disbursement: 0, closed: 0 })
    const [dupliateList, setDuplicateList] = useState([])

    const [statDialog, setStatDialog] = useState(false)
    const [userListDialog, setUserListDialog] = useState(false)
    const [productDialog, setProductDialog] = useState(false)

    const [selectedProduct, setSelectedProduct] = useState("")
    const [progress, setProgress] = useState(false)
    const [shareWithAll, setShareWithAll] = useState(false)
    const productArray = ["Education Loan", "Health Insurance", "Travel Insurance", "Dental Insurance", "Admission Counselling"]

    const [singleLeadModal, setSingleLeadModal] = useState(false)
    const [selectedSingleAdvisor, setSelectedSingleAdvisor] = useState("")
    const [selectedSingleLead, setSelectedSingleLead] = useState({})
    const [submitting, setSubmitting] = useState(false)
    const handleDistributeButtonRef = useRef();
    const [alreadyExistsModal, setAlreadyExistsModal] = useState(false)
    const [preExistingFile, setPreExistingFile] = useState({})

    useEffect(() => {
        let temp = []

        onValue(ref(firebaseDatabase, "POOL_INBOUND_APPLICATIONS/Education Loan"), (snap) => {
            if (snap.exists()) {
                temp = []
                for (const key in snap.val()) {
                    temp.push(snap.child(key).val())
                }
                setVisibleList(temp)
            }
        }, { onlyOnce: true })

        let tempAd = []

        onValue(ref(firebaseDatabase, "USER_ARCHIVE"), (snapShot) => {
            if (snapShot.exists()) {
                for (const key in snapShot.val()) {
                    let item = snapShot.child(key).val()
                    if (item.department === "Health Insurance") {
                        if ((item.position === "Advisor" || item.position === "Manager") && item.status === "Active") {
                            tempAd.push(item)
                        }
                    } else if (item.department === "Education Loan") {
                        if (item.position === "Advisor" && item.status === "Active") {
                            tempAd.push(item)
                        }
                    }

                }
                setAllAdvisors(tempAd)
            }
        }, { onlyOnce: true })
    }, [])

    const handleChange = (event, newValue) => {
        let tempVisible = []
        setValue(newValue)
        setSubValue(0)

        let dbRef = null

        if (newValue === 0) {
            dbRef = ref(firebaseDatabase, "POOL_INBOUND_APPLICATIONS/Education Loan")
        } else if (newValue === 1) {
            dbRef = ref(firebaseDatabase, "POOL_OUTBOUND_APPLICATIONS/Education Loan")
        } else if (newValue === 2) {
            dbRef = ref(firebaseDatabase, "POOL_WEBSITE_APPLICATIONS/Education Loan")
        } else if (newValue === 3) {
            dbRef = ref(firebaseDatabase, `POOL_DIRECT_APPLICATIONS/Education Loan`)
        } else if (newValue === 4) {
            dbRef = ref(firebaseDatabase, `POOL_INTERNATIONAL_APPLICATIONS/Education Loan`)
        }

        onValue(dbRef, (snap) => {
            if (snap.exists()) {

                tempVisible = []
                for (const key in snap.val()) {
                    let item = snap.child(key).val()
                    tempVisible.push(item)
                }
                setVisibleList(tempVisible)
            } else {
                setVisibleList([])
            }
        }, { onlyOnce: true })
    }

    const handleSubChange = (event, newValue) => {
        let tempVisible = []
        setSubValue(newValue)

        let dbRef = null

        if (value === 0) {
            dbRef = ref(firebaseDatabase, `POOL_INBOUND_APPLICATIONS/${productArray[newValue]}`)
        } else if (value === 1) {
            dbRef = ref(firebaseDatabase, `POOL_OUTBOUND_APPLICATIONS/${productArray[newValue]}`)
        } else if (value === 2) {
            dbRef = ref(firebaseDatabase, `POOL_WEBSITE_APPLICATIONS/${productArray[newValue]}`)
        } else if (value === 3) {
            dbRef = ref(firebaseDatabase, `POOL_DIRECT_APPLICATIONS/${productArray[newValue]}`)
        } else if (value === 4) {
            dbRef = ref(firebaseDatabase, `POOL_INTERNATIONAL_APPLICATIONS/${productArray[newValue]}`)
        }

        onValue(dbRef, (snap) => {
            if (snap.exists()) {
                tempVisible = []
                for (const key in snap.val()) {
                    let item = snap.child(key).val()
                    tempVisible.push(item)
                }
                setVisibleList(tempVisible)
            } else {
                setVisibleList([])
            }
        }, { onlyOnce: true })

    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    function subA11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleUploadLeads = () => {

        if (selectedProduct === "") {
            return
        }

        if (selectedProduct !== "Health Insurance" && value === 4) {
            alert("Sorry! This module is only available for Health Insurance.")
            return
        }

        const inputElement = document.createElement("input")
        inputElement.setAttribute("type", "file")

        inputElement.onchange = (event) => {
            const file = event.target.files[0];
            let size = 0
            let flag = false
            let invalidRowNumbers = []
            let invalidData = []

            const reader = new FileReader();
            reader.onload = async (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                jsonData.shift()

                let finalData = {}
                jsonData.forEach(item => {
                    try {
                        const name = item[0] === undefined ? "" : item[0].toString()
                        
                        const emailId = item[2] === undefined ? "" : item[2].toString()
                        const countryOfStudy = item[3] === undefined ? "" : item[3].toString()
                        const desiredUniversity = item[4] === undefined ? "" : item[4].toString()
                        const source = item[5] === undefined ? "" : item[5].toString()
                        const sourceDescription = item[6] === undefined ? "" : item[6].toString()
                        const specialNoteFromAdmin = item[7] === undefined ? "" : item[7].toString()
                        let phoneNumber = ""

                        if (value === 4) {
                            phoneNumber = item[1].toString()
                        } else {
                            phoneNumber = "91" + item[1].toString()
                        }
                        if (value !== 4 && phoneNumber.length !== 12) {
                            invalidRowNumbers.push((size + 2).toString())
                            invalidData.push(item)
                            return
                        } else {
                            const forKeyRef = ref(firebaseDatabase, `/POOL_OUTBOUND_APPLICATIONS/${selectedProduct}`)
                            const key = push(forKeyRef).key;

                            let sampleData = {}

                            if (subValue === 0) {
                                sampleData = { ...educationLoan }
                            } else {
                                sampleData = { ...healthInsurance }
                            }

                            sampleData.key = key
                            sampleData.phoneNumber = phoneNumber
                            sampleData.name = name
                            sampleData.emailId = emailId
                            sampleData.desiredCountry = countryOfStudy
                            sampleData.desiredUniversity = desiredUniversity
                            sampleData.source = source
                            sampleData.sourceDescription = sourceDescription
                            sampleData.type = value
                            sampleData.date = moment().format("DD-MM-YYYY h:mm a")
                            sampleData.status = "Pool"
                            sampleData.subStatus = ""
                            sampleData.product = selectedProduct
                            sampleData.receivedFrom = { "position": userData.position, "name": userData.name, "phoneNumber": userData.phoneNumber, "uid": userData.uid }
                            sampleData.advisorPool = []
                            sampleData.specialNoteFromAdmin = specialNoteFromAdmin

                            finalData[phoneNumber] = {
                                ...sampleData
                            }

                        }
                        size++
                    } catch (err) {
                        alert("Something went wrong. Please check if the sheet has valid data.")
                        flag = true
                        return
                    }

                })

                if (!flag) {
                    setProgress(true)
                    if (value === 0) {
                        for (const key in finalData) {
                            await set(ref(firebaseDatabase, `/POOL_INBOUND_APPLICATIONS/${selectedProduct}/${key}`), finalData[key])
                        }
                    } else if (value === 1) {
                        for (const key in finalData) {
                            await set(ref(firebaseDatabase, `/POOL_OUTBOUND_APPLICATIONS/${selectedProduct}/${key}`), finalData[key])
                        }
                    } else if (value === 4) {                      
                        for (const key in finalData) {
                            if (key.includes("+") || key.includes(" ")) {
                                alert("Invalid Phone Number. Contains '+' or blank spaces.")
                                setProgress(false)
                                return
                            }
                            await set(ref(firebaseDatabase, `/POOL_INTERNATIONAL_APPLICATIONS/${selectedProduct}/${key}`), finalData[key])
                        }
                    }

                    setProgress(false)
                    setSubValue(productArray.indexOf(selectedProduct))
                    if (invalidData.length !== 0) {
                        alert("Valid leads uploaded. Invalid leads found in rows " + invalidRowNumbers.join(", ") + ". A sheet containing all the invalid data is downloaded.")

                        let data = []
                        let date = moment().format("DD-MM-YYYY")
                        for (const index in invalidData) {
                            let tempObj = {
                                "Customer Name": invalidData[index][0],
                                "Phone Number": invalidData[index][1],
                                "Email ID": invalidData[index][2],
                                "Country of Study": invalidData[index][3],
                                "Desired University": invalidData[index][4],
                                "Source": invalidData[index][5],
                                "SourceDescription": invalidData[index][6]
                            }
                            data.push(tempObj)
                        }
                        const fileName = 'Invalid Data: ' + date
                        const exportType = exportFromJSON.types.csv

                        exportFromJSON({ data, fileName, exportType })
                    } else {
                        alert("Done")
                        handleSubChange(null, productArray.indexOf(selectedProduct))
                    }
                    setProductDialog(false)
                }
            }
            reader.readAsArrayBuffer(file)
        }
        inputElement.click();
    }

    const filterAdvisors = () => {
        let temp = []
        for (const index in allAdvisors) {

            let item = allAdvisors[index]
            if (value === 0) {
                if (item.rights.includes("HandleInboundLeads") && item.department === productArray[subValue]) {
                    temp.push(item)
                }
            } else {
                if (item.department === productArray[subValue]) {
                    temp.push(item)
                }
            }
        }
        // if (subValue === 1) {
        //     temp.push(userData)
        // }
        if (temp.length === 0) {
            alert("Sorry! No advisors added under " + productArray[subValue])
        } else {
            setFilteredAdvisors(temp)
            if (value === 2 || value === 3) {
                setSingleLeadModal(true)
            } else {
                setUserListDialog(true)
            }
        }
    }

    const handleCheckbox = (item, state) => {
        let temp = [...selectedAdvisors]
        if (state) {
            temp.push(item)
        } else {
            const removeIndex = _.indexOf(selectedAdvisors, item);
            temp.splice(removeIndex, 1)
        }

        setSelectedAdvisors([...temp])
    }

    const handleDistribute = async () => {
        if (selectedAdvisors.length === 0) {
            return
        }

        let uniqueTemp = []
        let duplicateTemp = []

        let leadList = [...visibleList]
        const numberList = []
        let number = 0
        let date = moment().format("DD-MM-YYYY h:mm a")

        for (const index in leadList) {
            let item = leadList[index]
            item.assignedAdvisorUid = selectedAdvisors[number].uid
            item.assignedAdvisorName = selectedAdvisors[number].name
            item.assignedAdvisorPhoneNumber = selectedAdvisors[number].phoneNumber
            item.newFromAdminDate = date
            item.type = value === 1 ? "OUTBOUND" : value === 4 ? "INTERNATIONAL" : "INBOUND"
            if (number === selectedAdvisors.length - 1) {
                number = 0
            } else {
                number++
            }
            numberList.push(item.phoneNumber)
        }

        if (subValue === 0) {
            let tempStat = { new: 0, interested: 0, caseDetails: 0, operations: 0, vendor: 0, pfPaid: 0, slReceived: 0, disbursement: 0, closed: 0 }
            for (const [index1, phoneNumber] of numberList.entries()) {
                const status = await checkIfEntryExists(phoneNumber, productArray[subValue])
                handleDistributeButtonRef.current.handleLoading(true, leadList.length, index1);

                switch (status) {
                    case "New": {
                        tempStat.new += 1;
                        duplicateTemp.push(leadList[index1])
                        break;
                    }
                    case "Interested": {
                        tempStat.interested += 1;
                        duplicateTemp.push(leadList[index1])
                        break;
                    }
                    case "Case Details": {
                        tempStat.caseDetails += 1;
                        duplicateTemp.push(leadList[index1])
                        break;
                    }
                    case "Operations": {
                        tempStat.operations += 1;
                        duplicateTemp.push(leadList[index1])
                        break;
                    }
                    case "Vendor Status": {
                        tempStat.vendor += 1;
                        duplicateTemp.push(leadList[index1])
                        break;
                    }
                    case "PF Paid": {
                        tempStat.pfPaid += 1;
                        duplicateTemp.push(leadList[index1])
                        break;
                    }
                    case "SL Received": {
                        tempStat.slReceived += 1;
                        duplicateTemp.push(leadList[index1])
                        break;
                    }
                    case "Disbursed": {
                        tempStat.disbursement += 1;
                        duplicateTemp.push(leadList[index1])
                        break;
                    }
                    case "Closed": {
                        tempStat.closed += 1;
                        duplicateTemp.push(leadList[index1])
                        break;
                    }
                    case "Unique": {
                        uniqueTemp.push(leadList[index1]);
                        break
                    }
                    default: { }
                }
            }
            setUniqueList(uniqueTemp)
            setDuplicationStat({ ...tempStat })
            setDuplicateList(duplicateTemp)
            setStatDialog(true)
            handleDistributeButtonRef.current.handleLoading(false, 0, 0);
        } else if (subValue === 1) {
            let tempStat = { new: 0, interested: 0, rescheduled: 0, operations: 0, linkSent: 0, policyGenerated: 0, closed: 0 }
            for (const [index1, phoneNumber] of numberList.entries()) {
                const status = await checkIfEntryExists(phoneNumber, productArray[subValue])
                switch (status) {
                    case "New": {
                        tempStat.new += 1;
                        duplicateTemp.push(leadList[index1])
                        break;
                    }
                    case "Interested": {
                        tempStat.interested += 1;
                        duplicateTemp.push(leadList[index1])
                        break;
                    }
                    case "Rescheduled": {
                        tempStat.rescheduled += 1;
                        duplicateTemp.push(leadList[index1])
                        break;
                    }
                    case "Operations": {
                        tempStat.operations += 1;
                        duplicateTemp.push(leadList[index1])
                        break;
                    }
                    case "Link Sent": {
                        tempStat.linkSent += 1;
                        duplicateTemp.push(leadList[index1])
                        break;
                    }
                    case "Policy Generated": {
                        tempStat.policyGenerated += 1;
                        duplicateTemp.push(leadList[index1])
                        break;
                    }
                    case "Closed": {
                        tempStat.closed += 1;
                        duplicateTemp.push(leadList[index1])
                        break;
                    }
                    case "Unique": {
                        uniqueTemp.push(leadList[index1]);
                        break
                    }
                    default: { }
                }
            }
            setUniqueList(uniqueTemp)
            setDuplicationStat({ ...tempStat })
            setDuplicateList(duplicateTemp)
            setStatDialog(true)
        }
    }

    const distributeData = async () => {
        setProgress(true)
        let promises = [];
        for (const index in uniqueList) {
            const promise = new Promise(async (resolve, reject) => {
                let item = uniqueList[index]
                item.status = "New"
                item.subStatus = "From Admin-Yet to Call"
                item.advisorPool = [...selectedAdvisors]
                if (subValue === 0) {
                    await set(ref(firebaseDatabase, `NEW_LEADS/${item.assignedAdvisorUid}/${item.phoneNumber}`), item)
                    await set(ref(firebaseDatabase, `LOAN_APPLICATIONS/${item.phoneNumber}`), item)
                } else if (subValue === 1) {
                    await set(ref(firebaseDatabase, `HI_NEW_LEADS/${item.assignedAdvisorUid}/${item.phoneNumber}`), item)
                    await set(ref(firebaseDatabase, `HEALTH_INSURANCE_APPLICATIONS/${item.phoneNumber}`), item)
                }

                //lifeline object
                let tempLifelineObj = {
                    status: "New",
                    lifeline: [{
                        actionBy: userData.uid,
                        status: "New",
                        subStatus: "From Admin-Yet to Call",
                        date: item.newFromAdminDate,
                        ref: "NEW_LEADS",
                        sentTo: item.assignedAdvisorUid
                    }]
                }

                await set(ref(firebaseDatabase, `ALL_LEADS_STATUS/${productArray[subValue]}/${item.phoneNumber}`), tempLifelineObj)
                if (value === 0) {
                    await remove(ref(firebaseDatabase, `POOL_INBOUND_APPLICATIONS/${productArray[subValue]}/${item.phoneNumber}`))
                } else if (value === 1) {
                    await remove(ref(firebaseDatabase, `POOL_OUTBOUND_APPLICATIONS/${productArray[subValue]}/${item.phoneNumber}`))
                } else if (value === 2) {
                    await remove(ref(firebaseDatabase, `POOL_WEBSITE_APPLICATIONS/${productArray[subValue]}/${item.phoneNumber}`))
                } else if (value === 3) {
                    await remove(ref(firebaseDatabase, `POOL_DIRECT_APPLICATIONS/${productArray[subValue]}/${item.phoneNumber}`))
                } else if (value === 4) {
                    await remove(ref(firebaseDatabase, `POOL_INTERNATIONAL_APPLICATIONS/${productArray[subValue]}/${item.phoneNumber}`))
                }
                resolve();
            })
            promises.push(promise);
        }

        await Promise.allSettled(promises)
        setProgress(false)
        setStatDialog(false)
        setUserListDialog(false)
        setSelectedAdvisors([])
        setFilteredAdvisors([])
        setSelectedProduct("")
        alert("Leads moved")
        handleSubChange(null, subValue)
    }

    const handleDownloadReport = () => {
        let data = []
        let date = moment().format("DD-MM-YYYY")

        setProgress(true)

        let downloadRef = null

        // if (subValue === 0) {
        //     downloadRef = ref(firebaseDatabase, `LOAN_APPLICATIONS`)
        // } else if (subValue === 1) {
        //     downloadRef = ref(firebaseDatabase, `HEALTH_INSURANCE_APPLICATIONS`)
        // }

        for (const [index, item] of dupliateList.entries()) {
            if (subValue === 0) {
                downloadRef = ref(firebaseDatabase, `LOAN_APPLICATIONS/${item.phoneNumber}`)
            } else if (subValue === 1) {
                downloadRef = ref(firebaseDatabase, `HEALTH_INSURANCE_APPLICATIONS/${item.phoneNumber}`)
            }
            onValue(downloadRef, (snap) => {
                if (snap.exists()) {
                    let tempObj = {
                        "Customer Name": dupliateList[index].name,
                        "Phone Number": dupliateList[index].phoneNumber,
                        "Email ID": dupliateList[index].emailId,
                        "Country of Study": dupliateList[index].desiredCountry,
                        "Desired University": dupliateList[index].desiredUniversity,
                        "Source": dupliateList[index].source,
                        "SourceDescription": dupliateList[index].sourceDescription,
                        "Status": snap.child("status").val(),
                        "Sub Status": snap.child("subStatus").val(),
                        "Current Advisor Name": snap.child("assignedAdvisorName").val(),
                        "Current Advisor Phone Number": snap.child("assignedAdvisorPhoneNumber").val()
                    }
                    data.push(tempObj)

                    if (dupliateList.length - 1 === index) {

                        const fileName = 'Duplicate Data Report: ' + date
                        const exportType = exportFromJSON.types.csv
                        exportFromJSON({ data, fileName, exportType })
                        setProgress(false)
                    }
                }
            }, { onlyOnce: true })
        }



        // onValue(downloadRef, (snap) => {
        //     if (snap.exists()) {
        //         for (const index in dupliateList) {
        //             let tempObj = {
        //                 "Customer Name": dupliateList[index].name,
        //                 "Phone Number": dupliateList[index].phoneNumber,
        //                 "Email ID": dupliateList[index].emailId,
        //                 "Country of Study": dupliateList[index].desiredCountry,
        //                 "Desired University": dupliateList[index].desiredUniversity,
        //                 "Source": dupliateList[index].source,
        //                 "SourceDescription": dupliateList[index].sourceDescription,
        //                 "Status": snap.child(dupliateList[index].phoneNumber).child("status").val(),
        //                 "Sub Status": snap.child(dupliateList[index].phoneNumber).child("subStatus").val(),
        //                 "Current Advisor Name": snap.child(dupliateList[index].phoneNumber).child("assignedAdvisorName").val(),
        //                 "Current Advisor Phone Number": snap.child(dupliateList[index].phoneNumber).child("assignedAdvisorPhoneNumber").val()
        //             }
        //             data.push(tempObj)
        //         }
        //         const fileName = value === 0 ? ('Duplicate Data Report: ' + date) : ('Outbound Invalid Data: ' + date)
        //         const exportType = exportFromJSON.types.csv
        //         exportFromJSON({ data, fileName, exportType })
        //         setProgress(false)
        //     }
        // }, { onlyOnce: true })
    }

    const downloadAndDelete = async () => {
        let data = []
        let date = moment().format("DD-MM-YYYY")

        for (const index in visibleList) {
            let tempObj = {
                "Customer Name": visibleList[index].name,
                "Phone Number": visibleList[index].phoneNumber,
                "Email ID": visibleList[index].emailId,
                "Country of Study": visibleList[index].desiredCountry,
                "Desired University": visibleList[index].desiredUniversity,
                "Product": visibleList[index].product,
                "Source": visibleList[index].source,
                "SourceDescription": visibleList[index].sourceDescription,
                "Upload Date": visibleList[index].date
            }
            data.push(tempObj)
        }
        const fileName = 'Pool Leads: ' + date
        const exportType = exportFromJSON.types.csv
        exportFromJSON({ data, fileName, exportType })
        setProgress(true)
        if (value === 0) {
            await remove(ref(firebaseDatabase, `POOL_INBOUND_APPLICATIONS/${productArray[subValue]}`))
        } else if (value === 1) {
            await remove(ref(firebaseDatabase, `POOL_OUTBOUND_APPLICATIONS/${productArray[subValue]}`))
        } else if (value === 2) {
            await remove(ref(firebaseDatabase, `POOL_WEBSITE_APPLICATIONS/${productArray[subValue]}`))
        } else if (value === 3) {
            await remove(ref(firebaseDatabase, `POOL_DIRECT_APPLICATIONS/${productArray[subValue]}`))
        } else if (value === 4) {
            await remove(ref(firebaseDatabase, `POOL_INTERNATIONAL_APPLICATIONS/${productArray[subValue]}`))
        }
        setVisibleList([])
        setProgress(false)
    }

    const handleSingleForward = async () => {

        let dbRef = ref(firebaseDatabase, `ALL_LEADS_STATUS/${productArray[subValue]}/${selectedSingleLead.phoneNumber}`)

        onValue(dbRef, async (snap) => {
            if (snap.exists()) {
                if (subValue === 1) {
                    let status = snap.child("status").val()
                    if (status === "Operations" || status === "Link Sent" || status === "Policy Generated") {
                        alert("Sorry! This number is already present in CRM under " + status + ".")
                    } else {
                        handlePreExisting()
                    }
                } else {
                    alert("Sorry. This number already exists in the CRM as " + snap.child("status").val() + ".")
                }
                return
            } else {
                let item = { ...selectedSingleLead }
                item.status = "New"
                item.subStatus = "From Admin-Yet to Call"
                item.advisorPool = [selectedSingleAdvisor]
                item.assignedAdvisorUid = selectedSingleAdvisor.uid
                item.assignedAdvisorName = selectedSingleAdvisor.name
                item.assignedAdvisorPhoneNumber = selectedSingleAdvisor.phoneNumber
                item.newFromAdminDate = moment().format("DD-MM-YYYY h:mm a")
                item.type = "INBOUND"

                setSubmitting(true)

                if (subValue === 0) {
                    await set(ref(firebaseDatabase, `NEW_LEADS/${item.assignedAdvisorUid}/${item.phoneNumber}`), item)
                    await set(ref(firebaseDatabase, `LOAN_APPLICATIONS/${item.phoneNumber}`), item)
                } else if (subValue === 1) {
                    await set(ref(firebaseDatabase, `HI_NEW_LEADS/${item.assignedAdvisorUid}/${item.phoneNumber}`), item)
                    await set(ref(firebaseDatabase, `HEALTH_INSURANCE_APPLICATIONS/${item.phoneNumber}`), item)
                }

                //lifeline object
                let tempLifelineObj = {
                    status: "New",
                    lifeline: [{
                        actionBy: userData.uid,
                        status: "New",
                        subStatus: "From Admin-Yet to Call",
                        date: item.newFromAdminDate,
                        ref: "NEW_LEADS",
                        sentTo: item.assignedAdvisorUid
                    }]
                }

                await set(ref(firebaseDatabase, `ALL_LEADS_STATUS/${productArray[subValue]}/${item.phoneNumber}`), tempLifelineObj)
                if (value === 2) {
                    await remove(ref(firebaseDatabase, `POOL_WEBSITE_APPLICATIONS/${productArray[subValue]}/${item.phoneNumber}`))
                } else if (value === 3) {
                    await remove(ref(firebaseDatabase, `POOL_DIRECT_APPLICATIONS/${productArray[subValue]}/${item.phoneNumber}`))
                }
                setSubmitting(false)
                setSingleLeadModal(false)
                handleSubChange(null, subValue)
            }
        }, { onlyOnce: true })


    }

    const handlePreExisting = () => {
        onValue(ref(firebaseDatabase, `HEALTH_INSURANCE_APPLICATIONS/${selectedSingleLead.phoneNumber}`), (snap) => {
            if (snap.exists()) {
                setPreExistingFile(snap.val())
                setAlreadyExistsModal(true)
            }
        }, {onlyOnce:true})
    }

    const handleReassign = () => {

        let pastRef = null
        switch (preExistingFile.status) {
            case "New": {
                pastRef = "HI_NEW_LEADS"
                break
            }
            case "Rescheduled": {
                pastRef = "HI_RESCHEDULED_LEADS"
                break
            }
            case "Interested": {
                pastRef = "HI_INTERESTED_LEADS"
                break
            }
            case "Closed": {
                pastRef = "HI_CLOSED_LEADS"
                break
            }
            default: {}
        }

        if (pastRef === "HI_INTERESTED_LEADS") {
            if (preExistingFile.assignedAdvisorUid === selectedSingleAdvisor.uid) {
                alert("The file is already in " + selectedSingleAdvisor.name + "'s Interested.")
                return
            } else {
                let newObject = {
                    ...preExistingFile,
                    assignedAdvisorUid: selectedSingleAdvisor.uid,
                    assignedAdvisorName: selectedSingleAdvisor.name,
                    assignedAdvisorPhoneNumber: selectedSingleAdvisor.phoneNumber,
                    reAssigningDate: moment().format("DD-MM-YYYY h:mm a"),
                    receivedFrom: {"position":userData.position, "name":userData.name, "phoneNumber": userData.phoneNumber, "uid": userData.uid}
                }
        
                let hasBeenWithArray = preExistingFile.pastAdvisors?preExistingFile.pastAdvisors:[]
                hasBeenWithArray.push(preExistingFile.assignedAdvisorUid)
                newObject.pastAdvisors = hasBeenWithArray
        
                setSubmitting(true)
        
                onValue(ref(firebaseDatabase, `ALL_LEADS_STATUS/Health Insurance/${newObject.phoneNumber}`), async (snap) => {
                    if (snap.exists()) {
                        let statusObject = snap.val()
        
                        let lifeLineArray = [...statusObject.lifeline]
                        let arrayItem = {
                            actionBy: userData.uid,
                            status: "Interested",
                            subStatus: "Reassigned by Admin - From Distribution",
                            date: moment().format("DD-MM-YYYY h:mm a"),
                            ref: "HI_INTERESTED_LEADS"
                        }
                        statusObject.status = "Interested"
                        lifeLineArray.push(arrayItem)
                        statusObject.lifeline = [...lifeLineArray]
        
                        if (newObject.phoneNumber && newObject.assignedAdvisorUid && userData.uid && preExistingFile.assignedAdvisorUid && preExistingFile.phoneNumber) {
                            await set(ref(firebaseDatabase, `ALL_LEADS_STATUS/Health Insurance/${newObject.phoneNumber}`), statusObject)
                            await set(ref(firebaseDatabase, `HEALTH_INSURANCE_APPLICATIONS/${newObject.phoneNumber}`), newObject)
                            await set(ref(firebaseDatabase, `HI_INTERESTED_LEADS/${newObject.assignedAdvisorUid}/${newObject.phoneNumber}`), newObject)
                            await remove(ref(firebaseDatabase, `HI_INTERESTED_LEADS/${preExistingFile.assignedAdvisorUid}/${preExistingFile.phoneNumber}`))
                            if (value === 2) {
                                await remove(ref(firebaseDatabase, `POOL_WEBSITE_APPLICATIONS/${productArray[subValue]}/${preExistingFile.phoneNumber}`))
                            } else if (value === 3) {
                                await remove(ref(firebaseDatabase, `POOL_DIRECT_APPLICATIONS/${productArray[subValue]}/${preExistingFile.phoneNumber}`))
                            }
                            setSubmitting(false)
                            setPreExistingFile({})
                            alert("Re-assigned successfully!")
                            setAlreadyExistsModal(false)
                            setSingleLeadModal(false)
                            handleSubChange(null, subValue)
                        } else {
                            alert("Something went wrong.")
                        }
                        
                    }
                }, {onlyOnce:true})
            }
        } else {
            let newObject = {
                ...preExistingFile,
                assignedAdvisorUid: selectedSingleAdvisor.uid,
                assignedAdvisorName: selectedSingleAdvisor.name,
                assignedAdvisorPhoneNumber: selectedSingleAdvisor.phoneNumber,
                reAssigningDate: moment().format("DD-MM-YYYY h:mm a"),
                newFromAdminDate: moment().format("DD-MM-YYYY h:mm a"),
                status: "New",
                subStatus: "From Admin-Yet to Call",
                product: "Health Insurance",
                firstCall: null,
                lastCallDate: "",
                receivedFrom: {"position":userData.position, "name":userData.name, "phoneNumber": userData.phoneNumber, "uid": userData.uid}
            }
    
            let hasBeenWithArray = preExistingFile.pastAdvisors?preExistingFile.pastAdvisors:[]
            hasBeenWithArray.push(preExistingFile.assignedAdvisorUid)
            newObject.pastAdvisors = hasBeenWithArray
    
            setSubmitting(true)
    
            onValue(ref(firebaseDatabase, `ALL_LEADS_STATUS/Health Insurance/${newObject.phoneNumber}`), async (snap) => {
                if (snap.exists()) {
                    let statusObject = snap.val()
    
                    let lifeLineArray = [...statusObject.lifeline]
                    let arrayItem = {
                        actionBy: userData.uid,
                        status: "New",
                        subStatus: "Reassigned by Admin - From Distribution",
                        date: moment().format("DD-MM-YYYY h:mm a"),
                        ref: "HI_NEW_LEADS"
                    }
                    statusObject.status = "New"
                    lifeLineArray.push(arrayItem)
                    statusObject.lifeline = [...lifeLineArray]
    
                    await set(ref(firebaseDatabase, `ALL_LEADS_STATUS/Health Insurance/${newObject.phoneNumber}`), statusObject)
                    await set(ref(firebaseDatabase, `HEALTH_INSURANCE_APPLICATIONS/${newObject.phoneNumber}`), newObject)
                    await set(ref(firebaseDatabase, `HI_NEW_LEADS/${newObject.assignedAdvisorUid}/${newObject.phoneNumber}`), newObject)
                    if (value === 2) {
                        await remove(ref(firebaseDatabase, `POOL_WEBSITE_APPLICATIONS/${productArray[subValue]}/${preExistingFile.phoneNumber}`))
                    } else if (value === 3) {
                        await remove(ref(firebaseDatabase, `POOL_DIRECT_APPLICATIONS/${productArray[subValue]}/${preExistingFile.phoneNumber}`))
                    }
                    if ((preExistingFile.assignedAdvisorUid !== newObject.assignedAdvisorUid || pastRef!=="HI_NEW_LEADS")) {
                        await remove(ref(firebaseDatabase, `${pastRef}/${preExistingFile.assignedAdvisorUid}/${preExistingFile.phoneNumber}`))
                    }
                    setSubmitting(false)
                    setPreExistingFile({})
                    alert("Re-assigned successfully!")
                    setAlreadyExistsModal(false)
                    setSingleLeadModal(false)
                    handleSubChange(null, subValue)
                }
            }, {onlyOnce:true})
        }
    }

    return (
        <div className={Styles.distributeLeadsContainer}>
            <div className={Styles.tabHederContainer}>
                <h1 style={{ color: "#352F6C" }}>Lead Pool</h1>
                {
                    (userData.rights.includes("UploadLeads") && value !== 2 && value !== 3)
                    &&
                    <div className={Styles.uploadViewContainer}>
                        <Button onClick={() => { setProductDialog(true) }} startIcon={<IoAddCircle />} style={{ backgroundColor: "#8069B4" }} size='medium' variant='contained'>Upload Leads</Button>
                        <p className={Styles.viewTemplateButton} onClick={() => { window.open("https://firebasestorage.googleapis.com/v0/b/student-cover-crm.appspot.com/o/SC-CRM%20Lead%20Upload%20Template.xlsx?alt=media&token=c19f833f-b4aa-44b2-9623-cfd1948c2b0f", "_blank") }}>Download Template</p>
                    </div>
                }
            </div>

            <div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Inbound Leads" {...a11yProps(0)} />
                        <Tab label="Outbound Leads" {...a11yProps(1)} />
                        <Tab label="Website Leads" {...a11yProps(2)} />
                        <Tab label="Direct Leads" {...a11yProps(3)} />
                        <Tab label="International Numbers" {...a11yProps(4)} />
                    </Tabs>
                </div>

                <Divider />

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 30 }}>
                    <Tabs value={subValue} onChange={handleSubChange} aria-label="basic tabs example">
                        <Tab label="Education Loan" {...subA11yProps(0)} />
                        <Tab label="Health Insurance" {...subA11yProps(1)} />
                        <Tab label="Travel Insurance" {...subA11yProps(2)} />
                        <Tab label="Dental Insurance" {...subA11yProps(3)} />
                        <Tab label="Admission Counselling" {...subA11yProps(4)} />
                    </Tabs>
                </div>

                <Divider />

                <Stack direction={'row'} alignItems='center' justifyContent='space-between' className={Styles.filterContainer}>
                    <h2 style={{ color: "#352F6C" }}>{visibleList.length} {value === 0 ? "Inbound" : "Outbound"} Leads</h2>

                    <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
                        <Button onClick={downloadAndDelete} style={{ backgroundColor: "red" }} size='medium' variant='contained'>Clear Pool</Button>
                        {
                            (value !== 2 && value !== 3)
                            &&
                            <Button onClick={filterAdvisors} style={{ backgroundColor: "#8069B4" }} size='medium' variant='contained'>Forward Leads</Button>
                        }
                    </div>
                </Stack>

                {
                    (value !== 2 && value !== 3)
                        ?
                        <Box>
                            <TableContainer>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ fontWeight: 700, color: "#352F6C" }}>#</TableCell>
                                            <TableCell style={{ fontWeight: 700, color: "#352F6C" }}>Name</TableCell>
                                            <TableCell style={{ fontWeight: 700, color: "#352F6C" }}>Phone Number</TableCell>
                                            <TableCell style={{ fontWeight: 700, color: "#352F6C" }}>Email ID</TableCell>
                                            <TableCell style={{ fontWeight: 700, color: "#352F6C" }}>Upload Date</TableCell>
                                            <TableCell style={{ fontWeight: 700, color: "#352F6C" }}>Country</TableCell>
                                            <TableCell style={{ fontWeight: 700, color: "#352F6C" }}>University</TableCell>
                                            <TableCell style={{ fontWeight: 700, color: "#352F6C" }}>Source</TableCell>
                                            <TableCell style={{ fontWeight: 700, color: "#352F6C" }}>Source Description</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody style={{ backgroundColor: "white" }}>
                                        {
                                            visibleList.map((item, index) => {
                                                return (
                                                    <TableRow key={index.toString()}>
                                                        <TableCell>{(index + 1).toString()}</TableCell>
                                                        <TableCell>{item.name}</TableCell>
                                                        <TableCell>{item.phoneNumber}</TableCell>
                                                        <TableCell>{item.emailId}</TableCell>
                                                        <TableCell>{item.date}</TableCell>
                                                        <TableCell>{item.desiredCountry}</TableCell>
                                                        <TableCell>{item.desiredUniversity}</TableCell>
                                                        <TableCell>{item.source}</TableCell>
                                                        <TableCell>{item.sourceDescription}</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                        :
                        <Box>
                            <TableContainer>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ fontWeight: 700, color: "#352F6C" }}>#</TableCell>
                                            <TableCell style={{ fontWeight: 700, color: "#352F6C" }}>Name</TableCell>
                                            <TableCell style={{ fontWeight: 700, color: "#352F6C" }}>Phone Number</TableCell>
                                            <TableCell style={{ fontWeight: 700, color: "#352F6C" }}>Email ID</TableCell>
                                            <TableCell style={{ fontWeight: 700, color: "#352F6C" }}>Upload Date</TableCell>
                                            <TableCell style={{ fontWeight: 700, color: "#352F6C" }}>Country</TableCell>
                                            <TableCell style={{ fontWeight: 700, color: "#352F6C" }}>University</TableCell>
                                            <TableCell style={{ fontWeight: 700, color: "#352F6C" }}>Source</TableCell>
                                            <TableCell style={{ fontWeight: 700, color: "#352F6C" }}>Source Description</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody style={{ backgroundColor: "white" }}>
                                        {
                                            visibleList.map((item, index) => {
                                                return (
                                                    <TableRow key={index.toString()}>
                                                        <TableCell>{(index + 1).toString()}</TableCell>
                                                        <TableCell>{item.name}</TableCell>
                                                        <TableCell>{item.phoneNumber}</TableCell>
                                                        <TableCell>{item.emailId}</TableCell>
                                                        <TableCell>{item.date}</TableCell>
                                                        <TableCell>{item.desiredCountry}</TableCell>
                                                        <TableCell>{item.desiredUniversity}</TableCell>
                                                        <TableCell>{item.source}</TableCell>
                                                        <TableCell>{item.sourceDescription}</TableCell>
                                                        <TableCell><Button variant="contained" onClick={() => { filterAdvisors(); setSelectedSingleLead(item) }}>Assign</Button></TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                }
            </div>

            {/* Stat Dialog */}

            <Modal open={statDialog} onClose={() => { setStatDialog(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className={Styles.reportContainer}>
                        <p className={Styles.modalTitle}>Lead Report</p>
                        <div className={Styles.modalGridContainer}>
                            <p className={Styles.modalGridItem}>Total Leads</p>
                            <p className={Styles.modalGridItem}>{visibleList.length}</p>

                            <p className={Styles.modalGridItem}>Unique Leads</p>
                            <p className={Styles.modalGridItem}>{uniqueList.length}</p>
                        </div>

                        <p className={Styles.modalTitle} style={{ marginTop: 20, fontWeight: 500 }}>Current Lead Status</p>
                        <div className={Styles.modalGridContainer}>
                            <p className={Styles.modalGridItem} style={{ fontWeight: 500 }}>Duplicate Leads</p>
                            <p className={Styles.modalGridItem} style={{ fontWeight: 500 }}>{dupliateList.length}</p>

                            {
                                subValue === 0
                                &&
                                <>
                                    <p className={Styles.modalGridItem}>New</p>
                                    <p className={Styles.modalGridItem}>{duplicationStat.new}</p>
                                    <p className={Styles.modalGridItem}>Interested</p>
                                    <p className={Styles.modalGridItem}>{duplicationStat.interested}</p>
                                    <p className={Styles.modalGridItem}>Case Details</p>
                                    <p className={Styles.modalGridItem}>{duplicationStat.caseDetails}</p>
                                    <p className={Styles.modalGridItem}>Operations</p>
                                    <p className={Styles.modalGridItem}>{duplicationStat.operations}</p>
                                    <p className={Styles.modalGridItem}>Vendor Status</p>
                                    <p className={Styles.modalGridItem}>{duplicationStat.vendor}</p>
                                    <p className={Styles.modalGridItem}>PF Paid</p>
                                    <p className={Styles.modalGridItem}>{duplicationStat.pfPaid}</p>
                                    <p className={Styles.modalGridItem}>SL Received</p>
                                    <p className={Styles.modalGridItem}>{duplicationStat.slReceived}</p>
                                    <p className={Styles.modalGridItem}>Disbursed</p>
                                    <p className={Styles.modalGridItem}>{duplicationStat.disbursement}</p>
                                    <p className={Styles.modalGridItem}>Closed</p>
                                    <p className={Styles.modalGridItem}>{duplicationStat.closed}</p>
                                </>
                            }

                            {
                                subValue === 1
                                &&
                                <>
                                    <p className={Styles.modalGridItem}>New</p>
                                    <p className={Styles.modalGridItem}>{duplicationStat.new}</p>
                                    <p className={Styles.modalGridItem}>Interested</p>
                                    <p className={Styles.modalGridItem}>{duplicationStat.interested}</p>
                                    <p className={Styles.modalGridItem}>Rescheduled</p>
                                    <p className={Styles.modalGridItem}>{duplicationStat.rescheduled}</p>
                                    <p className={Styles.modalGridItem}>Operations</p>
                                    <p className={Styles.modalGridItem}>{duplicationStat.operations}</p>
                                    <p className={Styles.modalGridItem}>Link Sent</p>
                                    <p className={Styles.modalGridItem}>{duplicationStat.linkSent}</p>
                                    <p className={Styles.modalGridItem}>Policy Generated</p>
                                    <p className={Styles.modalGridItem}>{duplicationStat.policyGenerated}</p>
                                    <p className={Styles.modalGridItem}>Closed</p>
                                    <p className={Styles.modalGridItem}>{duplicationStat.closed}</p>
                                </>
                            }
                        </div>

                        <p className={Styles.downloadReportButton} onClick={() => { handleDownloadReport() }}>Download Report</p>

                        <div className={Styles.buttonContainer}>
                            <Button onClick={() => { setStatDialog(false) }} variant='contained' className={Styles.secondaryButton}>Cancel</Button>
                            <Button onClick={() => { distributeData() }} variant='contained' className={Styles.primaryButton}>Forward</Button>
                        </div>
                    </div>
                </Box>
            </Modal>

            {/* <Dialog aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">
                    {"Duplication Analysis Result"}
                </DialogTitle>
                <DialogContent>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: 30, width: "100%", justifyContent: 'space-between' }}>
                            <p>{uniqueList.length} Unique Leads found.</p>
                            <p style={{ fontWeight: 600, color: "green", cursor: 'pointer' }} onClick={() => { distributeData() }}>Distribute</p>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: 30, width: "100%", justifyContent: 'space-between' }}>
                            <p>{newList.length} Leads are currently in New state.</p>
                            <p style={{ fontWeight: 600, color: "white", cursor: 'pointer' }}>Distribute</p>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: 30, width: "100%", justifyContent: 'space-between' }}>
                            <p>{activeList.length} Leads are currently in Active state.</p>
                            <p style={{ fontWeight: 600, color: "white", cursor: 'pointer' }}>Distribute</p>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: 30, width: "100%", justifyContent: 'space-between' }}>
                            <p>{reprocessedList.length} Leads are currently in Reprocess state.</p>
                            <p style={{ fontWeight: 600, color: "white", cursor: 'pointer' }}>Distribute</p>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: 30, width: "100%", justifyContent: 'space-between' }}>
                            <p>{completedList.length} Leads are currently in Completed state.</p>
                            <p style={{ fontWeight: 600, color: "white", cursor: 'pointer' }}>Distribute</p>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: 30, width: "100%", justifyContent: 'space-between' }}>
                            <p>{closedList.length} Leads are currently in Rescheduled state.</p>
                            <p style={{ fontWeight: 600, color: "white", cursor: 'pointer' }}>Distribute</p>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setStatDialog(false) }}>Close</Button>
                </DialogActions>
            </Dialog> */}

            {/* Advisor Dialog */}

            <Modal open={userListDialog} onClose={() => { setUserListDialog(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className={Styles.modalContainer}>
                        <p className={Styles.modalTitle}>Select the advisors to distribute the leads.</p>
                        <div className={Styles.productListContainer}>
                            {
                                !shareWithAll
                                &&
                                filteredAdvisors.map((item, index) => {
                                    return (
                                        <div key={item.uid} className={Styles.itemContainer}>
                                            <p className={Styles.productName}>{item.name}</p>
                                            <Switch checked={_.includes(selectedAdvisors, item)} onChange={(event) => { handleCheckbox(item, event.target.checked) }} />
                                        </div>
                                    )
                                })
                            }
                        </div>

                        <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: 'center', marginTop: 20 }}>
                            <p>All Advisors</p>
                            <Switch value={shareWithAll} onChange={(event) => { setShareWithAll(event.target.checked); if (event.target.checked) { setSelectedAdvisors([...filteredAdvisors]) } else { setSelectedAdvisors([]) } }} checked={shareWithAll} />
                        </div>

                        <div className={Styles.buttonContainer}>
                            <Button sx={{ backgroundColor: "rgba(53, 47, 108, 0.5)" }} onClick={() => { setUserListDialog(false) }} variant='contained' className={Styles.secondaryButton}>Cancel</Button>
                            <DistributeButton handleDistribute={handleDistribute} ref={handleDistributeButtonRef} />
                            {/* <Button onClick={() => { handleDistribute() }} variant='contained' className={Styles.primaryButton}>Proceed</Button> */}
                        </div>
                    </div>
                </Box>
            </Modal>

            {/* Product Dialog */}

            <Modal open={productDialog} onClose={() => { setProductDialog(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className={Styles.modalContainer}>
                        <p className={Styles.modalTitle}>Please Select the department for which you want to upload the leads.</p>
                        <div className={Styles.productListContainer}>
                            {
                                ["Education Loan", "Health Insurance", "Travel Insurance", "Dental Insurance", "Admission Counselling"].map((item, index) => {
                                    return (
                                        <div key={JSON.stringify(item)} className={Styles.itemContainer}>
                                            <p className={Styles.productName}>{item}</p>
                                            <Switch value={selectedProduct === item} checked={selectedProduct === item} onChange={(event) => { if (event.target.checked) { setSelectedProduct(item) } }} />
                                        </div>
                                    )
                                })
                            }
                        </div>

                        <div className={Styles.buttonContainer}>
                            <Button sx={{ backgroundColor: "rgba(53, 47, 108, 0.5)" }} onClick={() => { setProductDialog(false) }} variant='contained' className={Styles.secondaryButton}>Cancel</Button>
                            <Button onClick={() => { handleUploadLeads() }} variant='contained' className={Styles.primaryButton}>Upload</Button>
                        </div>
                    </div>
                </Box>
            </Modal>

            {/* Uploading Data Dialog */}

            <Modal open={progress} onClose={() => { setProgress(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className={Styles.modalContainer}>
                        <p className={Styles.modalTitle} style={{ fontWeight: 400, marginBottom: 10 }}>Uploading Data</p>
                        <p className={Styles.modalTitle}>Please wait...</p>
                    </div>
                </Box>
            </Modal>

            <Modal open={singleLeadModal} onClose={() => { setSingleLeadModal(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className={Styles.modalContainer}>
                        <p className={Styles.modalTitle}>Select the advisor to forward the file to.</p>
                        <Select style={{ width: "100%" }} value={selectedSingleAdvisor} onChange={(event) => { setSelectedSingleAdvisor(event.target.value) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                            {
                                filteredAdvisors.map((item) => {
                                    return (
                                        <MenuItem value={item}>{item.name}</MenuItem>
                                    )
                                })
                            }
                        </Select>

                        <div className={Styles.buttonContainer}>
                            <Button disabled={submitting} sx={{ backgroundColor: submitting ? "#ccc" : "rgba(53, 47, 108, 0.5)" }} onClick={() => { if (!submitting) { setSingleLeadModal(false) } }} variant='contained' className={Styles.secondaryButton}>Cancel</Button>
                            <Button disabled={submitting} onClick={() => { if (!submitting) { handleSingleForward() } }} variant='contained' style={{ backgroundColor: submitting ? "#ccc" : null }} className={Styles.primaryButton}>Assign</Button>
                        </div>
                    </div>
                </Box>
            </Modal>

            <Modal open={alreadyExistsModal} onClose={() => { setAlreadyExistsModal(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className={Styles.modalContainer}>
                        <p className={Styles.modalTitle}>The file is currently present with {preExistingFile.assignedAdvisorName}, under {preExistingFile.status}.</p>
                        <p className={Styles.modalTitle}>Select the advisor you want to move this file to.</p>
                        <Select style={{ width: "100%" }} value={selectedSingleAdvisor} onChange={(event) => { setSelectedSingleAdvisor(event.target.value) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                            {
                                filteredAdvisors.map((item) => {
                                    return (
                                        <MenuItem value={item}>{item.name}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                        {
                            (preExistingFile.status === "New" || preExistingFile.status === "Rescheduled" || preExistingFile.status === "Closed")
                            ?
                            <p className={Styles.modalTitle}>The file will move to "New"</p>
                            :
                            <p className={Styles.modalTitle}>The file will move to "Interested"</p>
                        }
                        

                        <div className={Styles.buttonContainer}>
                            <Button disabled={submitting} sx={{ backgroundColor: submitting ? "#ccc" : "rgba(53, 47, 108, 0.5)" }} onClick={() => { if (!submitting) { setAlreadyExistsModal(false) } }} variant='contained' className={Styles.secondaryButton}>Cancel</Button>
                            <Button disabled={submitting} onClick={() => { if (!submitting) { handleReassign()} }} variant='contained' style={{ backgroundColor: submitting ? "#ccc" : null }} className={Styles.primaryButton}>Assign</Button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}



const DistributeButton = forwardRef(({ handleDistribute, updateState }, ref) => {
    const [loading, setLoading] = useState({ status: false, value: "" });

    const handleLoading = (status, totalLength, currentIndex) => {
        setLoading({
            status: status,
            value: `${currentIndex}/${totalLength}`
        })
    }

    useImperativeHandle(ref, () => {
        return {
            handleLoading
        }
    })



    return (
        <>
            <Button onClick={handleDistribute} disabled={loading.status} variant='contained' className={Styles.primaryButton}>
                {
                    loading.status
                        ?
                        loading.value
                        :
                        "Proceed"
                }

            </Button>
        </>
    )
});

export default DistributeLeads;





