import React, { useContext, useEffect, useState } from "react";
import Styles from "./OperationWithOperationLeads.module.css"
import UserContext from "../../../context/UserData.context";
import { useNavigate } from "react-router-dom";
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import moment from "moment";

const OperationWithOperationLeads = ({ activeList, type }) => {

    const navigate = useNavigate()

    const handleNavigate = (item) => {
        if (type === "Normal") {
            navigate ("/operations-leads/operation-complete-detail", {state:{item:item}})
        } else if (type === "Incognito") {
            navigate ("/operations-incognito-leads/operation-complete-detail-incognito", {state:{item:item}})
        }
    }

    return (
        <div>
            <div style={{width:"100%", marginTop:40}}>
                <Box>
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>#</TableCell>
                                    <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Customer ID</TableCell>
                                    <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Name</TableCell>
                                    <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Ph Number</TableCell>
                                    <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Email ID</TableCell>
                                    <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Date Received</TableCell>
                                    <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>PAN Number</TableCell>
                                    {
                                        type === "Incognito"
                                        &&
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Type</TableCell>
                                    }
                                    <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Advisor Name</TableCell>
                                    <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Status</TableCell>
                                    <TableCell style={{backgroundColor:"rgba(1, 1, 1, 0)"}}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody style={{backgroundColor:"white"}}>
                                {
                                    activeList.map((item, index) => {
                                        return (
                                            <TableRow key={index.toString()} >
                                                <TableCell style={{color:item.outstandingDate?"#ccc":"#444"}}>{(index + 1).toString()}</TableCell>
                                                <TableCell style={{color:item.outstandingDate?"#ccc":"#444"}}>{item.customerId}</TableCell>
                                                <TableCell style={{color:item.outstandingDate?"#ccc":"#444"}}>{item.name}</TableCell>
                                                <TableCell style={{color:item.outstandingDate?"#ccc":"#444"}}>xxxxxxxxx{item.phoneNumber.substring(9, 12)}</TableCell>
                                                <TableCell style={{color:item.outstandingDate?"#ccc":"#444"}}>{item.emailId}</TableCell>
                                                <TableCell style={{color:item.outstandingDate?"#ccc":"#444"}}>{item.withOperationsDate}</TableCell>
                                                <TableCell style={{color:item.outstandingDate?"#ccc":"#444"}}>{item.caseDetail.panNumber}</TableCell>
                                                {
                                                    type === "Incognito"
                                                    &&
                                                    <TableCell style={{color:item.outstandingDate?"#ccc":"#444"}}>{item.incognitoType}</TableCell>
                                                }
                                                <TableCell style={{color:item.outstandingDate?"#ccc":"#444"}}>{item.assignedAdvisorName}</TableCell>
                                                <TableCell style={{color:item.outstandingDate?"#ccc":"#444"}}>{item.subStatus==="New"?"Pending":item.subStatus}</TableCell>
                                                <TableCell><Button onClick={()=>{handleNavigate(item)}} style={{backgroundColor:"#352F6C", height:30}} size='medium' variant='contained'>Check</Button></TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </div>
        </div>
    )
}

export default OperationWithOperationLeads