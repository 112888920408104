import { onValue, ref } from "firebase/database"
import { firebaseDatabase } from "../backend/firebaseHandler";


export const checkIfEntryExists = async (phoneNumber, product) => {
    
    const existsPromise = new Promise((resolve, reject) => {
        const allLeadsRef = ref(firebaseDatabase, `ALL_LEADS_STATUS/${product}/${phoneNumber}/status`);
        onValue(allLeadsRef, (dataSnapshot) => {
            if (dataSnapshot.exists()) {
                const currentStatus = dataSnapshot.val();
                resolve(currentStatus)
            } else {
                resolve("Unique");
            }
        }, { onlyOnce: true })
    })

    return await existsPromise.then();
}