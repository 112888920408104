import React, { useContext, useEffect, useState } from "react";
import Styles from "./HiManagerInterestedLeads.module.css"
import moment from "moment";
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import HiInterestedCollapseRow from "../HiInterestedCollapseRow/HiInterestedCollapseRow.component";
import exportFromJSON from "export-from-json";
import { HiDocumentDownload } from "react-icons/hi";
import UserContext from "../../context/UserData.context";

const HiManagerInterestedLeads = ({activeList}) => {

    const [visibleList, setVisibleList] = useState([])
    const [value, setValue] = React.useState(0);
    const [userData] = useContext(UserContext)

    useEffect (() => {
        let temp = []
        for (const index in activeList) {
            let item = activeList[index]
            let travelDate = moment(item.travelDate, "DD-MM-YYYY").format("YYYY-MM-DD")
            let lastCallDate = moment(item.lastCallDate.split(" ")[0], "DD-MM-YYYY").format("YYYY-MM-DD")
            item.travelAge = moment(travelDate).diff(moment(), 'days')
            item.callAge = moment().diff(lastCallDate, 'days')
            temp.push(item)
        }
        setVisibleList(temp)
    }, [])

    

    const handleExport = () => {
        let data = []
        let date = moment().format("DD-MM-YYYY")
        for (const [index, item] of activeList.entries()) {
            let tempObj = {
                "Customer Name": item.name || "",
                "Phone Number": item.phoneNumber || "",
                "Email ID": item.emailId || "",
                "Country": item.desiredCountry || "",
                "University": item.desiredUniversity || "",
                "Travel Date": item.travelDate || "",
                "SC Advisor": item.assignedAdvisorName || "",
                "Upload Date": item.date || "",
                "Assignment Date": item.newFromAdminDate || "",
                "Interested Date": item.interestedDate || "",
                "Status": item.status || "",
                "Sub-status": item.subStatus || "",
                "Source": item.source || "",
                "Source Description": item.sourceDescription || ""
            }
            data.push(tempObj)
        }
        
        const fileName = 'All HI Interested Leads: ' + date
        const exportType =  exportFromJSON.types.csv
        exportFromJSON({ data, fileName, exportType })
    }

    return (
        <div style={{display:"flex", flexDirection:"column"}}>
            <Button onClick={() => { handleExport() }} startIcon={<HiDocumentDownload />} style={{ backgroundColor: "#8069B4", alignSelf:"flex-end" }} size='medium' variant='contained'>Export New Leads</Button>

            <Box>
                <TableContainer>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>#</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Name</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Ph Number</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Email ID</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>University</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Days before travel</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Last call age</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Next Call</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Advisor</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}></TableCell>
                                <TableCell style={{backgroundColor:"rgba(1, 1, 1, 0)"}}></TableCell>
                                <TableCell style={{backgroundColor:"rgba(1, 1, 1, 0)"}}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody style={{backgroundColor:"white"}}>
                            {
                                visibleList.map((item, index) => {
                                    return (
                                        <HiInterestedCollapseRow userType={userData.position} item={item} index={index} />
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </div>
    )
}

export default HiManagerInterestedLeads