import React, { useContext, useEffect, useState } from "react";
import Styles from "./RegionalManagerLeadStatus.module.css"
import { CircularProgress, MenuItem, Select, Tab, Tabs } from "@mui/material";
import { onValue, ref } from "firebase/database";
import { firebaseDatabase } from "../../../backend/firebaseHandler";
import UserContext from "../../../context/UserData.context";
import moment from "moment";
import NbfcManagerNewLeads from "../../NbfManagerLeadFlow/NbfcManagerNewLeads/NbfcManagerNewLeads.component";
import NbfcManagerFreshLeads from "../../NbfManagerLeadFlow/NbfcManagerFreshLeads/NbfcManagerFreshLeads.component";
import NbfcManagerConfirmLeads from "../../NbfManagerLeadFlow/NbfcManagerConfirmLeads/NbfcManagerConfirmLeads.component";
import NbfcManagerPfPaidLeads from "../../NbfManagerLeadFlow/NbfcManagerPfPaidLeads/NbfcManagerPfPaidLeads.component";
import NbfcManagerSlSentLeads from "../../NbfManagerLeadFlow/NbfcManagerSlSentLeads/NbfcManagerSlSentLeads.component";
import NbfcManagerDisbursedLeads from "../../NbfManagerLeadFlow/NbfcManagerDisbursedLeads/NbfcManagerDisbursedLeads.component";
import NbfcManagerReLoginLeads from "../../NbfManagerLeadFlow/NbfcManagerReLoginLeads/NbfcManagerReLoginLeads.component";
import NbfcManagerRejectedLeads from "../../NbfManagerLeadFlow/NbfcManagerRejectedLeads/NbfcManagerRejectedLeads.component";
import NbfcManagerClosedLeads from "../../NbfManagerLeadFlow/NbfcManagerClosedLeads/NbfcManagerClosedLeads.component";

const RegionalManagerLeadStatus = () => {

    
    const [selectedManager, setSelectedManager] = useState({})
    const [leadList, setLeadList] = useState([])
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState(0)
    const [userData, setUserData] = useContext(UserContext)
    const managers = [...userData.managerList]

    useEffect(() => {
        if (selectedManager) {
            fetchNbfcData(selectedManager)
        }
    }, [value])

    const handleSelectManager = event => {
        const value = JSON.parse(event.target.value);
        setSelectedManager(value);
        fetchNbfcData(value);
    }

    const fetchNbfcData = (manager) => {

        if (manager.uid) {
            let temp = []
            let dbRef = null;
    
            console.log(manager.uid)
            setLoading(true)
            switch (value) {
                case 0: {
                    dbRef = ref(firebaseDatabase, `VENDOR_LEADS/${userData.nbfcName}`)
                    break;
                }
                case 1: {
                    dbRef = ref(firebaseDatabase, `VENDOR_FRESH_LEADS/${userData.nbfcName}`)
                    break;
                }
                case 2: {
                    dbRef = ref(firebaseDatabase, `VENDOR_APPROVED_LEADS/${userData.nbfcName}`)
                    break;
                }
                case 3: {
                    dbRef = ref(firebaseDatabase, `VENDOR_PF_PAID_LEADS/${userData.nbfcName}`)
                    break;
                }
                case 4: {
                    dbRef = ref(firebaseDatabase, `VENDOR_SL_SENT_LEADS/${userData.nbfcName}`)
                    break;
                }
                case 5: {
                    dbRef = ref(firebaseDatabase, `VENDOR_DISBURSED_LEADS/${userData.nbfcName}`)
                    break;
                }
                case 6: {
                    dbRef = ref(firebaseDatabase, `VENDOR_RE_LOGIN_LEADS/${userData.nbfcName}`)
                    break;
                }
                case 7: {
                    dbRef = ref(firebaseDatabase, `VENDOR_REJECTED_LEADS/${userData.nbfcName}`)
                    break;
                }
                case 8: {
                    dbRef = ref(firebaseDatabase, `VENDOR_CLOSED_LEADS/${userData.nbfcName}`)
                    break;
                }
                default: {
                    dbRef = ref(firebaseDatabase, `VENDOR_LEADS/${userData.nbfcName}`)
                }
            }
    
            let temp2 = []
            onValue(dbRef, (snapShot) => {
                if (snapShot.exists()) {
                    for (const key in snapShot.val()) {
                        let item = snapShot.child(key).val()
    
                        if (item.assignedNbfcManagerUid === manager.uid) {
                            temp.push(item)
                        }
    
                        temp2 = temp.sort((a, b) => {
                            const date_1 = moment(a.sentToVendorDate,"DD-MM-YYYY h:mm a")
                            const date_2 = moment(b.sentToVendorDate,"DD-MM-YYYY h:mm a")
                            return moment(date_2).diff(date_1)
                        })
                    }
                }
                setLeadList(temp2)
                setLoading(false)
            }, {onlyOnce:true}) 
        }
    }

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <div>
            <h1 style={{color:"#352F6C"}}>Regional Manager Leads</h1>

            <div className={Styles.inputButtonContainer}>
                <div>
                    <p style={{ marginTop: 20 }}>Select NBFC</p>
                    <Select value={selectedManager.name} onChange={handleSelectManager} style={{ backgroundColor: "white", borderRadius: 5, width: 250, height: 40, border: "none", outline: "none" }}>
                        {
                            managers.map((item, index) => {
                                return (
                                    <MenuItem value={JSON.stringify(item)}>{item.name}</MenuItem>
                                )
                            })
                        }
                    </Select>
                </div>
            </div>

            <div style={{marginTop:20}}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" >
                    <Tab label="New" {...a11yProps(0)} />
                    <Tab label="Fresh" {...a11yProps(1)} />
                    <Tab label="Confirm PF & ROI" {...a11yProps(2)} />
                    <Tab label="PF Paid" {...a11yProps(3)} />
                    <Tab label="SL Sent" {...a11yProps(4)} />
                    <Tab label="Disbursed" {...a11yProps(5)} />
                    <Tab label="Re-login" {...a11yProps(6)} />
                    <Tab label="Rejected" {...a11yProps(7)} />
                    <Tab label="Closed" {...a11yProps(8)} />
                </Tabs>
            </div>

            <div className={Styles.progressComponentContainer}>
                {
                    loading
                    ?
                    <CircularProgress className={Styles.progress} />
                    :
                    <div>
                        {
                            value === 0 && <NbfcManagerNewLeads activeList={leadList} />
                        }

                        {
                            value === 1 && <NbfcManagerFreshLeads activeList={leadList} />
                        }

                        {
                            value === 2 && <NbfcManagerConfirmLeads activeList={leadList} />
                        }

                        {
                            value === 3 && <NbfcManagerPfPaidLeads activeList={leadList} />
                        }

                        {
                            value === 4 && <NbfcManagerSlSentLeads activeList={leadList} />
                        }

                        {
                            value === 5 && <NbfcManagerDisbursedLeads activeList={leadList} />
                        }

                        {
                            value === 6 && <NbfcManagerReLoginLeads activeList={leadList} />
                        } 

                        {
                            value === 7 && <NbfcManagerRejectedLeads activeList={leadList} />
                        } 

                        {
                            value === 8 && <NbfcManagerClosedLeads activeList={leadList} />
                        } 
                    </div>
                }
            </div>
        </div>
    )
}

export default RegionalManagerLeadStatus