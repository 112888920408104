const getHiDocumentMap = () => {
    const hiDocumentMap = {
        "StudentInIndiaICICI": [
            {
                name: "Passpport",
                url: "",
                label: "StudentInIndia_Passpport"
            },
            {
                name: "i20",
                url: "",
                label: "StudentWorking_i20"
            }
        ],
        "StudentInIndiaBajaj": [
            {
                name: "Passpport",
                url: "",
                label: "StudentInIndia_Passpport"
            }
        ],
        "StudentNotInIndia": [
            {
                name: "Passpport",
                url: "",
                label: "StudentInIndia_Passpport"
            },
            {
                name: "i20",
                url: "",
                label: "StudentWorking_i20"
            },
            {
                name: "Pan Card",
                url: "",
                label: "StudentWorking_PanCard"
            },
            {
                name: "Health Declaration",
                url: "",
                label: "StudentWorking_HealthDeclaration"
            },
            {
                name: "Visa",
                url: "",
                label: "StudentWorking_Visa"
            },
            {
                name: "i94",
                url: "",
                label: "StudentWorking_i94"
            },
            {
                name: "Exit Stamp",
                url: "",
                label: "StudentWorking_ExitStamp"
            }
        ]
    }
    return hiDocumentMap
}


export default getHiDocumentMap;