import React, { useContext, useEffect, useState } from "react";
import Styles from "./HiManagerLeadStatus.module.css"
import UserContext from "../../../context/UserData.context";
import { onValue, ref } from "firebase/database";
import { firebaseDatabase } from "../../../backend/firebaseHandler";
import { CircularProgress, Tab, Tabs } from "@mui/material";
import HiManagerNewLeads from "../../HiManagerNewLeads/HiManagerNewLeads.component";
import moment from "moment/moment";
import HiManagerInterestedLeads from "../../HiManagerInterestedLeads/HiManagerInterestedLeads.component";
import HiManagerClosedLeads from "../../HiManagerClosedLeads/HiManagerClosedLeads.component";
import HiManagerWithOprationsLeads from "../../HiManagerWithOprationsLeads/HiManagerWithOprationsLeads.component";
import HiManagerLinkSentLeads from "../../HiManagerLinkSentLeads/HiManagerLinkSentLeads.component";
import HiManagerPolicyGeneratedLeads from "../../HiManagerPolicyGeneratedLeads/HiManagerPolicyGeneratedLeads.component";

const HiManagerLeadStatus = () => {

    const [list, setList] = useState([])
    const [value, setValue] = useState(0)
    const [loading, setLoading] = useState(true)
    const [userData, setUserData] = useContext(UserContext)

    useEffect(() => {
        handleChange(null, 0)
    }, [userData])

    const handleChange = (event, newValue) => {
        setValue(newValue)

        let temp = []
        let temp2 = []
        let dbRef = null;

        setLoading(true)
        switch (newValue) {
            case 0: {
                dbRef = ref(firebaseDatabase, `HI_NEW_LEADS`)
                break;
            }
            case 1: {
                dbRef = ref(firebaseDatabase, `HI_INTERESTED_LEADS`)
                break;
            }
            case 2: {
                dbRef = ref(firebaseDatabase, `HI_RESCHEDULED_LEADS`)
                break;
            }
            case 3: {
                // dbRef = ref(firebaseDatabase, `HI_WITH_OPERATIONS_LEADS`)
                break;
            }
            case 4: {
                dbRef = ref(firebaseDatabase, `HI_OP_USER_LINK_SENT_LEADS`)
                break;
            }
            case 5: {
                dbRef = ref(firebaseDatabase, `HI_OP_USER_POLICY_GENERATED_LEADS`)
                break;
            }
            case 6: {
                dbRef = ref(firebaseDatabase, `HI_CLOSED_LEADS`)
                break;
            }
            default: {
                dbRef = ref(firebaseDatabase, `ACTIVE_LEADS`)
            }
        }

        if (newValue !== 3) {
            onValue(dbRef, (snapShot) => {
                if (snapShot.exists()) {
                    if (newValue === 0 || newValue === 1 || newValue === 2 || newValue === 6) {
                        for (const key in snapShot.val()) {
                            for (const phNo in snapShot.child(key).val()) {
                                let item = snapShot.child(key).child(phNo).val()
                                if (item.receivedFrom.uid === userData.uid || item.receivedFrom.uid === "CdYsrXiTXSQcbYGxkLaVf24GaKF2") {
                                    temp.push(item)
                                }
                            }
                        }
                    } else {
                        for (const key in snapShot.val()) {
                            let item = snapShot.child(key).val()
                            if (item.receivedFrom.uid === userData.uid || item.receivedFrom.uid === "CdYsrXiTXSQcbYGxkLaVf24GaKF2") {
                                temp.push(item)
                            }
                        }
                    }
                    
                    temp2 = temp.sort((a, b) => {
                        const date_1 = moment(a.date,"DD-MM-YYYY h:mm a")
                        const date_2 = moment(b.date,"DD-MM-YYYY h:mm a")
                        return moment(date_2).diff(date_1)
                    })
                }
                setList(temp2)
                setLoading(false)
            }, {onlyOnce:true})
        } else {
            setLoading(false)
        }
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <div>
            <h1 style={{ color: "#352F6C" }}>Lead Status</h1>

            <div>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" >
                    <Tab label="New" {...a11yProps(0)} />
                    <Tab label="Interested" {...a11yProps(1)} />
                    <Tab label="Rescheduled" {...a11yProps(2)} />
                    <Tab label="Operations" {...a11yProps(3)} />
                    <Tab label="Link Sent" {...a11yProps(4)} />
                    <Tab label="Policy Generated" {...a11yProps(5)} />
                    <Tab label="Closed" {...a11yProps(6)} />
                </Tabs>
            </div>

            <div className={Styles.progressComponentContainer}>
                {
                    loading
                    ?
                    <CircularProgress className={Styles.progress} />
                    :
                    <div>
                        {
                            value === 0 && <HiManagerNewLeads activeList={list} />
                        }

                        {
                            value === 1 && <HiManagerInterestedLeads activeList={list} />
                        }

                        {
                            value === 2 && <HiManagerNewLeads activeList={list} />
                        }

                        {
                            value === 3 && <HiManagerWithOprationsLeads />
                        }

                        {
                            value === 4 && <HiManagerLinkSentLeads activeList={list} />
                        }

                        {
                            value === 5 && <HiManagerPolicyGeneratedLeads activeList={list} />
                        }

                        {
                            value === 6 && <HiManagerClosedLeads activeList={list} />
                        }
                        
                    </div>
                }
            </div>
        </div>
    )
}

export default HiManagerLeadStatus