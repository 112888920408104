import React from "react";
import Styles from "./HiManagerClosedLeads.module.css"
import { Box, CircularProgress, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs } from "@mui/material";

const HiManagerClosedLeads = ({activeList}) => {

    return (
        <div>
            <Box>
                <TableContainer>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>#</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Name</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Ph Number</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Email ID</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>University</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Date Received</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Reason</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Advisor</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody style={{backgroundColor:"white"}}>
                            {
                                activeList.map((item, index) => {
                                    return (
                                        <TableRow key={index.toString()} >
                                            <TableCell>{(index + 1).toString()}</TableCell>
                                            <TableCell>{item.name}</TableCell>
                                            <TableCell>{item.phoneNumber}</TableCell>
                                            <TableCell>{item.emailId}</TableCell>
                                            <TableCell>{item.desiredUniversity}</TableCell>
                                            <TableCell>{item.newFromAdminDate?item.newFromAdminDate.split(" ")[0]:""}</TableCell>
                                            <TableCell>{item.subStatus}</TableCell>
                                            <TableCell>{item.assignedAdvisorName}</TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </div>
    )
}

export default HiManagerClosedLeads