import { onValue, ref, set } from "firebase/database";
import React, { useState } from "react";
import { firebaseDatabase } from "../../backend/firebaseHandler";

const ForTesting = () => {
    
    const handleUpdate = async () => {

        // await set(ref(firebaseDatabase, "VENDOR_FILE_POOL/UnionBank/917875573022/assignedAdvisorName"), "Kamaljeet Kaur")
        // await set(ref(firebaseDatabase, "VENDOR_FILE_POOL/UnionBank/917875573022/assignedAdvisorPhoneNumber"), "8627861474")
        // await set(ref(firebaseDatabase, "VENDOR_FILE_POOL/UnionBank/917875573022/assignedAdvisorUid"), "QwH6KY2gzbWi47NnzhPteSvEmyE3")

        // await set(ref(firebaseDatabase, "VENDOR_FILE_POOL/UnionBank/918547563411/assignedAdvisorName"), "Yash Sagar")
        // await set(ref(firebaseDatabase, "VENDOR_FILE_POOL/UnionBank/918547563411/assignedAdvisorPhoneNumber"), "8219307304")
        // await set(ref(firebaseDatabase, "VENDOR_FILE_POOL/UnionBank/918547563411/assignedAdvisorUid"), "k7uP5XdAr9fyMmat5AfEZuWerSs1")


        // await set(ref(firebaseDatabase, "VENDOR_FILE_POOL/UnionBank/919676434955/assignedAdvisorName"), "Pathan Vali")
        // await set(ref(firebaseDatabase, "VENDOR_FILE_POOL/UnionBank/919676434955/assignedAdvisorPhoneNumber"), "8920175330")
        // await set(ref(firebaseDatabase, "VENDOR_FILE_POOL/UnionBank/919676434955/assignedAdvisorUid"), "zrRuWWRvDbMJFLicUyaR8aH5qU32")
    }

    

    const handleTransfer = () => {
        
    }

    return (
        <div style={{padding:50}}>
            <h1 onClick={()=>{handleUpdate()}}>Update</h1>
        </div>
    )
}

export default ForTesting