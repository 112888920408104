import React, { useContext, useEffect, useState } from "react";
import Styles from "./HiAdvisorInterestedLeads.module.css"
import { onValue, ref } from "firebase/database";
import { firebaseDatabase } from "../../../backend/firebaseHandler";
import UserContext from "../../../context/UserData.context";
import { Box, CircularProgress, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs } from "@mui/material";
import { IoCall } from "react-icons/io5"
import { useNavigate } from "react-router-dom";
import moment from "moment";
import HiInterestedCollapseRow from "../../HiInterestedCollapseRow/HiInterestedCollapseRow.component";

const HiAdvisorInterestedLeads = () => {

    const [loading, setLoading] = useState(true)
    const [visibleList, setVisibleList] = useState([])
    const [userData, setUserData] = useContext(UserContext)

    useEffect(() => {
        let temp = []
        let temp2 = []

        onValue(ref(firebaseDatabase, `HI_INTERESTED_LEADS/${userData.uid}`), (snap) => {
            if (snap.exists()) {
                for (const key in snap.val()) {
                    let item = snap.child(key).val()
                    let travelDate = moment(item.travelDate, "DD-MM-YYYY").format("YYYY-MM-DD")
                    let lastCallDate = moment(item.lastCallDate.split(" ")[0], "DD-MM-YYYY").format("YYYY-MM-DD")
                    item.travelAge = moment(travelDate).diff(moment(), 'days')
                    item.callAge = moment().diff(lastCallDate, 'days')
                    temp.push(item)
                }

                temp2 = temp.sort((a, b) => {
                    const date_1 = moment(a.nextCallDateTime,"DD-MM-YYYY h:mm a")
                    const date_2 = moment(b.nextCallDateTime,"DD-MM-YYYY h:mm a")
                    return moment(date_1).diff(date_2)
                })
            }
            setVisibleList([...temp2])
            setLoading(false)
        }, {onlyOnce:true})
    }, [])

    return (
        <div>
            <h1 style={{ color: "#352F6C", marginBottom:40 }}>Interested Leads</h1>

            <div className={Styles.progressComponentContainer}>
                {
                    loading
                    ?
                    <CircularProgress className={Styles.progress} />
                    :
                    <Box>
                        <TableContainer>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{backgroundColor:"rgba(1, 1, 1, 0)"}}></TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>#</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Name</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Ph Number</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Email ID</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>University</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Days before travel</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Last call age</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Next Call</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}></TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody style={{backgroundColor:"white"}}>
                                    {
                                        visibleList.map((item, index) => {
                                            return (
                                                <HiInterestedCollapseRow userType={"Advisor"} item={item} index={index} />
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                }
            </div>
        </div>
    )
}

export default HiAdvisorInterestedLeads