import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { HiDocumentCheck } from "react-icons/hi2"

const OperationWithVendorLeads = ({ activeList, type }) => {

    const navigate = useNavigate()

    const handleNavigate = (item, button) => {
        if (button === "file") {
            if (type === "Normal") {
                navigate ("/operations-leads/operation-complete-detail", {state:{item:item}})
            } else if (type === "Incognito") {
                navigate ("/operations-incognito-leads/operation-complete-detail-incognito", {state:{item:item}})
            }
        } else {
            if (type === "Normal") {
                navigate ("/operations-leads/operation-nbfc-detail", {state:{item:item}})
            } else if (type === "Incognito") {
                navigate ("/operations-incognito-leads/operation-nbfc-detail-incognito", {state:{item:item}})
            }
        }
        
    }

    return (
        <div>
            <div style={{width:"100%", marginTop:40}}>
                <Box>
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>#</TableCell>
                                    <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Customer ID</TableCell>
                                    <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>App ID</TableCell>
                                    <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Name</TableCell>
                                    <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>PAN Number</TableCell>
                                    <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Date Received</TableCell>
                                    <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Sent Date</TableCell>
                                    {
                                        type === "Incognito"
                                        &&
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Type</TableCell>
                                    }
                                    <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Advisor Name</TableCell>
                                    <TableCell style={{backgroundColor:"rgba(1, 1, 1, 0)"}}></TableCell>
                                    <TableCell style={{backgroundColor:"rgba(1, 1, 1, 0)"}}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody style={{backgroundColor:"white"}}>
                                {
                                    activeList.map((item, index) => {
                                        return (
                                            <TableRow key={index.toString()} >
                                                <TableCell>{(index + 1).toString()}</TableCell>
                                                <TableCell>{item.customerId}</TableCell>
                                                <TableCell>{item.applicationId}</TableCell>
                                                <TableCell>{item.name}</TableCell>
                                                <TableCell>{item.caseDetail?item.caseDetail.panNumber:""}</TableCell>
                                                <TableCell>{item.withOperationsDate?item.withOperationsDate.split(" ")[0]:""}</TableCell>
                                                <TableCell>{item.sentToVendorDate?item.sentToVendorDate:""}</TableCell>
                                                {
                                                    type === "Incognito"
                                                    &&
                                                    <TableCell>{item.incognitoType}</TableCell>
                                                }
                                                <TableCell>{item.assignedAdvisorName}</TableCell>
                                                <TableCell><HiDocumentCheck onClick={()=>{handleNavigate(item, "file")}} color={"#352F6C"} size={25} style={{cursor:"pointer"}} /></TableCell>
                                                <TableCell><Button onClick={()=>{handleNavigate(item, "status")}} style={{backgroundColor:"#352F6C", height:30, width:120}} size='medium' variant='contained'>View NBFC</Button></TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </div>
        </div>
    )
}

export default OperationWithVendorLeads