import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getDatabase } from 'firebase/database'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

const firebaseConfigSC = {
  apiKey: process.env.REACT_APP_FIREBASE_SC_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_SC_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_SC_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_SC_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_SC_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SC_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_SC_APP_ID,
  // measurementId: process.env.REACT_APP_FIREBASE_SC_MEASUREMENT_ID // Optional, if needed
};

const firebaseConfigAdmin = {
  apiKey: process.env.REACT_APP_FIREBASE_ADMIN_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_ADMIN_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_ADMIN_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_ADMIN_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_ADMIN_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_ADMIN_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_ADMIN_APP_ID
};

const firebaseConfigFidesure = {
  apiKey: process.env.REACT_APP_FIREBASE_FIDESURE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_FIDESURE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_FIDESURE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_FIDESURE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_FIDESURE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_FIDESURE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_FIDESURE_APP_ID,
  // measurementId: process.env.REACT_APP_FIREBASE_FIDESURE_MEASUREMENT_ID // Optional, if needed
};

const app = initializeApp(firebaseConfig);
const appSC = initializeApp(firebaseConfigSC, "Student Cover")
const appAdmin = initializeApp(firebaseConfigAdmin, "Admin")
const appFidesure = initializeApp(firebaseConfigFidesure, "Fidesure")

export const firebaseAuth = getAuth(app);
export const firebaseStorage = getStorage(app);
export const firebaseDatabase = getDatabase(app);
export const firebaseDatabaseSC = getDatabase(appSC)
export const firebaseStorageSC = getStorage(appSC)
export const firebaseDatabaseAdmin = getDatabase(appAdmin)
export const firebaseDatabaseFidesure = getDatabase(appFidesure)