import React, { useContext, useEffect, useState } from "react";
import Styles from "./AdminDasbhoard.module.css";
import UserContext from "../../context/UserData.context";
import { BarChart } from "@mui/x-charts";
import { ref, onValue } from 'firebase/database';
import { firebaseDatabase } from "../../backend/firebaseHandler";
import { CircularProgress, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import moment from "moment";

const AdminDasbhoard = () => {
    const [userData, setUserData] = useContext(UserContext);
    const todaysDate = moment().format("DD-MM-YYYY")


    return (
        <div className={Styles.dashboardContainer}>
            <h1>Hello {userData.name}</h1>

            <h2 style={{ marginTop: '12px' }}>Student Cover Reports</h2>
            <div className={Styles.contentContainer}>
                <LineChartWithData reference={`/MIS/SC_MIS/${todaysDate}/INTERESTED`} label='Interested' />
                <LineChartWithData reference={`/MIS/SC_MIS/${todaysDate}/CASE_DETAIL`} label='Case Detail' />
                <LineChartWithData reference={`/MIS/SC_MIS/${todaysDate}/OPERATIONS`} label='Operations' />
                <LineChartWithData reference={`/MIS/SC_MIS/${todaysDate}/VENDOR_STATUS`} label='Vendor Status' />
                <LineChartWithData reference={`/MIS/SC_MIS/${todaysDate}/PF_PAID`} label='Pf Paid' />
                <LineChartWithData reference={`/MIS/SC_MIS/${todaysDate}/SL_SENT`} label='SL Sent' />
                <LineChartWithData reference={`/MIS/SC_MIS/${todaysDate}/DISBURSED`} label='Disbursed' />
                <LineChartWithData reference={`/MIS/SC_MIS/${todaysDate}/CLOSED`} label='Closed' />
            </div>
            <Divider sx={{ marginTop: '20px' }} />
            <h2 style={{ marginTop: '20px' }}>Vendor Reports</h2>
            <VendorReport />
        </div>
    )
}


const LineChartWithData = ({ reference, label }) => {

    const [data, setData] = useState({ loading: true, noData: false, data: {}, kind: 'bar' });

    useEffect(() => {
        const databaseRef = ref(firebaseDatabase, reference);
        onValue(databaseRef, (dataSnapshot) => {
            if (dataSnapshot.exists()) {
                setData({
                    loading: false,
                    noData: false,
                    data: dataSnapshot.val()
                })

            } else {
                setData({
                    loading: false,
                    noData: true,
                    data: {}
                })
            }
        }, { onlyOnce: true })
    }, [])



    return (
        <div className={Styles.graphContainer}>
            <h3>{label}</h3>
            {
                data.loading
                    ?
                    <CircularProgress />
                    :
                    data.noData
                        ?
                        <p style={{ opacity: 0.5 }} >Nothing to show here</p>
                        :
                        <BarChart
                            xAxis={[{ scaleType: 'band', data: Object.keys(data.data) }]}
                            series={[{ data: Object.values(data.data) }]}
                            grid={true}
                            sx={{ width: '100%' }}
                            height={300}
                            title="Intested"
                        />


            }

        </div>
    )
}

const VendorReport = () => {
    const [data, setData] = useState({ loading: true, noData: false, data: [] });
    useEffect(() => {
        const databaseRef = ref(firebaseDatabase, "/MIS/VENDOR_MIS");
        onValue(databaseRef, (dataSnapshot) => {
            if (dataSnapshot.exists()) {
                setData({
                    loading: false,
                    noData: false,
                    data: dataSnapshot.val()
                })

            } else {
                setData({
                    loading: false,
                    noData: true,
                    data: []
                })
            }
        }, { onlyOnce: true })
    }, [])

    return (
        <div>
            {
                data.loading
                &&
                <CircularProgress />
            }
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>NBFC</TableCell>
                            <TableCell>Manager Name</TableCell>
                            <TableCell>Approved</TableCell>
                            <TableCell>Disbursed</TableCell>
                            <TableCell>Follow Up</TableCell>
                            <TableCell>Fresh</TableCell>
                            <TableCell>Incomplete File</TableCell>
                            <TableCell>Logged-in</TableCell>
                            <TableCell>Not Contactable</TableCell>
                            <TableCell>Not Doable</TableCell>
                            <TableCell>PF Paid</TableCell>
                            <TableCell>Re-check</TableCell>
                            <TableCell>Rejected</TableCell>
                            <TableCell>SL Received</TableCell>
                            <TableCell>Shared</TableCell>
                            <TableCell>Updated</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            data.data.map(item => {
                                return (
                                    <TableRow style={{ backgroundColor: 'white' }} key={`${item['nbfc']}-${item['assignedNbfcManagerName']}`}>
                                        <TableCell>{item['nbfc']}</TableCell>
                                        <TableCell>{item['assignedNbfcManagerName']}</TableCell>
                                        <TableCell>{item['Approved']}</TableCell>
                                        <TableCell>{item['Disbursed']}</TableCell>
                                        <TableCell>{item['Follow Up']}</TableCell>
                                        <TableCell>{item['Fresh']}</TableCell>
                                        <TableCell>{item['Incomplete File']}</TableCell>
                                        <TableCell>{item['Logged-in']}</TableCell>
                                        <TableCell>{item['Not Contactable']}</TableCell>
                                        <TableCell>{item['Not Doable']}</TableCell>
                                        <TableCell>{item['PF Paid']}</TableCell>
                                        <TableCell>{item['Re-check']}</TableCell>
                                        <TableCell>{item['Rejected']}</TableCell>
                                        <TableCell>{item['SL Received']}</TableCell>
                                        <TableCell>{item['Shared']}</TableCell>
                                        <TableCell>{item['Updated']}</TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default AdminDasbhoard;