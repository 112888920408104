import React, { useContext, useState } from "react";
import Styles from "./AdvisorWithVendorLeads.module.css"
import UserContext from "../../../context/UserData.context";
import { useNavigate } from "react-router-dom";
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { HiDocumentCheck } from "react-icons/hi2"

const AdvisorWithVendorLeads = ({ activeList, type }) => {

    const navigate = useNavigate()

    return (
        <div>
            <div style={{width:"100%", marginTop:40}}>
                <Box>
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>#</TableCell>
                                    <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Customer ID</TableCell>
                                    <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>App ID</TableCell>
                                    <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Name</TableCell>
                                    <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Ph Number</TableCell>
                                    <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Email ID</TableCell>
                                    <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>PAN Number</TableCell>
                                    <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Sent Date</TableCell>
                                    <TableCell style={{backgroundColor:"rgba(1, 1, 1, 0)"}}></TableCell>
                                    <TableCell style={{backgroundColor:"rgba(1, 1, 1, 0)"}}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody style={{backgroundColor:"white"}}>
                                {
                                    activeList.map((item, index) => {
                                        return (
                                            <TableRow key={index.toString()} >
                                                <TableCell>{(index + 1).toString()}</TableCell>
                                                <TableCell>{item.customerId}</TableCell>
                                                <TableCell>{item.applicationId}</TableCell>
                                                <TableCell>{item.name}</TableCell>
                                                <TableCell>xxxxxxxxx{item.phoneNumber.substring(9, 12)}</TableCell>
                                                <TableCell>{item.emailId}</TableCell>
                                                <TableCell>{item.caseDetail?item.caseDetail.panNumber:""}</TableCell>
                                                <TableCell>{item.sentToVendorDate?item.sentToVendorDate:""}</TableCell>
                                                {
                                                    type === "INCOGNITO"
                                                    ?
                                                    <TableCell><Button onClick={()=>{navigate ("/advisor-incognito-leads/advisor-incognito-complete-detail", {state:{item:item}})}} style={{backgroundColor:item.subStatus!=="Re-check"?"#352F6C":"#e0a500", height:30}} size='medium' variant='contained'>Edit</Button></TableCell>
                                                    :
                                                    <TableCell><Button onClick={()=>{navigate ("/advisor-leads/advisor-complete-detail", {state:{item:item}})}} style={{backgroundColor:item.subStatus!=="Re-check"?"#352F6C":"#e0a500", height:30}} size='medium' variant='contained'>Edit</Button></TableCell>
                                                }
                                                {
                                                    type === "INCOGNITO"
                                                    ?
                                                    <TableCell><Button onClick={()=>{navigate("/advisor-incognito-leads/advisor-nbfc-detail-incognito", {state:{item:item}})}} style={{backgroundColor:"#352F6C", height:30, width:120}} size='medium' variant='contained'>View NBFC</Button></TableCell>
                                                    :
                                                    <TableCell><Button onClick={()=>{navigate("/advisor-leads/advisor-nbfc-detail", {state:{item:item}})}} style={{backgroundColor:"#352F6C", height:30, width:120}} size='medium' variant='contained'>View NBFC</Button></TableCell>
                                                }
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </div>
        </div>
    )
}

export default AdvisorWithVendorLeads