import { onValue, ref, set } from "firebase/database"
import { firebaseDatabase } from "../backend/firebaseHandler"
import healthInsurance from "../entities/HealthInsurance.sample";
import moment from "moment";

export const checkStatus = async (phoneNumber) => {
    const statusPromise = new Promise((resolve, reject) => {
        const allLeadsRef = ref(firebaseDatabase, `ALL_LEADS_STATUS/Health Insurance/${phoneNumber}/status`);
        onValue(allLeadsRef, (dataSnapshot) => {
            if (dataSnapshot.exists()) {
                const status = dataSnapshot.val();
                if (status === "Operations" || status === "Link Sent" || status === "Policy Generated") {
                    reject("Already in process");
                } else {
                    resolve(status);
                }
            } else {
                resolve("Fresh");
            }
        }, { onlyOnce: true })
    })

    return await statusPromise.then()
}

export const writePotentialCrossSale = async (lead) => {
    try {
        const shouldWrite = await checkStatus(lead.phoneNumber)
        if (shouldWrite) {
            const newCSLead = {
                ...healthInsurance,
                assignedAdvisorName: lead.assignedAdvisorName,
                assignedAdvisorPhoneNumber: lead.assignedAdvisorPhoneNumber,
                assignedAdvisorUid: lead.assignedAdvisorUid,
                customerId: lead.customerId,
                date: lead.date,
                markedPotentialCSDate: moment().format("DD-MM-YYYY h:mm a"),
                desiredCountry: lead.desiredCountry,
                desiredUniversity: lead.desiredUniversity,
                dob: lead.dob,
                emailId: lead.emailId,
                indiaAddress: lead?.caseDetail?.permanentAddress || "",
                key: lead.key,
                motherName: lead?.caseDetail?.motherName || "",
                lastCallDate: lead.lastCallDate,
                name: lead.name,
                panNumber: lead?.caseDetail?.panNumber || "",
                passportNumber: "",
                phoneNumber: lead.phoneNumber,
                product: "Education Loan",
                receivedFrom: lead.receivedFrom,
                source: lead.source,
                sourceDescription: lead.sourceDescription,
                status: "Potential CS",
                subStatus: "New",
                type: lead.type
            }
            const potentialLeadRef = ref(firebaseDatabase, `POTENTIAL_CROSS_SALES_LEADS/${lead.phoneNumber}`);
            await set(potentialLeadRef, newCSLead);
        }
    } catch (err) {
        console.log(err)
    }
}