import React, { useContext, useEffect, useState } from "react";
import Styles from "./OperationsIncognitoLeads.module.css"
import UserContext from "../../../context/UserData.context";
import { useNavigate } from "react-router-dom";
import { onValue, ref } from "firebase/database";
import { firebaseDatabase } from "../../../backend/firebaseHandler";
import { CircularProgress, Tab, Tabs } from "@mui/material";
import OperationWithOperationLeads from "../../OperationsLeadFlow/OperationWithOperationLeads/OperationWithOperationLeads.component";
import OperationWithVendorLeads from "../../OperationsLeadFlow/OperationWithVendorLeads/OperationWithVendorLeads.component";
import OperationPfPaidLeads from "../../OperationsLeadFlow/OperationPfPaidLeads/OperationPfPaidLeads.component";
import OperationSlReceivedLeads from "../../OperationsLeadFlow/OperationsSlReceivedLeads/OperationsSlReceivedLeads.component";
import OperationDisbursementLeads from "../../OperationsLeadFlow/OperationDisbursementLeads/OperationDisbursementLeads.component";
import moment from "moment";

const OperationsIncognitoLeads = () => {

    const [activeList, setActiveList] = useState([])
    const [userData, setUserData] = useContext(UserContext)
    const [value, setValue] = React.useState(0);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        handleChange(null, 0)
    }, [userData])

    const handleChange = (event, newValue) => {
        setValue(newValue)
        setLoading(true)

        let temp = []
        let temp2 = []
        let dbRef = null;

        switch (newValue) {
            case 0: {
                dbRef = ref(firebaseDatabase, `INCOGNITO_OP_USER_WITH_OPERATIONS_LEADS`)
                break;
            }
            case 1: {
                dbRef = ref(firebaseDatabase, `INCOGNITO_OP_USER_WITH_VENDORS_LEADS`)
                break;
            }
            case 2: {
                dbRef = ref(firebaseDatabase, `INCOGNITO_OP_USER_PF_PAID_LEADS`)
                break;
            }
            case 3: {
                dbRef = ref(firebaseDatabase, `INCOGNITO_OP_USER_SL_RECEIVED_LEADS`)
                break;
            }
            case 4: {
                dbRef = ref(firebaseDatabase, `INCOGNITO_OP_USER_DISBURSED_LEADS`)
                break;
            }
            case 5: {
                dbRef = ref(firebaseDatabase, `INCOGNITO_OP_USER_CLOSED_LEADS`)
                break;
            }
            default: {
                dbRef = ref(firebaseDatabase, `INCOGNITO_OP_USER_WITH_OPERATIONS_LEADS/${userData.uid}`)
            }
        }

        onValue(dbRef, (snapShot) => {
            if (snapShot.exists()) {
                for (const key in snapShot.val()) {
                    let item = snapShot.child(key).val()
                    temp.push(item)
                }

                temp2 = temp.sort((a, b) => {
                    const date_1 = moment(a.withOperationsDate,"DD-MM-YYYY h:mm a")
                    const date_2 = moment(b.withOperationsDate,"DD-MM-YYYY h:mm a")
                    return moment(date_2).diff(date_1)
                })

                setActiveList(temp2)
                setLoading(false)
            } else {
                setActiveList(temp2)
                setLoading(false)
            }
        }, {onlyOnce:true})
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <div>
            <div>
                <h1 style={{color:"#352F6C"}}>Incognito Leads</h1>
            </div>

            <div>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" >
                    <Tab label="Operations" {...a11yProps(0)} />
                    <Tab label="Vendor Status" {...a11yProps(1)} />
                    <Tab label="PF Paid" {...a11yProps(2)} />
                    <Tab label="SL Received" {...a11yProps(3)} />
                    <Tab label="Disbursed" {...a11yProps(4)} />
                    <Tab label="Closed" {...a11yProps(5)} />
                </Tabs>
            </div>

            <div className={Styles.progressComponentContainer}>
                {
                    loading
                    ?
                    <CircularProgress className={Styles.progress} />
                    :
                    <>
                        {
                            value === 0 && <OperationWithOperationLeads activeList={activeList} type="Incognito" />
                        }

                        {
                            value === 1 && <OperationWithVendorLeads activeList={activeList} type="Incognito" />
                        }

                        {/* {
                            value === 2 && <OperationPfPaidLeads activeList={activeList} />
                        }
                        {
                            value === 3 && <OperationSlReceivedLeads activeList={activeList} />
                        }
                        {
                            value === 4 && <OperationDisbursementLeads activeList={activeList} />
                        } */}
                    </>
                }
            </div>
        </div>
    )
}

export default  OperationsIncognitoLeads