import React from 'react';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import HomePage from './pages/HomePage/HomePage.component';
import UserContext, { UserProvider } from '../src/context/UserData.context';
import { useContext, useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { firebaseAuth, firebaseDatabase } from './backend/firebaseHandler';
import AuthPage from './pages/AuthPage/AuthPage.component';
import { onValue, ref, set } from 'firebase/database';
import moment from 'moment';
import { ThemeProvider } from '@emotion/react';
import { CircularProgress, CssBaseline, createTheme } from '@mui/material';
import ForTesting from './pages/ForTesting/ForTesting.component';
import HealthInsurance from './pages/HealthInsurance/HealthInsurance.component';
import { ToastContainer } from 'react-toastify';
import ContestPopup from './components/ContestPopup/ContestPopup.component';
import LatestVersion from './workload/LatestVersion';


function App() {

  const [currentState, setCurrentState] = useState("SPLASH")
  const [userData, setUserData] = useContext(UserContext)

  useEffect(() => {
    onAuthStateChanged(firebaseAuth, (user) => {
      if (user) {
        onValue((ref(firebaseDatabase, "USER_ARCHIVE/" + user.uid)), async (snapShot) => {
          if (snapShot.exists()) {
            if (snapShot.child("status").val() === "Active") {
              setUserData(snapShot.val())
              let item = snapShot.val()
              item.currently = "Online"
              item.lastLogin = moment().format("DD-MM-YYYY, h:mm a")
              item.version = LatestVersion
              await set(ref(firebaseDatabase, `USER_ARCHIVE/${item.uid}/`), item)
              if (item.department === "Health Insurance") {
                setCurrentState("HI")
              } else {
                setCurrentState("HOME")
              }
            } else {
              alert("Your profile is currently inactive/disabled. Please try again in a little while. If the problem persists, please contact the admin.")
              setCurrentState("LOGIN")
            }
          } else {
            alert("Something went wrong. Please try again later.")
            setCurrentState("LOGIN")
          }
        }, { onlyOnce: true })
      } else {
        setCurrentState("LOGIN")
      }
    })
  }, [])

  if (currentState === "SPLASH") {
    return (
      <div style={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: "center" }}>
        <CircularProgress />
      </div>
    )
  }

  if (currentState === "LOGIN") {
    return (
      <AuthPage />
    )
  }

  if (currentState === "HI") {
    return (
      <BrowserRouter>
        <Routes>
          <Route path='/health-insurance' element={<HealthInsurance />} />
          <Route path='/health-insurance/number-record' element={<HealthInsurance />} />
          <Route path='/health-insurance/create-hi-files' element={<HealthInsurance />} />
          <Route path='/health-insurance/add-single-lead' element={<HealthInsurance />} />
          <Route path='/health-insurance/distribute-leads' element={<HealthInsurance />} />
          <Route path='/health-insurance/re-assign-leads' element={<HealthInsurance />} />
          <Route path='/health-insurance/lead-status' element={<HealthInsurance />} />
          <Route path='/health-insurance/lead-status/complete-lead-detail' element={<HealthInsurance />} />
          <Route path='/health-insurance/new-leads' element={<HealthInsurance />} />
          <Route path='/health-insurance/new-leads/new-lead-detail' element={<HealthInsurance />} />
          <Route path='/health-insurance/rescheduled-leads' element={<HealthInsurance />} />
          <Route path='/health-insurance/rescheduled-leads/rescheduled-lead-detail' element={<HealthInsurance />} />
          <Route path='/health-insurance/interested-leads' element={<HealthInsurance />} />
          <Route path='/health-insurance/interested-leads/interested-lead-detail' element={<HealthInsurance />} />
          <Route path='/health-insurance/operations-leads' element={<HealthInsurance />} />
          <Route path='/health-insurance/closed-leads' element={<HealthInsurance />} />
          <Route path='/health-insurance/closed-leads/closed-lead-detail' element={<HealthInsurance />} />
          <Route path='/health-insurance/operations-new-leads' element={<HealthInsurance />} />
          <Route path='/health-insurance/operations-link-sent' element={<HealthInsurance />} />
          <Route path='/health-insurance/operations-new-leads/operations-new-lead-detail' element={<HealthInsurance />} />
          <Route path='/health-insurance/operations-policy-generated' element={<HealthInsurance />} />
          <Route path='/health-insurance/sbp-purchase-record' element={<HealthInsurance />} />
          <Route path='/health-insurance/sbp-purchase-record/sbp-purchase-detail' element={<HealthInsurance />} />
          <Route path='/health-insurance/f1-opt-purchase-record' element={<HealthInsurance />} />
          <Route path='/health-insurance/f1-opt-purchase-record/f1-opt-purchase-detail' element={<HealthInsurance />} />
          <Route path='/health-insurance/free-ti-claims' element={<HealthInsurance />} />
          <Route path='/health-insurance/free-ti-claims/ti-claim-detail' element={<HealthInsurance />} />
          <Route path="*" element={<Navigate to="/health-insurance" />} />
        </Routes>
      </BrowserRouter>
    )
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/testing-page' element={<ForTesting />} />
        <Route path='/admin-dashboard' element={<HomePage />} />
        <Route path='/add-single-lead' element={<HomePage />} />
        <Route path='/manage-users' element={<HomePage />} />
        <Route path='/manage-users/add-user' element={<HomePage />} />
        <Route path='/manage-users/edit-profile' element={<HomePage />} />
        <Route path='/manage-leads' element={<HomePage />} />
        <Route path='/manage-leads/manager-nbfc-detail' element={<HomePage />} />
        <Route path='/call-stats' element={<HomePage />} />
        <Route path='/distribute-leads' element={<HomePage />} />
        <Route path='/incognito-leads' element={<HomePage />} />
        <Route path='/lead-status' element={<HomePage />} />
        <Route path='/inbound-leads' element={<HomePage />} />
        <Route path='/all-hi-lead-status' element={<HomePage />} />
        <Route path='/all-hi-lead-status/complete-lead-detail' element={<HomePage />} />
        <Route path='/vendor-wise-leads' element={<HomePage />} />
        <Route path='/bt-and-nd' element={<HomePage />} />
        <Route path='/re-assign-leads' element={<HomePage />} />
        <Route path='/inactive-users' element={<HomePage />} />
        <Route path='/closed-leads' element={<HomePage />} />
        <Route path='/lead-status/admin-complete-detail' element={<HomePage />} />
        <Route path='/lead-status/admin-nbfc-detail' element={<HomePage />} />
        <Route path='/advisor-leads' element={<HomePage />} />
        <Route path='/advisor-incognito-leads' element={<HomePage />} />
        <Route path='/future-intake' element={<HomePage />} />
        <Route path='/future-intake/future-intake-lead-detail' element={<HomePage />} />
        <Route path='/advisor-dashboard' element={<HomePage />} />
        <Route path='/cross-sale-leads' element={<HomePage />} />
        <Route path='/cross-sale-hi-operations' element={<HomePage />} />
        <Route path='/cross-sale-leads/cross-sale-lead-detail' element={<HomePage />} />
        <Route path='/advisor-leads/advisor-lead-detail' element={<HomePage />} />
        <Route path='/advisor-incognito-leads/advisor-incognito-lead-detail' element={<HomePage />} />
        <Route path='/advisor-leads/advisor-case-detail' element={<HomePage />} />
        <Route path='/advisor-incognito-leads/advisor-incognito-case-detail' element={<HomePage />} />
        <Route path='/advisor-leads/advisor-complete-detail' element={<HomePage />} />
        <Route path='/advisor-incognito-leads/advisor-incognito-complete-detail' element={<HomePage />} />
        <Route path='/advisor-leads/advisor-nbfc-detail' element={<HomePage />} />
        <Route path='/advisor-incognito-leads/advisor-nbfc-detail-incognito' element={<HomePage />} />
        <Route path='/operations-leads' element={<HomePage />} />
        <Route path='/operations-incognito-leads' element={<HomePage />} />
        <Route path='/operations-leads/operation-complete-detail' element={<HomePage />} />
        <Route path='/operations-incognito-leads/operation-complete-detail-incognito' element={<HomePage />} />
        <Route path='/operations-leads/operation-nbfc-detail' element={<HomePage />} />
        <Route path='/operations-incognito-leads/operation-nbfc-detail-incognito' element={<HomePage />} />
        <Route path='vendor-file-pool' element={<HomePage />} />
        <Route path='/nbfc-manager-lead-status' element={<HomePage />} />
        <Route path='/nbfc-r-manager-file-status' element={<HomePage />} />
        <Route path='/vendor-leads' element={<HomePage />} />
        <Route path='/vendor-leads/vendor-login-type' element={<HomePage />} />
        <Route path='/vendor-fresh-leads' element={<HomePage />} />
        <Route path='/vendor-on-hold-leads' element={<HomePage />} />
        <Route path='/vendor-fresh-leads/vendor-complete-detail' element={<HomePage />} />
        <Route path='/vendor-on-hold-leads/vendor-complete-detail' element={<HomePage />} />
        <Route path='/vendor-re-look-leads/vendor-complete-detail' element={<HomePage />} />
        <Route path='/vendor-re-login-leads' element={<HomePage />} />
        <Route path='/vendor-re-look-leads' element={<HomePage />} />
        <Route path='/vendor-update-pf-leads' element={<HomePage />} />
        <Route path='/vendor-update-pf-leads/add-pf-roi' element={<HomePage />} />
        <Route path='/vendor-pf-paid-leads' element={<HomePage />} />
        <Route path='/vendor-sl-sent-leads' element={<HomePage />} />
        <Route path='/vendor-disbursement-leads' element={<HomePage />} />
        <Route path='/vendor-closed-leads' element={<HomePage />} />
        <Route path='/vendor-view-file' element={<HomePage />} />
      </Routes>
    </BrowserRouter>
  );
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#352F6C',
    },

  },
  typography: {
    fontFamily: 'Montserrat, sans-serif',
  },
})

export default () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <UserProvider>
        <App />
        <ToastContainer
          position="bottom-left"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick

          pauseOnFocusLoss={false}
          draggable
          pauseOnHover={false}
          theme="light"

        />
        {/* <ContestPopup /> */}
      </UserProvider>
    </ThemeProvider>
  )
}