import React, { useEffect, useState } from "react";
import Styles from "./VendorWiseLeads.module.css"
import { Box, Button, CircularProgress, MenuItem, Select, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs } from "@mui/material";
import getNbfcList from "../../../workload/NbfcList";
import { onValue, ref } from "firebase/database";
import { firebaseDatabase } from "../../../backend/firebaseHandler";
import moment from "moment";
import exportFromJSON from "export-from-json";
import { HiDocumentDownload } from "react-icons/hi";

const VendorWiseLeads = () => {

    const [value, setValue] = useState(0)
    const [selectedNbfc, setSelectedNbfc] = useState({})
    const [leadList, setLeadList] = useState([])
    const [loading, setLoading] = useState(false);
    const statusArray = ["Pool", "In-review", "Fresh", "Approved", "PF Paid", "SL Received", "Disbursed", "Re-login", "Rejected", "Auto Rejected"]

    useEffect(() => {
        if (selectedNbfc) {
            fetchNbfcData(selectedNbfc)
        }
    }, [value])



    const handleChange = (event, newValue) => {
        setValue(newValue)
    }



    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleSelectNBFC = event => {
        const value = JSON.parse(event.target.value);
        setSelectedNbfc(value);
        fetchNbfcData(value);
    }

    const fetchNbfcData = (nbfc) => {

        console.log(nbfc.uid, value)
        if (nbfc.uid) {
            let dbRef = null

            switch (value) {
                case 0: {
                    dbRef = ref(firebaseDatabase, `VENDOR_FILE_POOL/${nbfc.uid}`)
                    break
                }
                case 1: {
                    dbRef = ref(firebaseDatabase, `VENDOR_LEADS/${nbfc.uid}`)
                    break
                }
                case 2: {
                    dbRef = ref(firebaseDatabase, `VENDOR_FRESH_LEADS/${nbfc.uid}`)
                    break
                }
                case 3: {
                    dbRef = ref(firebaseDatabase, `VENDOR_APPROVED_LEADS/${nbfc.uid}`)
                    break
                }
                case 4: {
                    dbRef = ref(firebaseDatabase, `VENDOR_PF_PAID_LEADS/${nbfc.uid}`)
                    break
                }
                case 5: {
                    dbRef = ref(firebaseDatabase, `VENDOR_SL_SENT_LEADS/${nbfc.uid}`)
                    break
                }
                case 6: {
                    dbRef = ref(firebaseDatabase, `VENDOR_DISBURSED_LEADS/${nbfc.uid}`)
                    break
                }
                case 7: {
                    dbRef = ref(firebaseDatabase, `VENDOR_RE_LOGIN_LEADS/${nbfc.uid}`)
                    break
                }
                case 8: {
                    dbRef = ref(firebaseDatabase, `VENDOR_RE_LOOK_LEADS/${nbfc.uid}`)
                    break
                }
                case 9: {
                    dbRef = ref(firebaseDatabase, `VENDOR_REJECTED_LEADS/${nbfc.uid}`)
                    break
                }
                case 10: {
                    dbRef = ref(firebaseDatabase, `VENDOR_AUTO_REJECT_LEADS/${nbfc.uid}`)
                    break
                }
                default: { }
            }

            let temp = []
            setLoading(true)
            onValue(dbRef, (snap) => {
                if (snap.exists()) {
                    for (const key in snap.val()) {
                        let item = snap.child(key).val()

                        if (value === 0 && item.status !== "Not Shared") {
                            temp.push(item)
                        } else if (value !== 0) {
                            temp.push(item)
                        }
                    }
                    setLeadList(temp)
                    setLoading(false)
                } else {
                    setLeadList([])
                    setLoading(false)
                }
            }, { onlyOnce: true })
        }
    }

    const handleExport = () => {
        let data = []
        let date = moment().format("DD-MM-YYYY")

        for (const index in leadList) {
            let tempObj = {
                "Application ID": leadList[index].applicationId || "",
                "Customer Name": leadList[index].name || "",
                "Phone Number": leadList[index].phoneNumber || "",
                "Email ID": leadList[index].emailId || "",
                "Manager": leadList[index].assignedNbfcManagerName || "",
                "Relationship Manager": leadList[index].assignedRmName || "",
                "SC Advisor": leadList[index].assignedAdvisorName || "",
                "Sent to vendor on": leadList[index].sentToVendorDate || "",
                "RM assigned on": leadList[index].rmAssignmentDate || "",
                "Status": leadList[index].status || "",
                "Sub-status": leadList[index].subStatus || "",
                "Comment from Vendor": leadList[index].noteFromVendor || "",
                "Comment from SC": leadList[index].noteFromSC || "",
                "PF": leadList[index].pf || "",
                "ROI": leadList[index].roi || "",
                "Sanctioned Amount": leadList[index].sanctionedAmount || "",
                "Insurance Offered": leadList[index].insuranceOffered || ""
            }
            data.push(tempObj)
        }
        
        const fileName = selectedNbfc.name + " " + statusArray[value] + ' Leads: ' + date
        const exportType =  exportFromJSON.types.csv
        exportFromJSON({ data, fileName, exportType })
    }

    return (
        <div className={Styles.vendorWiseLeadsContainer}>
            <h1 style={{ color: "#352F6C" }}>Vendor wise leads</h1>

            <div className={Styles.inputButtonContainer}>
                <div>
                    <p style={{ marginTop: 20 }}>Select NBFC</p>
                    <Select value={selectedNbfc.name} onChange={handleSelectNBFC} style={{ backgroundColor: "white", borderRadius: 5, width: 250, height: 40, border: "none", outline: "none" }}>
                        {
                            getNbfcList().map((item, index) => {
                                return (
                                    <MenuItem value={JSON.stringify(item)}>{item.name}</MenuItem>
                                )
                            })
                        }
                    </Select>
                </div>

                <Button onClick={() => { handleExport() }} startIcon={<HiDocumentDownload />} style={{ backgroundColor: "#8069B4" }} size='medium' variant='contained'>Export to Excel</Button>
            </div>
            

            <div style={{ marginTop: 20 }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" >
                    <Tab label="Pool" {...a11yProps(0)} />
                    <Tab label="In-review" {...a11yProps(1)} />
                    <Tab label="Fresh" {...a11yProps(2)} />
                    <Tab label="Approved" {...a11yProps(3)} />
                    <Tab label="PF Paid" {...a11yProps(4)} />
                    <Tab label="SL Received" {...a11yProps(5)} />
                    <Tab label="Disbursed" {...a11yProps(6)} />
                    <Tab label="Re-login" {...a11yProps(7)} />
                    <Tab label="Re-look" {...a11yProps(8)} />
                    <Tab label="Rejected" {...a11yProps(9)} />
                    <Tab label="Auto Rejected" {...a11yProps(10)} />
                </Tabs>
            </div>

            <div className={Styles.progressComponentContainer}>
                {
                    loading
                    ?
                    <CircularProgress className={Styles.progress} />
                    :
                    <Box>
                        <TableContainer>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>#</TableCell>
                                        {
                                            value !== 0
                                            &&
                                            <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>App ID</TableCell>
                                        }
                                        <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Name</TableCell>
                                        <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Ph Number</TableCell>
                                        <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Email ID</TableCell>
                                        <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Manager</TableCell>
                                        {
                                            value !== 0
                                            &&
                                            <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Executive</TableCell>
                                        }
                                        <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Date Sent</TableCell>
                                        {
                                            value !== 0
                                            &&
                                            <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>RM Assigned on</TableCell>
                                        }
                                        {
                                            value === 2
                                            &&
                                            <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Logged-in on</TableCell>
                                        }
                                        {
                                            (value === 3 || value === 4)
                                            &&
                                            <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>PF shared on</TableCell>
                                        }
                                        {
                                            value === 3
                                            &&
                                            <>
                                                <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>ROI</TableCell>
                                                <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>PF</TableCell>
                                            </>
                                        }
                                        
                                        {
                                            value === 4
                                            &&
                                            <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Confirmed on</TableCell>
                                        }

                                        {
                                            value === 5
                                            &&
                                            <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>SL Sent on</TableCell>
                                        }
                                        {
                                            value === 6
                                            &&
                                            <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Disbursed on</TableCell>
                                        }
                                        <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Status</TableCell>
                                        <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>SC Advisor</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody style={{ backgroundColor: "white" }}>
                                    {
                                        leadList.map((item, index) => {
                                            return (
                                                <TableRow key={index.toString()} >
                                                    <TableCell>{index + 1}</TableCell>
                                                    {
                                                        value !== 0
                                                        &&
                                                        <TableCell>{item.applicationId}</TableCell>
                                                    }
                                                    <TableCell>{item.name}</TableCell>
                                                    <TableCell>{item.phoneNumber}</TableCell>
                                                    <TableCell>{item.emailId}</TableCell>
                                                    <TableCell>{item.assignedNbfcManagerName}</TableCell>
                                                    {
                                                        value !== 0
                                                        &&
                                                        <TableCell>{item.assignedRmName}</TableCell>
                                                    }
                                                    <TableCell>{item.sentToVendorDate}</TableCell>
                                                    {
                                                        value !== 0
                                                        &&
                                                        <TableCell>{item.rmAssignmentDate}</TableCell>
                                                    }
                                                    {
                                                        value === 2
                                                        &&
                                                        <TableCell>{item.loginDate}</TableCell>
                                                    }
                                                    {
                                                        (value === 3 || value === 4)
                                                        &&
                                                        <TableCell>{item.pfGivenDate}</TableCell>
                                                    }
                                                    {
                                                        (value === 3)
                                                        &&
                                                        <>
                                                            <TableCell>{item.pf}</TableCell>
                                                            <TableCell>{item.roi}</TableCell>
                                                        </>
                                                    }
                                                    
                                                    {
                                                        value === 4
                                                        &&
                                                        <TableCell>{item.pfPaidDate}</TableCell>
                                                    }
                                                    {
                                                        value === 5
                                                        &&
                                                        <TableCell>{item.slUploadDate}</TableCell>
                                                    }
                                                    {
                                                        value === 6
                                                        &&
                                                        <TableCell>{item.disbursementAmountDate}</TableCell>
                                                    }
                                                    <TableCell>{item.status}</TableCell>
                                                    <TableCell>{item.assignedAdvisorName}</TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                }
            </div>
        </div>
    )
}

export default VendorWiseLeads