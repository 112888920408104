export default {
    uid: "",
    key: "",
    name: "",
    addedOnDate: "",
    addedOnTime: "",
    emailId: "",
    phoneNumber: "",
    department: "",
    position: "",
    assignedManager: "",
    mailingAddress: "",
    rights: [],
    languages: [],
    status: "",
    password: "",
    currently: "",
    lastLogin: "",
    advisorLastCallUID: ""
}