import React, { useContext, useEffect, useState } from "react";
import Styles from "./HiOperationPolicyGeneratedLeads.module.css"
import { onValue, ref, remove, set } from "firebase/database";
import { firebaseDatabase } from "../../../backend/firebaseHandler";
import { Alert, Box, Button, CircularProgress, Snackbar, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs } from "@mui/material";
import { IoDocument } from "react-icons/io5"
import moment from "moment";
import UserContext from "../../../context/UserData.context";

const HiOperationPolicyGeneratedLeads = () => {

    const [loading, setLoading] = useState(true)
    const [visibleList, setVisibleList] = useState([])
    const [userData] = useContext(UserContext)

    useEffect(() => {
        let temp = []
        let temp2 = []

        onValue(ref(firebaseDatabase, `HI_OP_USER_POLICY_GENERATED_LEADS`), (snap) => {
            if (snap.exists()) {
                for (const key in snap.val()) {
                    let item = snap.child(key).val()
                    if ((userData.uid === "93nbcASsRuV8g8pLF4mga0wxbHM2" || userData.uid === "vuc57CqdO8dJCCXFtiQtJoH9VD53") && item.planDetail.name.includes("Prima")) {
                        let travelDate = moment(item.travelDate, "DD-MM-YYYY").format("YYYY-MM-DD")
                        item.travelAge = moment(travelDate).diff(moment(), 'days')
                        temp.push(item)
                    } 
                    if (userData.uid === "0uH2KMkUD7TlfEV3yXL7ZfQGEbh2" && item.planDetail.name === "SC Gold Plus") {
                        let travelDate = moment(item.travelDate, "DD-MM-YYYY").format("YYYY-MM-DD")
                        item.travelAge = moment(travelDate).diff(moment(), 'days')
                        temp.push(item)
                    }
                    
                }

                temp2 = temp.sort((a, b) => {
                    const date_1 = moment(a.paymentConfirmedDate,"DD-MM-YYYY h:mm a")
                    const date_2 = moment(b.paymentConfirmedDate,"DD-MM-YYYY h:mm a")
                    return moment(date_2).diff(date_1)
                })
            }

            setVisibleList([...temp2])
            setLoading(false)
        }, {onlyOnce:true})
    }, [])

    return (
        <div>
            <h1 style={{ color: "#352F6C", marginBottom:40 }}>Policy Generated</h1>

            <div className={Styles.progressComponentContainer}>
                {
                    loading
                    ?
                    <CircularProgress className={Styles.progress} />
                    :
                    <Box>
                        <TableContainer>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>#</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Name</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Ph Number</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Email ID</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Generated on</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Payment Proof</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Policy Document</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Advisor</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody style={{backgroundColor:"white"}}>
                                    {
                                        visibleList.map((item, index) => {
                                            return (
                                                <TableRow key={index.toString()} >
                                                    <TableCell>{(index + 1).toString()}</TableCell>
                                                    <TableCell>{item.name}</TableCell>
                                                    <TableCell>{item.phoneNumber}</TableCell>
                                                    <TableCell>{item.emailId}</TableCell>
                                                    <TableCell>{item.paymentConfirmedDate?item.paymentConfirmedDate.split(" ")[0]:""}</TableCell>
                                                    <TableCell><IoDocument size={20} color="#352F6C" onClick={()=>{window.open(item.proofDocumentUrl, "_blank")}} style={{cursor:"pointer"}} /></TableCell>
                                                    <TableCell><IoDocument size={20} color="#352F6C" onClick={()=>{window.open(item.policyUrl, "_blank")}} style={{cursor:"pointer"}} /></TableCell>
                                                    <TableCell>{item.userType==="From Website"?"Website":item.userType==="Internal"?"Direct":item.assignedAdvisorName}</TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                }
            </div>
        </div>
    )
}

export default HiOperationPolicyGeneratedLeads