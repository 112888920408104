import React from "react";
import Styles from "./InputContainer.module.css"

const InputContainer = ({ placeholder, value, onChange }) => {

    return (
        <div className={Styles.containerStyle}>
            <p className={Styles.labelStyle}>{placeholder}</p>
            <input value={value} onChange={onChange} className={Styles.inputStyle} InputProps={{ disableUnderline: true }} />
        </div>
    )
}

export default InputContainer