import { onValue, ref } from "firebase/database"
import { firebaseDatabase } from "../backend/firebaseHandler"

export const getAdvisorUid = (phoneNumber) => {
    const getAdvisorUidName = new Promise((resolve, reject) => {
        const healthInsureanceRef = ref(firebaseDatabase, `HEALTH_INSURANCE_APPLICATIONS/${phoneNumber}/assignedAdvisorUid`);
        onValue(healthInsureanceRef, (dataSnapshot) => {
            if (dataSnapshot.exists()) {
                const uid = dataSnapshot.val();
                resolve(uid);
            }else {
                reject();
            }
        }, { onlyOnce: true })
    })
    return getAdvisorUidName;
}
