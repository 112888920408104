import React, { useContext, useEffect, useState } from "react";
import Styles from "./HiAdvisorClosedLeads.module.css"
import { onValue, ref } from "firebase/database";
import { firebaseDatabase } from "../../../backend/firebaseHandler";
import UserContext from "../../../context/UserData.context";
import { Box, CircularProgress, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs } from "@mui/material";
import { IoCall } from "react-icons/io5"
import { useNavigate } from "react-router-dom";

const HiAdvisorClosedLeads = () => {

    const [loading, setLoading] = useState(true)
    const [visibleList, setVisibleList] = useState([])
    const [userData, setUserData] = useContext(UserContext)
    const navigate = useNavigate()

    useEffect(() => {
        let temp = []

        onValue(ref(firebaseDatabase, `HI_CLOSED_LEADS/${userData.uid}`), (snap) => {
            if (snap.exists()) {
                for (const key in snap.val()) {
                    temp.push(snap.child(key).val())
                }
            }
            setVisibleList([...temp])
            setLoading(false)
        }, {onlyOnce:true})
    }, [])

    return (
        <div>
            <h1 style={{ color: "#352F6C", marginBottom:40 }}>Closed Leads</h1>

            <div className={Styles.progressComponentContainer}>
                {
                    loading
                    ?
                    <CircularProgress className={Styles.progress} />
                    :
                    <Box>
                        <TableContainer>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>#</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Name</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Ph Number</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Email ID</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>University</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Date Received</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Last Call</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Reason</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody style={{backgroundColor:"white"}}>
                                    {
                                        visibleList.map((item, index) => {
                                            return (
                                                <TableRow key={index.toString()} >
                                                    <TableCell>{(index + 1).toString()}</TableCell>
                                                    <TableCell>{item.name}</TableCell>
                                                    <TableCell>{item.phoneNumber}</TableCell>
                                                    <TableCell>{item.emailId}</TableCell>
                                                    <TableCell>{item.desiredUniversity}</TableCell>
                                                    <TableCell>{item.newFromAdminDate?item.newFromAdminDate.split(" ")[0]:""}</TableCell>
                                                    <TableCell>{item.lastCallDate?item.lastCallDate.split(" ")[0]:""}</TableCell>
                                                    <TableCell>{item.subStatus}</TableCell>
                                                    <TableCell><IoCall size={20} color="#352F6C" onClick={()=>{navigate("/health-insurance/closed-leads/closed-lead-detail", {state:{item:item}})}} style={{cursor:"pointer"}} /></TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                }
            </div>
        </div>
    )
}

export default HiAdvisorClosedLeads