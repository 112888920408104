import React, { useContext, useEffect, useState } from "react";
import Styles from "./HiFreeTiClaims.module.css"
import { onValue, ref } from "firebase/database";
import { firebaseDatabase, firebaseDatabaseFidesure, firebaseDatabaseSC } from "../../../backend/firebaseHandler";
import UserContext from "../../../context/UserData.context";
import { Box, CircularProgress, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs } from "@mui/material";
import { IoCall, IoDocument, IoInformation, IoInformationCircleSharp } from "react-icons/io5"
import { useNavigate } from "react-router-dom";
import moment from "moment";

const HiFreeTiClaims = () => {

    const [loading, setLoading] = useState(true)
    const [visibleList, setVisibleList] = useState([])
    const [allList, setAllList] = useState([])
    const [value, setValue] = React.useState(0);
    const [subValue, setSubValue] = React.useState(0);
    const navigate = useNavigate()

    useEffect(() => {
        handleChange(null, 0)
    }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue)
        setSubValue(0)
        let temp = []
        let temp2 = []
        let dbRef = null

        switch (newValue) {
            case 0: {
                dbRef = ref(firebaseDatabase, "SC_FREE_TI_CLAIMS")
                break
            }
            case 1: {
                dbRef = ref(firebaseDatabase, "FIDESURE_FREE_TI_CLAIMS")
                break
            }
            default: {}
        }

        setLoading(true)
        onValue(dbRef, (snap) => {
            if (snap.exists()) {
                for (const key in snap.val()) {
                    let item = snap.child(key).val()
                    if (newValue === 1) {
                        item.entryTime = moment(item.timeStamp).format('MMMM Do YYYY')
                    } else {
                        item.entryTime = item.date
                    }
                    if (item.status === "New") {
                        temp.push(item)    
                    } 
                    temp2.push(item)               
                }

                temp = temp.sort((a, b) => {
                    const date_1 = moment(a.entryTime,"MMMM Do YYYY")
                    const date_2 = moment(b.entryTime,"MMMM Do YYYY")
                    return moment(date_2).diff(date_1)
                })
            }

            setVisibleList([...temp])
            setAllList([...temp2])
            setLoading(false)
        }, {onlyOnce:true})
        
    }

    const handleSubChange = (event, newSubValue) => {
        setSubValue(newSubValue)

        let temp = []
        for (const index in allList) {
            if (newSubValue === 0 && allList[index].status === "New") {
                temp.push(allList[index])
            } else if (newSubValue === 1 && allList[index].status === "Policy Generated") {
                temp.push(allList[index])
            }
        }

        setVisibleList([...temp])
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <div>
            <h1 style={{ color: "#352F6C", marginBottom:40 }}>Free TI Claims</h1>

            <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', marginTop:20, marginBottom:40}}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" >
                    <Tab label="Student Cover" {...a11yProps(0)} />
                    <Tab label="Fidesure" {...a11yProps(1)} />
                </Tabs>
            </div>

            <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', marginTop:20, marginBottom:40}}>
                <Tabs value={subValue} onChange={handleSubChange} aria-label="basic tabs example" >
                    <Tab label="New" {...a11yProps(0)} />
                    <Tab label="Policy Generated" {...a11yProps(1)} />
                </Tabs>
            </div>

            <div className={Styles.progressComponentContainer}>
                {
                    loading
                    ?
                    <CircularProgress className={Styles.progress} />
                    :
                    <Box>
                        <TableContainer>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>#</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Name</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Ph Number</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Email ID</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Travel Date</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Date Received</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody style={{backgroundColor:"white"}}>
                                    {
                                        visibleList.map((item, index) => {
                                            return (
                                                <TableRow key={index.toString()} >
                                                    <TableCell>{(index + 1).toString()}</TableCell>
                                                    <TableCell>{item.name}</TableCell>
                                                    <TableCell>{item.phoneNumber}</TableCell>
                                                    <TableCell>{item.emailId}</TableCell>
                                                    <TableCell>{item.travelStartDate}</TableCell>
                                                    <TableCell>{item.entryTime}</TableCell>
                                                    <TableCell><IoInformationCircleSharp size={20} color="#352F6C" onClick={()=>{navigate("/health-insurance/free-ti-claims/ti-claim-detail", {state:{item:item, type:value}})}} style={{cursor:"pointer"}} /></TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                }
            </div>
        </div>
    )
}

export default HiFreeTiClaims