const relationMap = {
    "Father": ["Mother"],
    "Mother": ["Father"],
    "Spouse": ["Father", "Mother"],
    "Father-in-law": ["Father", "Mother", "Spouse", "Mother-in-law"],
    "Mother-in-law": ["Father", "Mother", "Spouse", "Father-in-law"],
    "Brother": ["Father", "Mother"],
    "Sister": ["Father", "Mother"],
    "Paternal Uncle (Father's Brother)": ["Father", "Mother"],
    "Paternal Uncle (Aunt's Spouse)": ["Father", "Mother", "Uncle's Spouse"],
    "Maternal Uncle (Mother's Brother)": ["Father", "Mother"],
    "Maternal Uncle (Aunt's Spouse)": ["Father", "Mother", "Uncle's Spouse"],
    "Paternal Aunt (Father's Sister)": ["Father", "Mother"],
    "Paternal Aunt (Uncle's Spouse)": ["Father", "Mother", "Paternal Uncle"],
    "Maternal Aunt (Mother's Sister)": ["Father", "Mother"],
    "Maternal Aunt (Uncle's Spouse)": ["Father", "Mother", "Paternal Uncle"],
    "Paternal Cousin": ["Father", "Mother", "Paternal Uncle"],
    "Maternal Cousin": ["Father", "Mother", "Maternal Uncle"],
    "Brother-in-law": ["Father", "Mother", "Sister"]
}

export default relationMap