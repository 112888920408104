import Styles from "./CredilaLeads.module.css"
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from "react";
import UserContext from "../../../context/UserData.context";
import { off, onValue, push, ref, remove, set } from "firebase/database";
import { firebaseDatabase } from "../../../backend/firebaseHandler";
import moment from "moment";
import exportFromJSON from "export-from-json";
import * as XLSX from 'xlsx';
import { Button, Divider, Tab, Tabs, Stack, Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Dialog, DialogTitle, DialogContent, DialogActions, Checkbox, Modal, Switch, Select, MenuItem } from '@mui/material';
import { IoAddCircle } from 'react-icons/io5';
import _ from 'lodash';
import educationLoan from "../../../entities/EducationLoan.sample";
import healthInsurance from "../../../entities/HealthInsurance.sample";
import { checkIfEntryExists } from "../../../workload/DuplicationReportHelper";

const CredilaLeads = () => {
    
    const [userData, setUserData] = useContext(UserContext)
    const [progress, setProgress] = useState(false)
    const [value, setValue] = React.useState(0);
    const [mainList, setMainList] = useState([])
    const [visibleList, setVisibleList] = useState([])
    const [filteredAdvisors, setFilteredAdvisors] = useState([])
    const [userListDialog, setUserListDialog] = useState(false)
    const [shareWithAll, setShareWithAll] = useState(false)
    const [selectedAdvisors, setSelectedAdvisors] = useState([])
    const handleDistributeButtonRef = useRef();
    const [uniqueList, setUniqueList] = useState([])
    const [duplicationStat, setDuplicationStat] = useState({ new: 0, interested: 0, caseDetails: 0, operations: 0, vendor: 0, pfPaid: 0, slReceived: 0, disbursement: 0, closed: 0 })
    const [dupliateList, setDuplicateList] = useState([])
    const [statDialog, setStatDialog] = useState(false)
    const [typeDialog, setTypeDialog] = useState(false)
    const [selectedType, setSelectedType] = useState("")
    const [clearPoolAlert, setClearPoolAlert] = useState(false)

    useEffect(()=>{
        handleChange(null, 0)
    }, [])

    const handleChange = (event, newValue) => {
        let tempVisible = []
        setValue(newValue)

        let dbRef = null

        if (newValue === 0) {
            dbRef = ref(firebaseDatabase, "POOL_INCOGNITO_APPLICATIONS/Education Loan")
        } 

        onValue(dbRef, (snap) => {
            if (snap.exists()) {

                tempVisible = []
                for (const key in snap.val()) {
                    let item = snap.child(key).val()
                    tempVisible.push(item)
                }
                setMainList(tempVisible)
                handleTypeSelect("")

            } else {
                setMainList([])
                handleTypeSelect("")
            }
        }, { onlyOnce: true })
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleUploadLeads = () => {
        if (selectedType === "") {
            alert("Please select type") 
            return
        }
        const inputElement = document.createElement("input")
        inputElement.setAttribute("type", "file")

        inputElement.onchange = (event) => {
            const file = event.target.files[0];
            let size = 0
            let flag = false
            let invalidRowNumbers = []
            let invalidData = []

            const reader = new FileReader();
            reader.onload = async (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                jsonData.shift()

                let finalData = {}
                jsonData.forEach(item => {
                    try {
                        const name = item[0] === undefined ? "" : item[0].toString()
                        const emailId = item[2] === undefined ? "" : item[2].toString()
                        const countryOfStudy = item[3] === undefined ? "" : item[3].toString()
                        const desiredUniversity = item[4] === undefined ? "" : item[4].toString()
                        const source = item[5] === undefined ? "" : item[5].toString()
                        const sourceDescription = item[6] === undefined ? "" : item[6].toString()
                        const incognitoType = selectedType
                        const specialNoteFromAdmin = item[8] === undefined ? "" : item[8].toString()
                        let phoneNumber = "91" + item[1].toString()
                        if (phoneNumber.length !== 12) {
                            invalidRowNumbers.push((size + 2).toString())
                            invalidData.push(item)
                            return
                        } else {
                            const forKeyRef = ref(firebaseDatabase, `/POOL_INCOGNITO_APPLICATIONS/Education Loan`)
                            const key = push(forKeyRef).key;

                            let sampleData = { ...educationLoan }

                            sampleData.key = key
                            sampleData.phoneNumber = phoneNumber
                            sampleData.name = name
                            sampleData.emailId = emailId
                            sampleData.desiredCountry = countryOfStudy
                            sampleData.desiredUniversity = desiredUniversity
                            sampleData.source = source
                            sampleData.sourceDescription = sourceDescription
                            sampleData.type = "INCOGNITO"
                            sampleData.date = moment().format("DD-MM-YYYY h:mm a")
                            sampleData.status = "Pool"
                            sampleData.subStatus = ""
                            sampleData.product = "Education Loan"
                            sampleData.receivedFrom = { "position": userData.position, "name": userData.name, "phoneNumber": userData.phoneNumber, "uid": userData.uid }
                            sampleData.advisorPool = []
                            sampleData.specialNoteFromAdmin = specialNoteFromAdmin
                            sampleData.incognitoType = incognitoType

                            finalData[phoneNumber] = {
                                ...sampleData
                            }

                        }
                        size++
                    } catch (err) {
                        alert("Something went wrong. Please check if the sheet has valid data.")
                        flag = true
                        return
                    }

                })

                setTypeDialog(false)

                if (!flag) {
                    setProgress(true)
                    for (const key in finalData) {
                        await set(ref(firebaseDatabase, `/POOL_INCOGNITO_APPLICATIONS/Education Loan/${key}`), finalData[key])
                    }
                    setProgress(false)
                    
                    if (invalidData.length !== 0) {
                        alert("Valid leads uploaded. Invalid leads found in rows " + invalidRowNumbers.join(", ") + ". A sheet containing all the invalid data is downloaded.")

                        let data = []
                        let date = moment().format("DD-MM-YYYY")
                        for (const index in invalidData) {
                            let tempObj = {
                                "Customer Name": invalidData[index][0],
                                "Phone Number": invalidData[index][1],
                                "Email ID": invalidData[index][2],
                                "Country of Study": invalidData[index][3],
                                "Desired University": invalidData[index][4],
                                "Source": invalidData[index][5],
                                "SourceDescription": invalidData[index][6]
                            }
                            data.push(tempObj)
                        }
                        const fileName = 'Invalid Data: ' + date
                        const exportType = exportFromJSON.types.csv

                        exportFromJSON({ data, fileName, exportType })
                    } else {
                        alert("Done")
                        handleChange(null, 0)
                    }
                }
            }
            reader.readAsArrayBuffer(file)
        }
        inputElement.click();
    }

    const filterAdvisors = () => {

        if (visibleList.length === 0) {
            alert("Nothing to share")
            return
        }

        let tempAd = []
        let uids = []

        if (selectedType === "Credila") {
            uids = ["TCTbQWhZ49Tks7Qxfv1BsD5U02h2", "q7xxlR2pt6VDXnz05iw21xxF8iH3", "z7ARx4HJEQbo98BQ5WAmCoVUdR72", "qaDEp3xFXOceH37nSnyqIPGkV0k1", "a9GLPJ0X03hZahcYZeAZ5rD2SFi2", "btLP3P8z4FUDMr34ZM5hFIkzaMJ2", "zrRuWWRvDbMJFLicUyaR8aH5qU32", "VttfkQHkCpS3S4HIWMCQgsAK2CE3", "EBNDNusKS3U0Yb7G1Llin4Ae2Hq2", "phgKfRebxthEBhqLZ1AAqaMFwqm2", "JaDrFM2Hywex5LcPalHoL2o2YVX2"]
        } else if (selectedType === "Avanse"){
            uids = ["QwH6KY2gzbWi47NnzhPteSvEmyE3", "6rEGv9zdFuXmU7OAvWR0s9IsTCh1", "wEOl6U0p2NVKjB9XO52EVgTzhgp1"]
        }

        onValue(ref(firebaseDatabase, "USER_ARCHIVE"), (snapShot) => {
            if (snapShot.exists()) {
                for (const key in snapShot.val()) {
                    let item = snapShot.child(key).val()
                    if (item.department === "Education Loan") {
                        if (item.position === "Advisor" && item.status === "Active" && uids.includes(item.uid)) {
                            tempAd.push(item)
                        }
                    }
                }
                setFilteredAdvisors(tempAd)
                setUserListDialog(true)
            }
        }, { onlyOnce: true })
    }

    const downloadAndDelete = async () => {
        let data = []
        let date = moment().format("DD-MM-YYYY")

        for (const index in mainList) {
            let tempObj = {
                "Customer Name": mainList[index].name,
                "Phone Number": mainList[index].phoneNumber,
                "Email ID": mainList[index].emailId,
                "Country of Study": mainList[index].desiredCountry,
                "Desired University": mainList[index].desiredUniversity,
                "Product": mainList[index].product,
                "Source": mainList[index].source,
                "SourceDescription": mainList[index].sourceDescription,
                "Upload Date": mainList[index].date
            }
            data.push(tempObj)
        }
        const fileName = 'Pool Leads: ' + date
        const exportType = exportFromJSON.types.csv
        exportFromJSON({ data, fileName, exportType })
        setProgress(true)
        await remove(ref(firebaseDatabase, `POOL_INCOGNITO_APPLICATIONS/Education Loan`))
        setProgress(false)
        handleChange(null, 0)
        setClearPoolAlert(false)
    }

    const handleCheckbox = (item, state) => {
        let temp = [...selectedAdvisors]
        if (state) {
            temp.push(item)
        } else {
            const removeIndex = _.indexOf(selectedAdvisors, item);
            temp.splice(removeIndex, 1)
        }

        setSelectedAdvisors([...temp])
    }

    const handleDistribute = async () => {
        if (selectedAdvisors.length === 0) {
            return
        }

        let uniqueTemp = []
        let duplicateTemp = []

        let leadList = [...visibleList]
        const numberList = []
        let number = 0
        let date = moment().format("DD-MM-YYYY h:mm a")

        for (const index in leadList) {
            let item = leadList[index]
            item.assignedAdvisorUid = selectedAdvisors[number].uid
            item.assignedAdvisorName = selectedAdvisors[number].name
            item.assignedAdvisorPhoneNumber = selectedAdvisors[number].phoneNumber
            item.newFromAdminDate = date
            if (number === selectedAdvisors.length - 1) {
                number = 0
            } else {
                number++
            }
            numberList.push(item.phoneNumber)
        }

        let tempStat = { new: 0, interested: 0, caseDetails: 0, operations: 0, vendor: 0, pfPaid: 0, slReceived: 0, disbursement: 0, closed: 0 }
        for (const [index1, phoneNumber] of numberList.entries()) {
            const status = await checkIfEntryExists(phoneNumber, "Incognito")
            handleDistributeButtonRef.current.handleLoading(true, leadList.length, index1);
            switch (status) {
                case "New": {
                    tempStat.new += 1;
                    duplicateTemp.push(leadList[index1])
                    break;
                }
                case "Interested": {
                    tempStat.interested += 1;
                    duplicateTemp.push(leadList[index1])
                    break;
                }
                case "Case Details": {
                    tempStat.caseDetails += 1;
                    duplicateTemp.push(leadList[index1])
                    break;
                }
                case "Operations": {
                    tempStat.operations += 1;
                    duplicateTemp.push(leadList[index1])
                    break;
                }
                case "Vendor Status": {
                    tempStat.vendor += 1;
                    duplicateTemp.push(leadList[index1])
                    break;
                }
                case "PF Paid": {
                    tempStat.pfPaid += 1;
                    duplicateTemp.push(leadList[index1])
                    break;
                }
                case "SL Received": {
                    tempStat.slReceived += 1;
                    duplicateTemp.push(leadList[index1])
                    break;
                }
                case "Disbursed": {
                    tempStat.disbursement += 1;
                    duplicateTemp.push(leadList[index1])
                    break;
                }
                case "Closed": {
                    tempStat.closed += 1;
                    duplicateTemp.push(leadList[index1])
                    break;
                }
                case "Unique": {
                    uniqueTemp.push(leadList[index1]);
                    break
                }
                default: { }
            }
        }
        setUniqueList(uniqueTemp)
        setDuplicationStat({ ...tempStat })
        setDuplicateList(duplicateTemp)
        setStatDialog(true)
        handleDistributeButtonRef.current.handleLoading(false, 0, 0);
    }

    const distributeData = async () => {
        setProgress(true)
        let promises = [];
        for (const index in uniqueList) {
            const promise = new Promise(async (resolve, reject) => {
                let item = uniqueList[index]
                item.status = "New"
                item.subStatus = "From Admin-Yet to Call"
                item.advisorPool = [...selectedAdvisors]
                await set(ref(firebaseDatabase, `INCOGNITO_NEW_LEADS/${item.assignedAdvisorUid}/${item.phoneNumber}`), item)
                await set(ref(firebaseDatabase, `INCOGNITO_LOAN_APPLICATIONS/${item.phoneNumber}`), item)

                //lifeline object
                let tempLifelineObj = {
                    status: "New",
                    lifeline: [{
                        actionBy: userData.uid,
                        status: "New",
                        subStatus: "From Admin-Yet to Call",
                        date: item.newFromAdminDate,
                        ref: "INCOGNITO_NEW_LEADS",
                        sentTo: item.assignedAdvisorUid
                    }]
                }

                await set(ref(firebaseDatabase, `ALL_LEADS_STATUS/Incognito/${item.phoneNumber}`), tempLifelineObj)
                await remove(ref(firebaseDatabase, `POOL_INCOGNITO_APPLICATIONS/Education Loan/${item.phoneNumber}`))
                resolve();
            })
            promises.push(promise);
        }

        await Promise.allSettled(promises)
        setProgress(false)
        setStatDialog(false)
        setUserListDialog(false)
        setSelectedAdvisors([])
        setFilteredAdvisors([])
        alert("Leads moved")
        handleChange(null, 0)
    }

    const handleDownloadReport = () => {
        let data = []
        let date = moment().format("DD-MM-YYYY")

        setProgress(true)

        let downloadRef = null

        // if (subValue === 0) {
        //     downloadRef = ref(firebaseDatabase, `LOAN_APPLICATIONS`)
        // } else if (subValue === 1) {
        //     downloadRef = ref(firebaseDatabase, `HEALTH_INSURANCE_APPLICATIONS`)
        // }

        for (const [index, item] of dupliateList.entries()) {
            downloadRef = ref(firebaseDatabase, `INCOGNITO_LOAN_APPLICATIONS/${item.phoneNumber}`)

            onValue(downloadRef, (snap) => {
                if (snap.exists()) {
                    let tempObj = {
                        "Customer Name": dupliateList[index].name,
                        "Phone Number": dupliateList[index].phoneNumber,
                        "Email ID": dupliateList[index].emailId,
                        "Country of Study": dupliateList[index].desiredCountry,
                        "Desired University": dupliateList[index].desiredUniversity,
                        "Source": dupliateList[index].source,
                        "SourceDescription": dupliateList[index].sourceDescription,
                        "Status": snap.child("status").val(),
                        "Sub Status": snap.child("subStatus").val(),
                        "Current Advisor Name": snap.child("assignedAdvisorName").val(),
                        "Current Advisor Phone Number": snap.child("assignedAdvisorPhoneNumber").val()
                    }
                    data.push(tempObj)

                    if (dupliateList.length - 1 === index) {

                        const fileName = 'Duplicate Data Report: ' + date
                        const exportType = exportFromJSON.types.csv
                        exportFromJSON({ data, fileName, exportType })
                        setProgress(false)
                    }
                }
            }, { onlyOnce: true })
        }
    }

    const handleTypeSelect = (value) => {
        setSelectedType(value)
        let temp = []

        for (const index in mainList) {
            let item = mainList[index]
            if (item.incognitoType === value) {
                temp.push(item)
            }
        }

        setVisibleList(temp)
    }

    return (
        <div>
            <div className={Styles.tabHederContainer}>
                <h1 style={{ color: "#352F6C" }}>Incognito Leads</h1>
                
                <div className={Styles.uploadViewContainer}>
                    <Button onClick={() => { setTypeDialog(true) }} startIcon={<IoAddCircle />} style={{ backgroundColor: "#8069B4" }} size='medium' variant='contained'>Upload Leads</Button>
                    <p className={Styles.viewTemplateButton} onClick={() => { window.open("https://firebasestorage.googleapis.com/v0/b/student-cover-crm.appspot.com/o/SC%20CRM%20Lead%20Upload%20Template(2).xlsx?alt=media&token=5af42011-85f0-4b9e-ba88-7ba263be5c85", "_blank") }}>Download Template</p>
                </div>
            </div>

            <div>
                <p style={{ marginTop: 20 }}>Type</p>
                <Select value={selectedType} onChange={(event)=>{handleTypeSelect(event.target.value)}} style={{ backgroundColor: "white", borderRadius: 5, width: 250, height: 40, border: "none", outline: "none" }}>
                    {
                        ["Credila", "Avanse"].map((item, index) => {
                            return (
                                <MenuItem value={item}>{item}</MenuItem>
                            )
                        })
                    }
                </Select>
            </div>

            <div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Pool" {...a11yProps(0)} />
                    </Tabs>
                </div>

                <Divider />

                <Stack direction={'row'} alignItems='center' justifyContent='space-between' className={Styles.filterContainer}>
                    <h2 style={{ color: "#352F6C" }}>{visibleList.length} Incognito Leads</h2>

                    <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
                        <Button onClick={()=>{setClearPoolAlert(true)}} style={{ backgroundColor: "red" }} size='medium' variant='contained'>Clear Pool</Button>
                        <Button onClick={filterAdvisors} style={{ backgroundColor: "#8069B4" }} size='medium' variant='contained'>Forward Leads</Button>
                    </div>
                </Stack>
                <Box>
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 700, color: "#352F6C" }}>#</TableCell>
                                    <TableCell style={{ fontWeight: 700, color: "#352F6C" }}>Name</TableCell>
                                    <TableCell style={{ fontWeight: 700, color: "#352F6C" }}>Phone Number</TableCell>
                                    <TableCell style={{ fontWeight: 700, color: "#352F6C" }}>Email ID</TableCell>
                                    <TableCell style={{ fontWeight: 700, color: "#352F6C" }}>Upload Date</TableCell>
                                    <TableCell style={{ fontWeight: 700, color: "#352F6C" }}>Country</TableCell>
                                    <TableCell style={{ fontWeight: 700, color: "#352F6C" }}>University</TableCell>
                                    <TableCell style={{ fontWeight: 700, color: "#352F6C" }}>Source</TableCell>
                                    <TableCell style={{ fontWeight: 700, color: "#352F6C" }}>Source Description</TableCell>
                                    <TableCell style={{ fontWeight: 700, color: "#352F6C" }}>Type</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody style={{ backgroundColor: "white" }}>
                                {
                                    visibleList.map((item, index) => {
                                        return (
                                            <TableRow key={index.toString()}>
                                                <TableCell>{(index + 1).toString()}</TableCell>
                                                <TableCell>{item.name}</TableCell>
                                                <TableCell>{item.phoneNumber}</TableCell>
                                                <TableCell>{item.emailId}</TableCell>
                                                <TableCell>{item.date}</TableCell>
                                                <TableCell>{item.desiredCountry}</TableCell>
                                                <TableCell>{item.desiredUniversity}</TableCell>
                                                <TableCell>{item.source}</TableCell>
                                                <TableCell>{item.sourceDescription}</TableCell>
                                                <TableCell>{item.incognitoType}</TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </div>

            {/* Uploading Data Dialog */}

            <Modal open={progress} onClose={() => { setProgress(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className={Styles.modalContainer}>
                        <p className={Styles.modalTitle} style={{ fontWeight: 400, marginBottom: 10 }}>Uploading Data</p>
                        <p className={Styles.modalTitle}>Please wait...</p>
                    </div>
                </Box>
            </Modal>

            {/* Advisor Dialog */}

            <Modal open={userListDialog} onClose={() => { setUserListDialog(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className={Styles.modalContainer}>
                        <p className={Styles.modalTitle}>Select the advisors to distribute the leads.</p>
                        <div className={Styles.productListContainer}>
                            {
                                !shareWithAll
                                &&
                                filteredAdvisors.map((item, index) => {
                                    return (
                                        <div key={item.uid} className={Styles.itemContainer}>
                                            <p className={Styles.productName}>{item.name}</p>
                                            <Switch checked={_.includes(selectedAdvisors, item)} onChange={(event) => { handleCheckbox(item, event.target.checked) }} />
                                        </div>
                                    )
                                })
                            }
                        </div>

                        <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: 'center', marginTop: 20 }}>
                            <p>All Advisors</p>
                            <Switch value={shareWithAll} onChange={(event) => { setShareWithAll(event.target.checked); if (event.target.checked) { setSelectedAdvisors([...filteredAdvisors]) } else { setSelectedAdvisors([]) } }} checked={shareWithAll} />
                        </div>

                        <div className={Styles.buttonContainer}>
                            <Button sx={{ backgroundColor: "rgba(53, 47, 108, 0.5)" }} onClick={() => { setUserListDialog(false) }} variant='contained' className={Styles.secondaryButton}>Cancel</Button>
                            <DistributeButton handleDistribute={handleDistribute} ref={handleDistributeButtonRef} />
                            {/* <Button onClick={() => { handleDistribute() }} variant='contained' className={Styles.primaryButton}>Proceed</Button> */}
                        </div>
                    </div>
                </Box>
            </Modal>

            {/* Type Dialog */}
            <Modal open={typeDialog} onClose={() => { setTypeDialog(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className={Styles.modalContainer}>
                        <p className={Styles.modalTitle}>Select the type of leads you are about to upload</p>
                        
                        <Select className={Styles.inputField} value={selectedType} onChange={(event)=>{setSelectedType(event.target.value)}}>
                            {
                                ["Credila", "Avanse"].map((item) => {return(
                                    <MenuItem value={item}>{item}</MenuItem>
                                )})
                            }
                        </Select>

                        <div className={Styles.buttonContainer}>
                            <Button sx={{ backgroundColor: "rgba(53, 47, 108, 0.5)" }} onClick={() => { setTypeDialog(false) }} variant='contained' className={Styles.secondaryButton}>Cancel</Button>
                            <Button onClick={() => { handleUploadLeads() }} variant='contained' className={Styles.primaryButton}>Upload</Button>
                        </div>
                    </div>
                </Box>
            </Modal>

            {/* Clear Pool Dialog */}
            <Modal open={clearPoolAlert} onClose={() => { setClearPoolAlert(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className={Styles.modalContainer}>
                        <p className={Styles.modalTitle}>Are you sure you want to clear the pool? This will delete all types of leads.</p>
                        
                        
                        <div className={Styles.buttonContainer}>
                            <Button sx={{ backgroundColor: "rgba(53, 47, 108, 0.5)" }} onClick={() => { setClearPoolAlert(false) }} variant='contained' className={Styles.secondaryButton}>Cancel</Button>
                            <Button onClick={() => { downloadAndDelete() }} variant='contained' className={Styles.primaryButton}>Clear</Button>
                        </div>
                    </div>
                </Box>
            </Modal>

            {/* Stat Dialog */}

            <Modal open={statDialog} onClose={() => { setStatDialog(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className={Styles.reportContainer}>
                        <p className={Styles.modalTitle}>Lead Report</p>
                        <div className={Styles.modalGridContainer}>
                            <p className={Styles.modalGridItem}>Total Leads</p>
                            <p className={Styles.modalGridItem}>{visibleList.length}</p>

                            <p className={Styles.modalGridItem}>Unique Leads</p>
                            <p className={Styles.modalGridItem}>{uniqueList.length}</p>
                        </div>

                        <p className={Styles.modalTitle} style={{ marginTop: 20, fontWeight: 500 }}>Current Lead Status</p>
                        <div className={Styles.modalGridContainer}>
                            <p className={Styles.modalGridItem} style={{ fontWeight: 500 }}>Duplicate Leads</p>
                            <p className={Styles.modalGridItem} style={{ fontWeight: 500 }}>{dupliateList.length}</p>

                            <p className={Styles.modalGridItem}>New</p>
                            <p className={Styles.modalGridItem}>{duplicationStat.new}</p>
                            <p className={Styles.modalGridItem}>Interested</p>
                            <p className={Styles.modalGridItem}>{duplicationStat.interested}</p>
                            <p className={Styles.modalGridItem}>Case Details</p>
                            <p className={Styles.modalGridItem}>{duplicationStat.caseDetails}</p>
                            <p className={Styles.modalGridItem}>Operations</p>
                            <p className={Styles.modalGridItem}>{duplicationStat.operations}</p>
                            <p className={Styles.modalGridItem}>Vendor Status</p>
                            <p className={Styles.modalGridItem}>{duplicationStat.vendor}</p>
                            <p className={Styles.modalGridItem}>PF Paid</p>
                            <p className={Styles.modalGridItem}>{duplicationStat.pfPaid}</p>
                            <p className={Styles.modalGridItem}>SL Received</p>
                            <p className={Styles.modalGridItem}>{duplicationStat.slReceived}</p>
                            <p className={Styles.modalGridItem}>Disbursed</p>
                            <p className={Styles.modalGridItem}>{duplicationStat.disbursement}</p>
                            <p className={Styles.modalGridItem}>Closed</p>
                            <p className={Styles.modalGridItem}>{duplicationStat.closed}</p>
                            
                        </div>

                        <p className={Styles.downloadReportButton} onClick={() => { handleDownloadReport() }}>Download Report</p>

                        <div className={Styles.buttonContainer}>
                            <Button onClick={() => { setStatDialog(false) }} variant='contained' className={Styles.secondaryButton}>Cancel</Button>
                            <Button onClick={() => { distributeData() }} variant='contained' className={Styles.primaryButton}>Forward</Button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

const DistributeButton = forwardRef(({ handleDistribute, updateState }, ref) => {
    const [loading, setLoading] = useState({ status: false, value: "" });

    const handleLoading = (status, totalLength, currentIndex) => {
        setLoading({
            status: status,
            value: `${currentIndex}/${totalLength}`
        })
    }

    useImperativeHandle(ref, () => {
        return {
            handleLoading
        }
    })



    return (
        <>
            <Button onClick={handleDistribute} disabled={loading.status} variant='contained' className={Styles.primaryButton}>
                {
                    loading.status
                        ?
                        loading.value
                        :
                        "Proceed"
                }

            </Button>
        </>
    )
});


export default CredilaLeads