import React, { useContext, useEffect, useState } from "react";
import Styles from "./HiOperationLinkSentLeads.module.css"
import { onValue, ref, remove, set } from "firebase/database";
import { firebaseDatabase, firebaseStorage } from "../../../backend/firebaseHandler";
import UserContext from "../../../context/UserData.context";
import { Alert, Box, Button, CircularProgress, Modal, Snackbar, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs } from "@mui/material";
import { IoDocument, IoRemove } from "react-icons/io5"
import moment from "moment";
import { getDownloadURL, ref as storageRef, uploadBytesResumable } from "firebase/storage";
import { MdDelete } from "react-icons/md"

const HiOperationLinkSentLeads = () => {

    const [loading, setLoading] = useState(true)
    const [visibleList, setVisibleList] = useState([])
    const [userData] = useContext(UserContext)
    const [submitting, setSubmitting] = useState(false)
    const [selectedFile, setSelectedFile] = useState({})
    const [snackBar, setSnackBar] = useState({ visibility: false, severity: "", message: "" })
    const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)

    useEffect(() => {
        fetchList()
    }, [])

    const fetchList = () => {
        let temp = []
        let temp2 = []

        onValue(ref(firebaseDatabase, `HI_OP_USER_LINK_SENT_LEADS`), (snap) => {
            if (snap.exists()) {
                for (const key in snap.val()) {
                    let item = snap.child(key).val()
                    if ((userData.uid === "93nbcASsRuV8g8pLF4mga0wxbHM2" || userData.uid === "vuc57CqdO8dJCCXFtiQtJoH9VD53") && item.planDetail.name.includes("Prima")) {
                        let travelDate = moment(item.travelDate, "DD-MM-YYYY").format("YYYY-MM-DD")
                        item.travelAge = moment(travelDate).diff(moment(), 'days')
                        temp.push(item)
                    } 
                    if (userData.uid === "0uH2KMkUD7TlfEV3yXL7ZfQGEbh2" && item.planDetail.name === "SC Gold Plus") {
                        let travelDate = moment(item.travelDate, "DD-MM-YYYY").format("YYYY-MM-DD")
                        item.travelAge = moment(travelDate).diff(moment(), 'days')
                        temp.push(item)
                    }
                    
                }

                temp2 = temp.sort((a, b) => {
                    const date_1 = moment(a.paymentLinkSentDate,"DD-MM-YYYY h:mm a")
                    const date_2 = moment(b.paymentLinkSentDate,"DD-MM-YYYY h:mm a")
                    return moment(date_2).diff(date_1)
                })
            }

            setVisibleList([...temp2])
            setLoading(false)
        }, {onlyOnce:true})
    }

    const handleDocumentUpload = (item, folder) => {
        const inputElement = document.createElement('input');
        inputElement.setAttribute('type', "file");
        inputElement.setAttribute('accept', 'application/*');

        inputElement.onchange = (event) => {
            const files = event.target.files;

            const fileRef = storageRef(firebaseStorage, `HEALTH_INSURANCE_DOCS/${item.key}/${folder}/${Date.now().toString()}`);
            setSubmitting(true)
            const uploadTask = uploadBytesResumable(fileRef, files[0]);
            uploadTask.on('state_changed', (snapshot) => {
            }, (error) => {
                setSnackBar({ visibility: true, severity: "error", message: "Something went wrong" })
            }, async () => {
                const url = await getDownloadURL(fileRef);
                console.log(url)
                if (folder === "Policy_Certificate") {
                    item.policyUrl = url
                    
                    if (item.userType === "From Advisor") {
                        handleConfirmAdvisor(item)
                    } else if (item.userType === "From Website") {
                        handleConfirmWebsite(item)
                    } else if (item.userType === "Internal") {
                        handleConfirmWebsite(item)
                    }
                } else if (folder === "Payment_Proof") {
                    if (item.userType === "From Advisor") {
                        handleProofAdvisor(url, item)
                    } else if (item.userType === "From Website") {
                        handleProofWebsite(url, item)
                    } else if (item.userType === "Internal") {
                        handleProofWebsite(url, item)
                    }
                }
                
                
            })
        }
        inputElement.click();
    }

    const handleProofAdvisor = async (url, hiApplication) => {
        console.log("Advisor")
        console.log(url)

        let tempObj = {...hiApplication}

        tempObj.subStatus = "Proof Uploaded"
        tempObj.proofUploadDate = moment().format("DD-MM-YYYY h:mm a")
        tempObj.proofDocumentUrl = url

        onValue(ref(firebaseDatabase, `ALL_LEADS_STATUS/${tempObj.product}/${tempObj.phoneNumber}`), async (snap) => {
            if (snap.exists()) {
                let lifelineObj = snap.val()

                let array = [...lifelineObj.lifeline]
                let arrayItem = {
                    actionBy: tempObj.phoneNumber,
                    status: "Link Sent",
                    subStatus: "Proof Uploaded",
                    date: tempObj.proofUploadDate,
                    ref: "HI_LINK_SENT_LEADS"
                }
                array.push(arrayItem)
                lifelineObj.lifeline = [...array]

                await set(ref(firebaseDatabase, `ALL_LEADS_STATUS/${tempObj.product}/${tempObj.phoneNumber}`), lifelineObj)
                await set(ref(firebaseDatabase, `HI_LINK_SENT_LEADS/${tempObj.assignedAdvisorUid}/${tempObj.phoneNumber}`), tempObj)
                await set(ref(firebaseDatabase, `HEALTH_INSURANCE_APPLICATIONS/${tempObj.phoneNumber}`), tempObj)
                await set(ref(firebaseDatabase, `HI_OP_USER_LINK_SENT_LEADS/${tempObj.phoneNumber}`), tempObj)
                setSubmitting(false)
                fetchList()
            }
        }, { onlyOnce: true })
    }

    const handleProofWebsite = async (url, hiApplication) => {
        console.log("Website")
        console.log(url)

        let tempObj = {...hiApplication}

        tempObj.subStatus = "Proof Uploaded"
        tempObj.proofUploadDate = moment().format("DD-MM-YYYY h:mm a")
        tempObj.proofDocumentUrl = url

        onValue(ref(firebaseDatabase, `ALL_LEADS_STATUS/${tempObj.product}/${tempObj.phoneNumber}`), async (snap) => {
            if (snap.exists()) {
                let lifelineObj = snap.val()

                let array = [...lifelineObj.lifeline]
                let arrayItem = {
                    actionBy: tempObj.phoneNumber,
                    status: "Link Sent",
                    subStatus: "Proof Uploaded",
                    date: tempObj.proofUploadDate,
                    ref: "HI_OP_USER_LINK_SENT_LEADS"
                }
                array.push(arrayItem)
                lifelineObj.lifeline = [...array]

                await set(ref(firebaseDatabase, `ALL_LEADS_STATUS/${tempObj.product}/${tempObj.phoneNumber}`), lifelineObj)
                await set(ref(firebaseDatabase, `HEALTH_INSURANCE_APPLICATIONS/${tempObj.phoneNumber}`), tempObj)
                await set(ref(firebaseDatabase, `HI_OP_USER_LINK_SENT_LEADS/${tempObj.phoneNumber}`), tempObj)
                setSubmitting(false)
                fetchList()
            }
        }, { onlyOnce: true })
    }

    const handleConfirmAdvisor = async (object) => {
        setSubmitting(true)
        let tempObj = {...object}

        tempObj.status = "Policy Generated"
        tempObj.subStatus = "Generated"
        tempObj.paymentConfirmedDate = moment().format("DD-MM-YYYY h:mm a")

        onValue(ref(firebaseDatabase, `ALL_LEADS_STATUS/${tempObj.product}/${tempObj.phoneNumber}`), async (snap) => {
            if (snap.exists()) {
                let lifelineObj = snap.val()
                lifelineObj.status = "Policy Generated"

                let array = [...lifelineObj.lifeline]
                let arrayItem = {
                    actionBy: userData.uid,
                    status: "Policy Generated",
                    subStatus: "Generated",
                    date: tempObj.paymentConfirmedDate,
                    ref: "HI_POLICY_GENERATED_LEADS"
                }
                array.push(arrayItem)
                lifelineObj.lifeline = [...array]

                await set(ref(firebaseDatabase, `ALL_LEADS_STATUS/${tempObj.product}/${tempObj.phoneNumber}`), lifelineObj);
                await set(ref(firebaseDatabase, `HI_POLICY_GENERATED_LEADS/${tempObj.assignedAdvisorUid}/${tempObj.phoneNumber}`), tempObj);
                await set(ref(firebaseDatabase, `HI_OP_USER_POLICY_GENERATED_LEADS/${tempObj.phoneNumber}`), tempObj);
                await set(ref(firebaseDatabase, `HEALTH_INSURANCE_APPLICATIONS/${tempObj.phoneNumber}`), tempObj);
                await remove(ref(firebaseDatabase, `HI_OP_USER_LINK_SENT_LEADS/${tempObj.phoneNumber}`));
                await remove(ref(firebaseDatabase, `HI_LINK_SENT_LEADS/${tempObj.assignedAdvisorUid}/${tempObj.phoneNumber}`));
                setSubmitting(false)
                fetchList()
            }
        }, { onlyOnce: true })
    }

    const handleConfirmWebsite = async (object) => {
        setSubmitting(true)
        let tempObj = {...object}

        tempObj.status = "Policy Generated"
        tempObj.subStatus = "Generated"
        tempObj.paymentConfirmedDate = moment().format("DD-MM-YYYY h:mm a")

        onValue(ref(firebaseDatabase, `ALL_LEADS_STATUS/${tempObj.product}/${tempObj.phoneNumber}`), async (snap) => {
            if (snap.exists()) {
                let lifelineObj = snap.val()
                lifelineObj.status = "Policy Generated"

                let array = [...lifelineObj.lifeline]
                let arrayItem = {
                    actionBy: userData.uid,
                    status: "Policy Generated",
                    subStatus: "Generated",
                    date: tempObj.paymentConfirmedDate,
                    ref: "HI_OP_USER_POLICY_GENERATED_LEADS"
                }
                array.push(arrayItem)
                lifelineObj.lifeline = [...array]

                await set(ref(firebaseDatabase, `ALL_LEADS_STATUS/${tempObj.product}/${tempObj.phoneNumber}`), lifelineObj)
                await set(ref(firebaseDatabase, `HI_OP_USER_POLICY_GENERATED_LEADS/${tempObj.phoneNumber}`), tempObj)
                await set(ref(firebaseDatabase, `HEALTH_INSURANCE_APPLICATIONS/${tempObj.phoneNumber}`), tempObj)
                await remove(ref(firebaseDatabase, `HI_OP_USER_LINK_SENT_LEADS/${tempObj.phoneNumber}`))
                setSubmitting(false)
                fetchList()
            }
        }, { onlyOnce: true })
    }

    const handleDelete = async () => {
        if (selectedFile.name) {
            let tempObj = {...selectedFile}
            tempObj.subStatus = "Sent"
            tempObj.proofUploadDate = null
            tempObj.proofDocumentUrl = null
            tempObj.proofRemoved = true

            setSubmitting(true)
            if (tempObj.userType === "From Advisor") {
                if (tempObj.assignedAdvisorUid==="" || tempObj.assignedAdvisorUid===null || tempObj.assignedAdvisorUid===undefined || tempObj.phoneNumber==="" || tempObj.phoneNumber===null || tempObj.phoneNumber===undefined) {
                    alert("Something went wrong.")
                    return
                }
                await set(ref(firebaseDatabase, `HI_LINK_SENT_LEADS/${tempObj.assignedAdvisorUid}/${tempObj.phoneNumber}`), tempObj)
                await set(ref(firebaseDatabase, `HEALTH_INSURANCE_APPLICATIONS/${tempObj.phoneNumber}`), tempObj)
                await set(ref(firebaseDatabase, `HI_OP_USER_LINK_SENT_LEADS/${tempObj.phoneNumber}`), tempObj)
            } else if (tempObj.userType === "From Website" || tempObj.userType === "Internal") {
                await set(ref(firebaseDatabase, `HEALTH_INSURANCE_APPLICATIONS/${tempObj.phoneNumber}`), tempObj)
                await set(ref(firebaseDatabase, `HI_OP_USER_LINK_SENT_LEADS/${tempObj.phoneNumber}`), tempObj)
            }
            setSubmitting(false)
            setConfirmDeleteModal(false)
            fetchList()
        } else {
            alert ("Something went wrong, please try again.")
        }
    }

    return (
        <div>
            <h1 style={{ color: "#352F6C", marginBottom:40 }}>Link sent</h1>

            <div className={Styles.progressComponentContainer}>
                {
                    loading
                    ?
                    <CircularProgress className={Styles.progress} />
                    :
                    <Box>
                        <TableContainer>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>#</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Name</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Ph Number</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Email ID</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Days before travel</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Link sent on</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Paid on</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Payment Proof</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Advisor</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody style={{backgroundColor:"white"}}>
                                    {
                                        visibleList.map((item, index) => {
                                            return (
                                                <TableRow key={index.toString()} >
                                                    <TableCell>{(index + 1).toString()}</TableCell>
                                                    <TableCell>{item.name}</TableCell>
                                                    <TableCell>{item.phoneNumber}</TableCell>
                                                    <TableCell>{item.emailId}</TableCell>
                                                    <TableCell>{item.travelAge} days</TableCell>
                                                    <TableCell>{item.paymentLinkSentDate?item.paymentLinkSentDate.split(" ")[0]:""}</TableCell>
                                                    <TableCell>{item.proofUploadDate?item.proofUploadDate.split(" ")[0]:"-"}</TableCell>
                                                    {
                                                        item.proofDocumentUrl
                                                        ? 
                                                        <TableCell>
                                                            <div style={{display:"flex", flexDirection:"row", gap:5, alignItems:"flex-end"}}>
                                                                <IoDocument size={20} color="#352F6C" onClick={()=>{window.open(item.proofDocumentUrl, "_blank")}} style={{cursor:"pointer"}} />
                                                                <MdDelete size={18} color="red" onClick={()=>{setSelectedFile(item); setConfirmDeleteModal(true)}} />
                                                            </div> 
                                                        </TableCell>
                                                        :
                                                        <TableCell></TableCell>
                                                    }
                                                    <TableCell>{item.userType==="From Website"?"Website":item.userType==="Internal"?"Direct":item.assignedAdvisorName}</TableCell>
                                                    {
                                                        item.proofDocumentUrl
                                                        ?  
                                                        <TableCell><Button disabled={submitting} variant="contained" onClick={()=>{handleDocumentUpload(item, "Policy_Certificate")}} className={Styles.button}>Upload Policy</Button></TableCell>
                                                        :
                                                        <TableCell><Button disabled={submitting} variant="contained" onClick={()=>{handleDocumentUpload(item, "Payment_Proof")}} className={Styles.button}>Upload Pay Proof</Button></TableCell>
                                                    }
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                }
            </div>

            {/* Confirm Delete */}
            <Modal open={confirmDeleteModal} onClose={() => { setConfirmDeleteModal(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className={Styles.modalContainer}>
                        <p className={Styles.modalTitle}>Are you sure you want to delete the payment proof?</p>

                        <div className={Styles.modalButtonContainer}>
                            <Button disabled={submitting} sx={{ backgroundColor: "rgba(53, 47, 108, 0.5)" }} onClick={() => { setConfirmDeleteModal(false) }} variant='contained' className={Styles.secondaryModalButton}>Cancel</Button>
                            <Button disabled={submitting} onClick={() => { handleDelete() }} variant='contained' className={Styles.primaryModalButton}>Submit</Button>
                        </div>
                    </div>
                </Box>
            </Modal>

            <Snackbar open={snackBar.visibility} autoHideDuration={3000} onClose={() => { setSnackBar({ visibility: false, message: "", severity: "" }) }}>
                <Alert onClose={() => { setSnackBar({ visibility: false, message: "", severity: "" }) }} severity={snackBar.severity} variant="filled" sx={{ width: '100%' }}>{snackBar.message}</Alert>
            </Snackbar>
        </div>
    )
}

export default HiOperationLinkSentLeads