import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../../context/UserData.context";
import { useNavigate } from "react-router-dom";
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { HiDocumentCheck } from "react-icons/hi2"


const OperationPfPaidLeads = ({ activeList }) => {

    const [visibleList, setVisibleList] = useState([])
    const [userData, setUserData] = useContext(UserContext)
    const [value, setValue] = React.useState(0);
    const navigate = useNavigate()

    return (
        <div style={{width:"100%", marginTop:40}}>
            <Box>
                <TableContainer>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>#</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>App ID</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Name</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Ph Number</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Email ID</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Upload Date</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Check Document</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody style={{backgroundColor:"white"}}>
                            {
                                activeList.map((item, index) => {
                                    return (
                                        <TableRow key={index.toString()} >
                                            <TableCell>{(index + 1).toString()}</TableCell>
                                            <TableCell>{item.applicationId}</TableCell>
                                            <TableCell>{item.name}</TableCell>
                                            <TableCell>xxxxxxxxx{item.phoneNumber.substring(9, 12)}</TableCell>
                                            <TableCell>{item.emailId}</TableCell>
                                            <TableCell>{item.pfUploadDate?item.pfUploadDate:""}</TableCell>
                                            <TableCell><HiDocumentCheck onClick={()=>{window.open(item.pfUrl, "_blank")}} color={"#352F6C"} size={25} style={{cursor:"pointer"}} /></TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </div>
    )
}

export default OperationPfPaidLeads